import { useEffect, useRef, useState } from 'react'
import { TextAreaBinding } from 'y-textarea'
import { useSelector } from 'react-redux'
const YjsInput = ({id, field, className, backgroundColor, style}) =>{
  const ref = useRef(null)
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const providerY = yjsData.provider
  const user = useSelector((state) => state.user.userInfo)
  const [areaBinding, setAreaBinding] = useState(null)

  function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length=== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return {r:(c>>16)&255, g:(c>>8)&255, b:c&255}
    }
    return null
  }

  useEffect(() => {
    const AreaBinding = new TextAreaBinding(
      field,
      ref.current,
      {
        awareness : providerY.awareness,
        clientName: user.username,
        color : hexToRgbA(user.color)
      }
    );
    setAreaBinding(AreaBinding)
    window.addEventListener('resize', ()=>{
      AreaBinding.rePositionCursors()
    })
    return () => {
      areaBinding&&areaBinding.destroy()
      window.removeEventListener('resize', ()=>{
        AreaBinding.rePositionCursors()
      })
    }
  }, [user.color])


  return <input
    id={id}
    style={{...style, backgroundColor: backgroundColor?backgroundColor:null}}
    ref={ref}
    type={"text"}
    className={className}
  />
}

export default YjsInput