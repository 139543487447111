import * as Y from 'yjs'
import { v4 as uuidv4 } from 'uuid'
import { panelDataToPanelCoords } from './YjsPanelFuncions.js'

export const findTaskById = (tasksY, id) => {
  let out
  tasksY.map((x, i) => {
    if (x.get('id') === id)
      out = x
  })
  return out
}

export const isChild = (child, parent, tasksY) => {
  if (!child.get('props').get('isChildTask'))
    return false
  else
  if(child.get('props').get('parentTask')===parent.get("id"))
    return true
  else
    return isChild(findTaskById(tasksY, child.get('props').get('parentTask')) ,parent, tasksY)
}

export const hasChilds = (parent, tasksY) => {
  const tasksJSON = tasksY.toJSON()
  for(let i=0;i<tasksJSON.length;i++){
    if(tasksJSON[i].props.isChildTask && tasksJSON[i].props.parentTask===parent.get("id"))
      return true
  }
  return false
}

export const getDirectChilds = (task, tasksY) =>{
  let out = []
  let tasksJson=tasksY.toJSON()
  tasksJson.map((x,i)=>{
    if(x.props.isChildTask && x.props.parentTask===task.get("id"))
      out.push(tasksY.get(i))
  })
  return out
}
export const getAveragePercentFromChilds = (childs) =>{
  let sum = 0
  let activeChilds = 0
  let totalWeight = 0
  childs.map((x,i)=>{
    if(x.get("props").get("completenessActive")) {
      sum += Number(x.get('props').get('completenessPercent'))
      activeChilds++
      totalWeight+=Number(x.get('props').get('completenessWeight'))
    }
  })
  if(activeChilds===0)
    return -1
  if(activeChilds===1)
    return sum/activeChilds
  let pondAv = 0
  childs.map((x,i)=>{
    if(x.get("props").get("completenessActive")) {
      pondAv +=Number(x.get('props').get('completenessPercent'))*(x.get("props").get("completenessWeight")/totalWeight)
    }
  })
  return pondAv

}

export const updateAverageOfParents= (tasksY, child)=>{
  if(child.get("props").get("isChildTask")){
    const parent = findTaskById(tasksY, child.get("props").get("parentTask"))
    const parentChilds = getDirectChilds(parent, tasksY)
    let hasAnyActiveChild = false
    for(let i=0;i<parentChilds.length;i++){
      if(parentChilds[i].get("props").get("completenessActive"))
        hasAnyActiveChild=true
    }
    parent.get("props").set("completenessActive", hasAnyActiveChild)
    const average = getAveragePercentFromChilds(getDirectChilds(parent, tasksY))
    parent.get("props").set("completenessPercent", average===-1?0:average)
    updateAverageOfParents(tasksY, parent)
  }
}

export const updateAverageOfParentsAndChild= (tasksY, child)=>{
  const childs = getDirectChilds(child, tasksY)
  let hasAnyActiveChild = false
  for(let i=0;i<childs.length;i++){
    if(childs[i].get("props").get("completenessActive"))
      hasAnyActiveChild=true
  }
  child.get("props").set("completenessActive", hasAnyActiveChild)
  const average = getAveragePercentFromChilds(getDirectChilds(child, tasksY))
  child.get("props").set("completenessPercent", average===-1?0:average)
  updateAverageOfParents(tasksY, child)
}

export const deleteNote = (tasksY, taskY, index) =>{
  const childs = getDirectChilds(taskY, tasksY)
  childs.map(x=>{
    x.get("props").set("isChildTask", false)
    x.get("props").set("parentTaks", "")
  })

  const parent = findTaskById(tasksY, taskY.get("props").get("parentTask"))
  taskY.get("props").set("completenessActive", false)
  updateWeightOfParents(tasksY, taskY)
  if(taskY.get("props").get("isChildTask")) {
    taskY.get('props').set('isChildTask', false)
    taskY.get('props').set('parentTask', '')

    updateAverageOfParentsAndChild(tasksY, parent)
  }


  tasksY.delete(index,1)
}


export const createNewTask = ({ fullCords, tasksY, parent }) =>{
  const newTask = new Y.Map()
  const CHILD_NOTE_DISTANCE=5
  const newId = uuidv4()
  newTask.set("id", newId)
  const yname = new Y.Text()
  yname.insert(0, !!parent?("Nuevo hijo de " + parent.get("name").toString()):"Nueva tarea")
  newTask.set("name", yname)
  const ydesc = new Y.Text()
  ydesc.insert(0, "Descripción de la tarea")
  newTask.set("description", ydesc)
  const newNote = new Y.Map()
  if(parent){
    const parentNote = parent.get("note")
    let newNotePosRelToCellX = Number(parentNote.get("posRelToCellX"))
    let newNotePosRelToCellY = Number(parentNote.get("posRelToCellY"))
    if(Number(parentNote.get("posRelToCellX"))<95)
      newNotePosRelToCellX=Number(parentNote.get("posRelToCellX"))+CHILD_NOTE_DISTANCE
    if(Number(parentNote.get("posRelToCellY"))<95)
      newNotePosRelToCellY=Number(parentNote.get("posRelToCellY"))+CHILD_NOTE_DISTANCE
    newNote.set("posRelToCellX", newNotePosRelToCellX)
    newNote.set("posRelToCellY", newNotePosRelToCellY)
    newNote.set("posX", fullCords.xIni+(fullCords.xEnd-fullCords.xIni)*(newNotePosRelToCellX/100))
    newNote.set("posY", fullCords.yIni+(fullCords.yEnd-fullCords.yIni)*(newNotePosRelToCellY/100))
  }else{
    newNote.set("posRelToCellX", 50)
    newNote.set("posRelToCellY", 25)
    newNote.set("posX", fullCords.xIni+(fullCords.xEnd-fullCords.xIni)/(100/50))
    newNote.set("posY", fullCords.yIni+(fullCords.yEnd-fullCords.yIni)/(100/25))
  }
  const zIndexMap = new Y.Map()
  zIndexMap.set("value", getBiggestZIndex(tasksY)+1)
  newNote.set("zIndex", zIndexMap)
  const newCoords = new Y.Array()
  newCoords.push(fullCords.coords)
  newNote.set("actCoords", newCoords)
  newTask.set("note", newNote)
  const yTaskProps = new Y.Map()
  yTaskProps.set('completenessActive', false)
  yTaskProps.set('completenessPercent', 0)
  yTaskProps.set('completenessWeight', 5)
  yTaskProps.set('isChildTask', !!parent)
  yTaskProps.set('parentTask', parent?parent.get('id'):"")
  yTaskProps.set('isAssigned', false)
  yTaskProps.set('assignedTo', "")
  newTask.set("props", yTaskProps)
  if(parent){
    parent.get("props").set("isAssigned", false)
    parent.get("props").set("assignedTo", "")
  }
  const fields = new Y.Array()
  newTask.set("fields", fields)

  tasksY.push([newTask])
  updateAverageOfParents(tasksY, newTask)
  return newTask
}

export const getBiggestZIndex = (tasksY) =>{
  updateZIndexes(tasksY)
  let out = 0
  tasksY.map(task=>{
    const newZ = Number(task.get("note").get("zIndex").get("value"))
    if(newZ>out)
      out=newZ
  })
  return out
}

export const updateZIndexes = (tasksY) =>{
  let unsortedList = []
  tasksY.map(x=>unsortedList.push(x))
  unsortedList.sort((a,b)=>
    a.get("note").get("zIndex").get("value")>b.get("note").get("zIndex").get("value")
  )
  unsortedList.map((x,i)=>{
    if(x.get("note").get("zIndex").get("value")!==i+1) {
      x.get('note').get('zIndex').set('value', i+1)
    }
  })

}

export const updateWeightOfParents= (tasksY, child)=>{
  if(child.get("props").get("isChildTask")){
    const parent = findTaskById(tasksY, child.get("props").get("parentTask"))
    const parentChilds = getDirectChilds(parent, tasksY)
    let hasAnyActiveChild = false
    let childsWeight = 0
    for(let i=0;i<parentChilds.length;i++){
      if(parentChilds[i].get("props").get("completenessActive")) {
        hasAnyActiveChild = true
        childsWeight+=Number(parentChilds[i].get("props").get('completenessWeight'))
      }
    }
    parent.get("props").set("completenessActive", hasAnyActiveChild)
    parent.get("props").set("completenessWeight", childsWeight)
    updateWeightOfParents(tasksY, parent)
  }
}

export const getNotePanelCoords = ({ handlePosPercentTop, handlePosPercentLeft, panelsDataY }) => {
  const panelCoords = panelDataToPanelCoords(panelsDataY.toJSON())
  let actualPanelCoords
  panelCoords.map(x => {
    if (handlePosPercentLeft >= x.xIni &&
      handlePosPercentLeft <= x.xEnd &&
      handlePosPercentTop >= x.yIni &&
      handlePosPercentTop <= x.yEnd
    ) {
      actualPanelCoords = x
    }
  })
  return actualPanelCoords ? actualPanelCoords : 'not found'
}

export const createNewField = (taskY, fieldName, fieldValue)=>{
  const newField = new Y.Map()
  const yname = new Y.Text()
  yname.insert(0, fieldName)
  newField.set("name", yname)
  const yvalue = new Y.Text()
  yvalue.insert(0, fieldValue)
  newField.set("value", yvalue)
  const newId = uuidv4()
  newField.set("id", newId)
  taskY.get("fields").push([newField])
}

export const getFieldById = (taskY, fieldId) =>{
  const fields = taskY.get("fields")
  for (let i=0;i<fields.length;i++){
    if(fields.get(i).get("id")===fieldId) {
      return { index: i, field: fields[i] }
    }
  }
  return {index: -1, field: null}
}

export const deleteFieldById = (taskY, fieldId)=>{
  const field=getFieldById(taskY, fieldId)
  taskY.get("fields").delete(field.index)
}

export const changeCompletenessActive = (taskY)=>{
  const active = taskY.get('props').get('completenessActive')
  if(!active) {
    taskY.get('props').set('completenessWeight', 5)
    taskY.get('props').set('completenessPercent', 0)
  }
  taskY.get('props').set('completenessActive', !active)
}

export const changeCompletenessActiveAndUpdateChilds = (task, tasksY) =>{
  changeCompletenessActive(task)
  updateAverageOfParents(tasksY, task)
  updateWeightOfParents(tasksY, task)
}

const getChildsOfTask = (taskY, tasksY) => {
  let out = []
  const directChilds = getDirectChilds(taskY, tasksY)
  directChilds.map(x=>{
    out.push(x.get('id').toString())
    const childChilds = getChildsOfTask(x, tasksY)
    out = out.concat(childChilds)
  })
  return out
}

const getChildsFromTaskList = (taskList, tasksY) =>{
  let allChilds = []
  const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a];
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
  }
  taskList.map(x=>{
    const thisTaskChilds = getChildsOfTask(x, tasksY)
    allChilds=merge(allChilds, thisTaskChilds)
  })
  return allChilds
}

const deleteSelfContainedChildsInList = (taskList, tasksY) =>{
  const childs = getChildsFromTaskList(taskList, tasksY)
  let listWithoutChilds = []
  taskList.map(x=>{
    if(childs.findIndex(id=>id===x.get("id"))===-1)
      listWithoutChilds.push(x)
  })
  return listWithoutChilds
}

export const getStatsFromTaskList = (taskList, tasksY) =>{
  let totalWeight = 0
  let average = 0
  let completedTasks = 0
  let tasksWithCompletenessActive = 0
  const listWithoutChilds = deleteSelfContainedChildsInList(taskList, tasksY)
  listWithoutChilds.map(x=>{
    if(x.get("props").get("completenessActive")) {
      totalWeight += Number(x.get('props').get('completenessWeight'))
      average += Number(x.get('props').get('completenessPercent')) * Number(x.get('props').get('completenessWeight'))
    }
  })
  taskList.map(x=>{
    if(x.get("props").get("completenessActive")){
      tasksWithCompletenessActive++
      if(Number(x.get("props").get("completenessPercent"))===100)
        completedTasks++
    }
  })
  average=totalWeight>0?average/totalWeight:100
  return {
    totalWeight: totalWeight,
    completedWeight: Math.round(10 * totalWeight*average/100)/10,
    completedWeightPercent: Math.round(average * 10)/10,
    totalTasks: taskList.length,
    completedTaskPercentAll: taskList.length>0?Math.round(100*completedTasks/taskList.length * 10)/10:100,
    completedTaskPercentActive: tasksWithCompletenessActive>0?Math.round(10*100*completedTasks/tasksWithCompletenessActive) / 10:100,
    tasksWithCompletenessActive: tasksWithCompletenessActive,
    completedTasks: completedTasks}
}

export const getStatsForProject = (tasksY) =>{
  let taskList = []
  tasksY.map(x=>{
    taskList.push(x)
  })
  return getStatsFromTaskList(taskList, tasksY)
}