import { useEffect, useState } from 'react'

import {
  findTaskById, isChild, updateAverageOfParents,
  updateAverageOfParentsAndChild,
  updateWeightOfParents
} from '../../../../../yjs/functions/YjsTasksFunctions.js'

export const ParentSelector = ({ tasksY, task }) => {
  const [isChildTask, setIsChildTask] = useState(false)
  const [parentTask, setParentTask] = useState(false)
  const [taskArr, setTaskArr] = useState([])

  const SelectOption = ({parent}) =>{
    const [name, setName] = useState(parent.get('name').toString())
    useEffect(()=>{
      let ob
      parent.observeDeep(ob=e=> {
          setName(parent.get('name').toString())
        }
      )
      return()=>{
        parent.unobserveDeep(ob)
      }
    }, [])

    return(
      <option key={parent.get('id')} value={parent.get('id')}>{name}</option>
    )
  }


  useEffect(()=>{
    let propOb
    const taskProps = task.get('props')
    taskProps.observe(propOb=e=>{
      setIsChildTask(task.get('props').get('isChildTask'))
      setParentTask(task.get('props').get('parentTask'))
    })
    let tasksYOb
    tasksY.observe(tasksYOb=e=>{
      setTaskArr(tasksY)
    })
    setTaskArr(tasksY)
    setIsChildTask(task.get('props').get('isChildTask'))
    setParentTask(task.get('props').get('parentTask'))
    return()=>{
      taskProps.unobserve(propOb)
      tasksY.unobserve(tasksYOb)
    }
  }, [])

  const onChangeParentSelector = (e) => {
    if (Number(e.target.value) === -1) {
      if (task.get('props').get('isChildTask')) {
        const parent = findTaskById(tasksY, task.get('props').get('parentTask'))
        task.get('props').set('isChildTask', false)
        task.get('props').set('parentTask', '')
        updateWeightOfParents(tasksY, task)
        updateAverageOfParentsAndChild(tasksY, parent)
      } else {
        task.get('props').set('isChildTask', false)
        task.get('props').set('parentTask', '')
      }

    } else {
      task.get('props').set('parentTask', e.target.value)
      task.get('props').set('isChildTask', true)
      const parent = findTaskById(tasksY, e.target.value)
      parent.get('props').set('assignedTo', '')
      parent.get('props').set('isAssigned', false)
      updateWeightOfParents(tasksY, task)
      updateAverageOfParents(tasksY, task)
    }
  }

  return <select className={'bg-color3 p-1 rounded text-white cursor-pointer w-48'}
                 value={isChildTask ? parentTask : -1}
                 name="select"
                 onChange={onChangeParentSelector}>
    <option value={-1}>Ninguno</option>
    {
      taskArr.map((x, i) => {
        if (x.get('id') !== task.get('id') && !isChild(x, task, tasksY))
          return <SelectOption key={i} parent={x} tasksY={tasksY}/>
      })
    }
  </select>
}