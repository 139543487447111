const AuthPanelBackground = (props) => {
    const {showLogo, children} = props;
    return (
        <div className="w-screen h-full bg-gradient fixed flex flex-col justify-center items-center overflow-auto">
            <div className="m-auto flex flex-col items-center gap-4 justify-start">
                {showLogo && <div className={"text-white text-4xl h-[100%] mt-2"}>
                    KanbanApp
                </div>}
                <div className={"bg-color3 rounded-2xl p-4 md:p-8 shadow md:w-full w-fit mb-2 mt-2"}>
                    {children}
                </div>
            </div>
            <div className={"flex justify-end p-2 pb-4 gap-2 w-full text-white text-xl"}> Creado por:<a className={"font-bold underline"} href={"https://jaimejcorral.com"}> Jaime José Corral García</a></div>
        </div>
    )
}

export default AuthPanelBackground