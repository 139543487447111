import { TaskTableHeader } from '../taskRows/TaskRow.jsx'
import { useEffect, useState } from 'react'
import { getStatsFromTaskList } from '../../../../../yjs/functions/YjsTasksFunctions.js'
import CircularProgress from '../common/CircularProgress.jsx'

const Category = ({ allTasks, category, tasksY }) => {
  const [categoryStats, setCategoryStats] = useState({})

  const countTasksInCategory = (tasksY, category) => {
    let out = 0
    tasksY.map((x, i) => {
      if (JSON.stringify((x.get('note').get('actCoords').toJSON())) === JSON.stringify(category.coords))
        out += 1
    })
    return out
  }

  const getCatIndex = (tasksY, category) => {
    let out = []
    tasksY.map((x, i) => {
      if (JSON.stringify((x.get('note').get('actCoords').toJSON())) === JSON.stringify(category.coords))
        out.push(i)
    })
    return out
  }
  const getCatTasks = (tasksY, category) => {
    let out = []
    tasksY.map((x, i) => {
      if (JSON.stringify((x.get('note').get('actCoords').toJSON())) === JSON.stringify(category.coords))
        out.push(x)
    })
    return out
  }

  const [tasksInCategory, setTasksInCategory] = useState(countTasksInCategory(tasksY, category) > 0)
  const [thisCatTasksIndexes, setThisCatTasksIndexes] = useState(getCatIndex(tasksY, category))

  const updateTaskIndexes = () => {
    setThisCatTasksIndexes(getCatIndex(tasksY, category))
  }
  const updateTasksInCategory = () => {
    const newCount = countTasksInCategory(tasksY, category)
    if (newCount !== tasksInCategory)
      setTasksInCategory(newCount)
  }

  useEffect(() => {
    let newOB
    tasksY.observeDeep(newOB = f => {
      updateTasksInCategory()
      updateTaskIndexes()
      setCategoryStats(getStatsFromTaskList(getCatTasks(tasksY, category), tasksY))
    })
    updateTasksInCategory()
    updateTaskIndexes()
    setCategoryStats(getStatsFromTaskList(getCatTasks(tasksY, category), tasksY))
    return () => {
      tasksY.unobserveDeep(newOB)
    }
  }, [])

  return (
    <div className={'bg-color4 flex flex-col w-full rounded-2xl p-2 gap-2 border-2 border-white'}>
      <div id={'title'} className={'flex sm:flex-row flex-col text-white justify-between'}>
        <a className={'text-white text-xl font-bold ellipsis'}>{category.name}</a>
        <div className={"flex flex-row gap-6 items-start pr-2 pl-2 overflow-hidden"}>
          <CircularProgress text={"Trabajo completado"} percent={categoryStats.completedWeightPercent}
                            done={categoryStats.completedWeight} total={categoryStats.totalWeight}/>
          <CircularProgress text={"Tareas completadas (solo activas)"} percent={categoryStats.completedTaskPercentActive}
                            done={categoryStats.completedTasks} total={categoryStats.tasksWithCompletenessActive}/>
        </div>
      </div>
      <div>
        {tasksInCategory ?
          <div className={'bg-color3 rounded-2xl overflow-hidden'}>
            <TaskTableHeader/>
            {allTasks.map((x, i) => thisCatTasksIndexes.find(z => z === i) > -1 ? x : null)}
          </div> :
          <div>
            <a className={'text-white text-lg'}>Todavía no hay tareas en esta categoría</a>
          </div>}

      </div>
    </div>
  )
}

export default Category