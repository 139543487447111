import { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { updateAverageOfParents, updateWeightOfParents } from '../../../../yjs/functions/YjsTasksFunctions.js'

export const CompletenessSelectorValueNote = ({ task, tasksY }) => {
  const plusIcon = <FontAwesomeIcon icon={faPlus} color="black"/>
  const minusIcon = <FontAwesomeIcon icon={faMinus} color="black"/>
  const [completenessActive, setCompletenessActive] = useState(false)
  const [completenessWeight, setCompletenessWeight] = useState(0)

  useEffect(()=>{
    const props = task.get('props')
    let ob
    props.observe(ob=e=>{
      setCompletenessActive(props.get('completenessActive'))
      setCompletenessWeight(props.get('completenessWeight'))
    })
    setCompletenessActive(props.get('completenessActive'))
    setCompletenessWeight(props.get('completenessWeight'))
    return()=>{
      props.unobserve(ob)
    }
  }, [])

  const values = [
    {name: "1", value: 1},
    {name: "2", value: 2},
    {name: "3", value: 3},
    {name: "4", value: 4},
    {name: "5", value: 5},
    {name: "6", value: 6},
    {name: "7", value: 7},
    {name: "8", value: 8},
    {name: "9", value: 9},
    {name: "10", value: 10},
  ]
  return (
    <div className={'flex flex-col gap-1 text-black '}>

      {completenessActive &&
        <div className={'flex flex-row gap-2'}>
          <select className={"bg-color3 text-white rounded p-1 w-full cursor-pointer"}
            value={completenessWeight}
                  onChange={e=>{
                    task.get("props").set("completenessWeight", e.target.value)
                    updateWeightOfParents(tasksY, task)
                    updateAverageOfParents(tasksY, task)
                  }
          }>
            {values.map((x,i)=>{return <option key={i} value={x.value}>{x.name}</option>})}
          </select>
        </div>
      }
    </div>
  )
}

export default CompletenessSelectorValueNote