import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleCheck, faCircleExclamation, faSpinner, faXmark} from '@fortawesome/free-solid-svg-icons'

import {RegisterFromField} from '../common/auxComponents.jsx'
import {mailExists, registerUser, userExists} from '../../../../backend/userService.js'
import {
    correctPassLength,
    passwordIsCorrect,
    usesAllRequiredChars,
    validatePass
} from '../../../validators/passValidator.js'
import {isOnlyLowercaseAndNumbers, isValidUser} from '../../../validators/userValidator.js'
import {isValidMail} from '../../../validators/mailValidator.js'
import useGoToUrl from '../../../common/useGoToUrl.jsx'

const whiteExclamationIcon = <FontAwesomeIcon icon={faCircleExclamation} color="white"/>
const redExclamationIcon = <FontAwesomeIcon icon={faCircleExclamation} color="red"/>
const checkIcon = <FontAwesomeIcon icon={faCircleCheck} color="green"/>
const loadingIcon = <FontAwesomeIcon icon={faSpinner} color="green"/>

const RegisterForm = ({}) => {
    const dispatch = useDispatch()
    const [fullNameField, setFullNameField] = useState('')
    const [mailField, setMailField] = useState('')
    const [userField, setUserField] = useState('')
    const [userFieldExists, setUserFieldExists] = useState(false)
    const [mailFieldExists, setMailFieldExists] = useState(false)
    const [passwordField, setPasswordrField] = useState('')
    const [passValidationResult, setPassValidationResult] = useState(['min', 'uppercase', 'lowercase', 'digits', 'symbols'])
    const [repeatPasswordField, setRepeatPasswordField] = useState('')
    const [checkingUser, setCheckingUser] = useState(false)
    const navigate = useGoToUrl()

    const userIsCorrect = () => {
        return isValidUser(userField) && !userFieldExists
    }
    const [userNameValProps, setUserNameValProps] = useState(
        {
            largerThanZero: false,
            shorterThan4: false,
            largerThan12: false
        }
    )
    const mailIsCorrect = () => {
        return mailField.length > 0 && isValidMail(mailField)
    }

    const handleUserNameChange = (event) => {
        const name = event.target.value.trim().toLowerCase()
        setUserField(name)
        if (isValidUser(name)) {
            setCheckingUser(true)
            dispatch(userExists(
                name,
                () => {
                    setUserFieldExists(true)
                    setCheckingUser(false)
                },
                () => {
                    setUserFieldExists(false)
                    setCheckingUser(false)
                },
            ))
        } else
            setUserFieldExists(false)

    }

    const handleFullNameChange = (event) => {
        setFullNameField(event.target.value.trimStart())
    }

    const handleMailChange = (event) => {
        const mail = event.target.value.trim()
        setMailField(mail)
        if (mailIsCorrect() && mail.length > 0)
            mailExists(
                mail,
                () => {
                    setMailFieldExists(true)
                },
                () => {
                    setMailFieldExists(false)
                },
            )
        else
            setMailFieldExists(false)
    }

    const handlePasswordChange = (event) => {
        let pass = event.target.value.trim()
        setPasswordrField(pass)
        setPassValidationResult(validatePass(pass, {list: true}))
    }

    const handleRepeatPasswordChange = (event) => {
        let repeatPass = event.target.value.trim()
        setRepeatPasswordField(repeatPass)
    }

    const allFieldsCorrect = () => {
        return passwordIsCorrect(passValidationResult) && passwordField === repeatPasswordField && userIsCorrect() && mailIsCorrect() && fullNameField.length > 1 && !mailFieldExists
    }

    const onSubmit = () => {
        event.preventDefault()
        dispatch(registerUser(userField.toLowerCase().trim(), passwordField, fullNameField.trim(), mailField.toLowerCase().trim(), () => {
            navigate('/')
        }))
    }
    return (
        <div className={"w-[85vw] md:w-[650px]"}>
            <div className={'flex flex-row justify-end '}>
                <div onClick={() => {
                    navigate('/login')
                }} className={'cursor-pointer'}>
                    <FontAwesomeIcon icon={faXmark} color="white" size="2x"/>
                </div>
            </div>
            <form className={'w-full flex flex-col gap-2'} onSubmit={onSubmit} noValidate>

                <a className="text-xl text-white font-bold ml-2 w-full">Cubre este formulario para empezar a usar la
                    aplicación:</a>
                <RegisterFromField
                    autoFocus={true}
                    name={'Nombre'}
                    value={fullNameField}
                    handleChange={handleFullNameChange}
                    goodConds={fullNameField.length > 0}
                    dataTestId={"fullNameInput"}
                    validatePanelConditions={[
                        {
                            condition: fullNameField.length < 1,
                            text: 'Obligatorio.',
                            icon: whiteExclamationIcon
                        },
                        {
                            condition: fullNameField.length > 0,
                            text: '',
                            icon: checkIcon
                        },
                    ]}
                />
                <RegisterFromField
                    name={'Correo Electrónico'}
                    value={mailField}
                    dataTestId={"mailInput"}
                    handleChange={handleMailChange}
                    goodConds={mailIsCorrect() && mailField.length > 0 && !mailFieldExists}
                    badConds={(!mailIsCorrect() || mailFieldExists) && mailField.length > 0}
                    validatePanelConditions={[
                        {
                            condition: !mailFieldExists && !mailField.length > 0,
                            text: 'Obligatorio.',
                            icon: whiteExclamationIcon
                        },
                        {
                            condition: mailField.length > 0 && !mailIsCorrect(),
                            text: 'Introduce un correo electrónico válido.',
                            icon: redExclamationIcon
                        },
                        {
                            condition: mailIsCorrect() && mailFieldExists,
                            text: 'Ya existe un usuario con este correo.',
                            icon: redExclamationIcon
                        },
                        {
                            condition: mailIsCorrect() && !mailFieldExists,
                            text: '',
                            icon: checkIcon
                        },
                    ]}
                />
                <RegisterFromField name={'Nombre de usuario'}
                                   value={userField}
                                   dataTestId={"usernameInput"}
                                   handleChange={handleUserNameChange}
                                   goodConds={isValidUser(userField) && !userFieldExists}
                                   badConds={userField.length > 0 && (!isValidUser(userField) || userFieldExists)}
                                   maxLength={12}
                                   validatePanelConditions={[
                                       {
                                           condition: userFieldExists === true,
                                           text: 'Ya existe un usuario con ese nombre.',
                                           icon: redExclamationIcon
                                       },
                                       {
                                           condition: !userFieldExists,
                                           text: 'Debe contener entre 4 y 12 caractéres.',
                                           icon: (userField.length < 1) ? whiteExclamationIcon : ((isValidUser(userField)) ? checkIcon : redExclamationIcon),
                                       },
                                       {
                                           condition: !userFieldExists,
                                           text: 'Solo puede contener letras y números.',
                                           icon: (userField.length < 1) ? whiteExclamationIcon : ((isOnlyLowercaseAndNumbers(userField)) ? checkIcon : redExclamationIcon),
                                       },
                                       {
                                           condition: !userFieldExists,
                                           text: 'No distingue entre mayúsculas y minúsculas.',
                                           icon:  null
                                       },
                                   ]}
                />
                <RegisterFromField
                    name={'Contraseña'}
                    value={passwordField}
                    dataTestId={"passInput"}
                    handleChange={handlePasswordChange}
                    goodConds={passwordIsCorrect(passValidationResult)}
                    badConds={!passwordIsCorrect(passValidationResult) && passwordField.length > 0}
                    maxLength={52}
                    password={true}
                    validatePanelConditions={[
                        {
                            condition: true,
                            text: 'Debe tener entre 6 y 52 caractéres.',
                            icon: (correctPassLength(passValidationResult) ? checkIcon : (passwordField.length > 0 ? redExclamationIcon : whiteExclamationIcon))
                        },
                        {
                            condition: true,
                            text: 'Debe contener por lo menos una mayúscula, una minúscula y un número.',
                            icon: usesAllRequiredChars(passValidationResult) ? checkIcon : (passwordField.length > 0 ? redExclamationIcon : whiteExclamationIcon)
                        },
                    ]}
                />
                <RegisterFromField
                    name={'Vuelve a introducir la contraseña'}
                    value={repeatPasswordField}
                    dataTestId={"repeatPassInput"}
                    handleChange={handleRepeatPasswordChange}
                    goodConds={passwordIsCorrect(passValidationResult) && repeatPasswordField === passwordField}
                    badConds={repeatPasswordField.length > 0 && repeatPasswordField !== passwordField}
                    maxLength={52}
                    password={true}
                    validatePanelConditions={[
                        {
                            condition: repeatPasswordField.length < 1,
                            text: 'Obligatorio.',
                            icon: whiteExclamationIcon
                        },
                        {
                            condition: repeatPasswordField.length > 0 && repeatPasswordField !== passwordField,
                            text: 'Las contraseñas deben coincidir.',
                            icon: redExclamationIcon
                        },
                        {
                            condition: passwordIsCorrect(passValidationResult) && repeatPasswordField === passwordField,
                            text: '',
                            icon: checkIcon
                        },
                    ]}
                />
                <div id="buttonDiv" className={'flex md:flex-row justify-end'}>
                    <button data-testid={"registerButton"} type={'submit'} disabled={!allFieldsCorrect()}
                            style={allFieldsCorrect() ? {cursor: 'pointer'} :
                                {cursor: 'not-allowed', opacity: '50%'}
                            }
                            className={'bg-color1 text-white font-bold py-4 px-8 rounded text-l'}>
                        <a>¡Regístrame!</a>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default RegisterForm