import { useState, useEffect } from 'react';
import { getUserPublicInfoMultiple } from '../../../backend/userService.js'
import { useDispatch, useSelector } from 'react-redux'

const useUserNameCache = () => {
  const dispatch = useDispatch()
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const [cache, setCache] = useState({})

  useEffect(()=>{
    const updateNameCache = () =>{
      const userIds = []
      yjsData.collaboratorsY.map(x=>{
        userIds.push(x.get("id"))
      })
      dispatch(getUserPublicInfoMultiple(userIds, (data)=>{
        let newCache = {}
        data.map(x=>newCache[x.id]=x)
        setCache(newCache)
      }))
    }
    let collabOb
    yjsData.collaboratorsY.observe(collabOb=e=>updateNameCache())
    updateNameCache()
    return()=>{
      yjsData.collaboratorsY.unobserve(collabOb)
    }
  }, [])


  return cache;
};

export default useUserNameCache;