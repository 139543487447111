import { HocuspocusProvider } from "@hocuspocus/provider";
import { getServiceToken } from '../backend/userService.js'

export const getYProvider = (name) =>{
  const provider  = new HocuspocusProvider({
    url: import.meta.env.VITE_HOCUSPOCUS_URL,
    name: name,
    token: getServiceToken(),

  });
  return provider
}