import { useEffect, useState } from 'react'
import CenterCard from '../../../../appInterface/components/CenterCard.jsx'
import Category from './Category.jsx'
import TaskRow from '../taskRows/TaskRow.jsx'
import { panelDataToPanelCoords } from '../../../../../yjs/functions/YjsPanelFuncions.js'
import {useDispatch, useSelector} from 'react-redux'
import {setHideMenu} from "../../../../../redux/slices/kanbanSlice.js";

const TasksPage = () => {
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const collaboratorsY=yjsData.collaboratorsY
  const tasksY=yjsData.tasksY
  const panelsDataY=yjsData.kanbanY
  const [panelCoords, setPanelCoords] = useState([])
  const [expanded, setExpanded] = useState({})
  const [taskArray, setTaskArray] = useState(tasksY)
  const [allTasks, setAllTasks] = useState([])
  const [ob, setOb] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    tasksY.map((task, i) => {
      let exp = { ...expanded }
      exp[task.get('id')] = false
      setExpanded(expanded)
    })


    tasksY.observe(e=> {
        setAllTasks(
          tasksY.map((x, i) => {
            return <TaskRow
              indexInCategory={0} index={i} key={x.get("id")}
              task={x} tasksY={tasksY} collaboratorsY={collaboratorsY}
              />
          })
        )
      }
    )
    setAllTasks(
      tasksY.map((x, i) => {
        return <TaskRow
          indexInCategory={0} index={i} key={x.get("id")}
          task={x} tasksY={tasksY} collaboratorsY={collaboratorsY}
        />
      })
    )

    const panelsDataObj = panelsDataY.toJSON()
    if(JSON.stringify({})!==JSON.stringify(panelsDataObj)) {
      const fullCats = panelDataToPanelCoords(panelsDataObj)
      let pCoords = []
      fullCats.map(x => pCoords.push({ name: x.name, coords: x.coords }))
      if (JSON.stringify(panelCoords) !== JSON.stringify(pCoords))
        setPanelCoords(pCoords)
    }
    dispatch(setHideMenu(false))
    return () => {
      ob && panelsDataY.unobserveDeep(ob)
      setOb(null)
    }
  }, [])

  useEffect(() => {
    ob && panelsDataY.unobserveDeep(ob)
    let newOb
    panelsDataY.observeDeep(newOb = x => {
      const docObj = panelsDataY.toJSON()
      if (JSON.stringify(docObj) === JSON.stringify({}))
        return
      const fullCats = panelDataToPanelCoords(docObj)
      let pCoords = []
      fullCats.map(x => pCoords.push({ name: x.name, coords: x.coords }))
      if (JSON.stringify(panelCoords) !== JSON.stringify(pCoords))
        setPanelCoords(pCoords)
    })
    setOb(newOb)
  }, [panelCoords])

  return (
    <CenterCard color={"sdf"} extraClasses={'md:w-[700px] w-full mb-8'}>
      <div  className={'flex flex-col gap-2'}>
        {panelCoords.map((x, i) => {
          return <Category allTasks={allTasks} tasksY={tasksY}
                           collaboratorsY={collaboratorsY}
                           key={x.coords.toString()}
                           category={x}
                           expanded={expanded}
                           setExpanded={setExpanded}
          />
        })}
      </div>

    </CenterCard>
  )
}

export default TasksPage