import {Navigate, useLocation} from "react-router-dom";
import {getServiceToken, loginFromToken, removeServiceToken} from "./backend/userService.js";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";

export const PublicRoutes = (props) => {
    const location = useLocation();
    const [checked, setChecked] = useState(false)
    const [isAuth, setIsAuth] = useState(false)
    const dispatch = useDispatch()
    const {children} = props
    useEffect(()=>{
        if (getServiceToken() != null){
            dispatch(loginFromToken(getServiceToken(),
                () => {
                    setIsAuth(true)
                    setChecked(true)
                },
                ()=>{
                    setChecked(true)
                })
            )
        }
        else{
            setChecked(true)
        }
    }, [])

    if(!checked)
        return
    return isAuth ? (
        <Navigate to="/" state={{ from: location }} replace />
    ) : (
        children
    );
};

export const ProtectedRoutes = (props) => {
    const location = useLocation();
    const {children} = props
    const [checked, setChecked] = useState(false)
    const [isAuth, setIsAuth] = useState(false)
    const [badTOken, setBadTOken] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (getServiceToken() != null){
            dispatch(loginFromToken(getServiceToken(),
                () => {
                    setIsAuth(true)
                    setChecked(true)
                },
                ()=>{
                    removeServiceToken()
                    setChecked(true)
                    setBadTOken(true)
                })
            )
        }
        else{
            setChecked(true)
        }
    }, []);

    if(!checked)
        return

    return isAuth ? (
        children
    ) : (badTOken?<Navigate to={("/reAuth?from="+window.location.pathname+window.location.search)} state={{ from: location }} replace />
        :<Navigate to="/login" state={{ from: location }} replace />
    );
};