import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import DefaultImage from "../../../common/DefaultImage.js"
const PointerSelector = ({ awarenessInfo }) => {
  const size = 20

  return (
    <div style={{
      width: 0,
      height: 0,
      borderTop: size / 2 + 'px solid transparent',
      borderLeft: size + 'px solid #555',
      borderBottom: size / 2 + 'px solid transparent',
      borderLeftColor: 'white',
    }}>
    </div>
  )
}

const ImageCircle = ({ awarenessInfo, imgRoute }) => {
  const size = 35
  return (



        <img style={{
          width: size,
          height: size,
          borderRadius: '50%',
          backgroundColor: 'white',
          borderStyle: "solid",
          borderWidth: "3px",
          borderColor: awarenessInfo.user.color
        }} src={imgRoute} className={"shrink-0 min-w-full min-h-full"}/>


  )
}

const Pointer = ({ awarenessInfo, scale }) => {
  const defaultImg= DefaultImage
  const imgRoute = awarenessInfo.user.defaultimg? defaultImg: `data:image/png;base64, ${awarenessInfo.user.imgdata}`
  const [img, setImg] = useState(<ImageCircle awarenessInfo={awarenessInfo} imgRoute={imgRoute}/>)


  return (
    <div className={'absolute pointer-events-none h-24 w-24 z-50'}
         style={{
           left: awarenessInfo.user.mouseX*scale,
           top: awarenessInfo.user.mouseY*scale
         }}>
      <div className={'absolute left-0 top-0'}>
        <PointerSelector awarenessInfo={awarenessInfo}/>
      </div>
      <div className={'absolute left-0 top-0'}
           style={{ left: '0px', top: '8px' }}>
        {img}
      </div>

    </div>
  )
}

export default Pointer