import { useEffect, useState } from 'react'
import * as Y from 'yjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faLayerGroup,
  faPercent,
  faTrash,
  faUser,
  faWeightHanging,
} from '@fortawesome/free-solid-svg-icons'
import { ParentSelector } from '../tasks/taskRows/ParentSelector.jsx'
import { AssignedToSelector } from '../tasks/taskRows/AssignedToSelector.jsx'
import { CompletenessSelectorValueNote } from './CompletenessSelectorValueNote.jsx'
import CompletenessVisualizator from './CompletenessVisualizator.jsx'
import CompletenessWeightSelectorNote from './CompletenessWeightSelectorNote.jsx'
import { useSelector } from 'react-redux'
import YjsInput from '../awareness/yjsText/YjsInput.jsx'
import {
  changeCompletenessActiveAndUpdateChilds,
  createNewTask,
  deleteNote,
  findTaskById,
  getBiggestZIndex,
  getDirectChilds,
  getNotePanelCoords,
  hasChilds
} from '../../../../yjs/functions/YjsTasksFunctions.js'
import { getFullCords } from '../../../../yjs/functions/YjsPanelFuncions.js'
import NoteFields from './NoteFields.jsx'
import NotePropButton from './NotePropButton.jsx'

const Note = ({
  collaboratorsY,
  index,
  tasksY,
  containerRef,
  propsY,
  panelsDataY,
  setMovingNote,
  setActualNoteCoords,
  taskY,
    scale
}) => {
  const yDoc = useSelector((state) => state.kanban.yjsData.yDoc)
  const deleteIcon = <FontAwesomeIcon icon={faTrash} color="black" size={'2xs'}/>
  const createChildIcon = <FontAwesomeIcon icon={faArrowDown} color="black" size={'2xs'}/>
  const userIcon = <FontAwesomeIcon icon={faUser} color="white"/>
  const completenessBIcon = <FontAwesomeIcon icon={faPercent} color="white"/>
  const parentBIcon = <FontAwesomeIcon icon={faLayerGroup} color="white"/>

  const userIconBlack = <FontAwesomeIcon icon={faUser} color="black"/>
  const completenessBIconBlack = <FontAwesomeIcon icon={faPercent} color="black"/>
  const parentBIconBlack = <FontAwesomeIcon icon={faLayerGroup} color="black"/>
  const weightIconBlack = <FontAwesomeIcon icon={faWeightHanging} color="black"/>

  const [taskProps, setTaskProps] = useState({})
  const [hasChildsV, setHasChildsV] = useState(false)
  const noteColor = hasChildsV ? 'rgb(236,181,0)' : 'rgb(229,127,56)'
  const textAreaColor = hasChildsV ? 'rgb(255,206,26)' : 'rgb(255,147,84)'
  const [zIndex, setZIndex] = useState(0)
  const percentToPx = ({ top, left }) => {
    return {
      left: (left * containerRef.current.offsetWidth) / 100,
      top: (top * containerRef.current.offsetHeight) / 100
    }
  }
  const [notePos, setNotePos] = useState(null)
  const handlePosRelativeToNote = { top: -8, left: 110 }
  const handleSize = { width: 40, height: 20 }
  const [notePosPercent, setNotePosPercent] = useState({
    top: 0,
    left: 0
  })

  const pxToPercent = ({ top, left }) => {
    if(!containerRef.current)
      return {left:0, top:0}
    return {
      left: (left / containerRef.current.offsetWidth) * 100,
      top: (top / containerRef.current.offsetHeight) * 100
    }
  }

  const updateActiveParentChilds = () => {
    if (taskY.get('props') == null)
      return
    let parentActivePercentChilds = 0
    if (taskY.get('props').get('isChildTask')) {
      const parent = findTaskById(tasksY, taskY.get('props').get('parentTask'))
      const parentChilds = getDirectChilds(parent, tasksY)
      let activeChildCounter = 0
      parentChilds.map(x => {
        if (x.get('props').get('completenessActive'))
          activeChildCounter++
      })
    }
  }

  const getTaskProps = (taskY) =>{
    return {
      completenessActive: taskY.get('props').get('completenessActive'),
      assignerActive: taskY.get('props').get('isAssigned'),
      parentActive: taskY.get('props').get('isChildTask'),
      completenessPercent: taskY.get('props').get('completenessPercent'),
      completenessWeight: taskY.get('props').get('completenessWeight')
    }
  }

  const updateNotePos = () => {
    if (!containerRef.current)
      return
    const posPercent = {
      left: taskY.get('note').get('posX'),
      top: taskY.get('note').get('posY')
    }

    setNotePosPercent({
      top: posPercent.top,
      left: posPercent.left
    })
    const posPx = percentToPx({
      top: posPercent.top,
      left: posPercent.left
    })
    setNotePos(posPx)
  }

  useEffect(() => {

    const u = percentToPx({ left: taskY.get('note').get('posX'), top: taskY.get('note').get('posY') })
    setNotePos({ top: u.top, left: u.left })


    let propsOb
    const taskYProps = taskY.get('props')
    taskYProps.observe(propsOb = e => {
      setTaskProps(getTaskProps(taskY))
    })
    setTaskProps(getTaskProps(taskY))
    let noteOb
    const taskYNote = taskY.get('note')
    taskYNote.observe(noteOb = e => {
      updateNotePos()
    })
    let propsYOb
    propsY.observeDeep(propsYOb = e => {
      updateNotePos()
    })

    let oob
    tasksY.observeDeep(oob = e => {
      updateActiveParentChilds()
      setHasChildsV(hasChilds(taskY, tasksY))
    })

    setHasChildsV(hasChilds(taskY, tasksY))
    updateActiveParentChilds()
    updateNotePos()
    let zIndexOb
    const zIndexMap = taskY.get('note').get('zIndex')
    zIndexMap.observe(zIndexOb = e => {
      setZIndex(zIndexMap.get('value'))
    })
    setZIndex(zIndexMap.get('value'))
    return (() => {
      zIndexMap.unobserve(zIndexOb)
      taskYProps.unobserve(propsOb)
      taskYNote.unobserve(noteOb)
      propsY.unobserveDeep(propsYOb)
      tasksY.unobserveDeep(oob)
    })
  }, [])

useEffect(()=>{
  updateNotePos()
}, [scale])


  const CalculateMove = (touch) => (e) => {
    if(!touch && e.button!==0)
      return
    e.stopPropagation()
    const target = touch ? e.changedTouches[0] : e
    const firstMovementPosPx = {
      x: target.clientX+ handlePosRelativeToNote.left + handleSize.width / 2,
      y: target.clientY + handlePosRelativeToNote.top + handleSize.height / 2}

    const mod = {x: firstMovementPosPx.x*scale, y: firstMovementPosPx.y*scale}
    const mousemove = (e) => {
      const target = touch ? e.changedTouches[0] : e
      const nextMovementPosPx = {x: target.clientX, y: target.clientY}

      const distancePx = { x: nextMovementPosPx.x - firstMovementPosPx.x, y: nextMovementPosPx.y - firstMovementPosPx.y }
      setMovingNote(true)
      const width = containerRef.current.offsetWidth
      const height = containerRef.current.offsetHeight
      let newNotePos = {
        left: notePos.left + distancePx.x,
        top: notePos.top + distancePx.y
      }
      let newHandlePos = {
        left: newNotePos.left + handlePosRelativeToNote.left + handleSize.width / 2,
        top: newNotePos.top + (handlePosRelativeToNote.top + handleSize.height / 2)
      }
      newNotePos = {
        left: newHandlePos.left < 0 ? 0 - handlePosRelativeToNote.left - handleSize.width / 2 : (newHandlePos.left > width ? width - handlePosRelativeToNote.left - handleSize.width / 2 : newNotePos.left),
        top: newHandlePos.top < 0 ? 0 - handlePosRelativeToNote.top - handleSize.height / 2 : (newHandlePos.top > height ? height - handlePosRelativeToNote.top - handleSize.height / 2 : newNotePos.top)
      }
      newHandlePos = {
        left: newNotePos.left + handlePosRelativeToNote.left + handleSize.width / 2,
        top: newNotePos.top + (handlePosRelativeToNote.top + handleSize.height / 2)
      }
      setNotePos({ top: newHandlePos.top, left: newHandlePos.left })
      const nP = pxToPercent(newHandlePos)
      taskY.get('note').set('posY', nP.top)
      taskY.get('note').set('posX', nP.left)
      const handlePosPercent = pxToPercent(newHandlePos)
      const actCoords = getNotePanelCoords({
        handlePosPercentTop: handlePosPercent.top,
        handlePosPercentLeft: handlePosPercent.left,
        panelsDataY: panelsDataY
      })
      setActualNoteCoords(actCoords)
      const yActCoords = new Y.Array()
      yActCoords.insert(0, actCoords.coords)
      if (JSON.stringify(actCoords.coords) !== JSON.stringify(taskY.get('note').get('actCoords').toJSON())) {
        yDoc.transact(() => {
          taskY.get('note').get('actCoords').delete(0, taskY.get('note').get('actCoords').length)
          taskY.get('note').get('actCoords').push(actCoords.coords)
        })
      }
      const actCoordsPx = percentToPx({ top: actCoords.yIni, left: actCoords.xIni })
      const relPos = {
        top: newNotePos.top - actCoordsPx.top + (handlePosRelativeToNote.top + handleSize.height / 2),
        left: newNotePos.left - actCoordsPx.left + handlePosRelativeToNote.left + handleSize.width / 2
      }

      const relPosPercent = pxToPercent({
        top: (relPos.top / (actCoords.yEnd - actCoords.yIni)) * 100,
        left: (relPos.left / (actCoords.xEnd - actCoords.xIni)) * 100
      })
      taskY.get('note').set('posRelToCellX', relPosPercent.left)
      taskY.get('note').set('posRelToCellY', relPosPercent.top)

    }
    const mouseup = () => {
      if(touch){
        window.removeEventListener('touchend', mouseup)
        window.removeEventListener('touchmove', mousemove)
      }else{
        window.removeEventListener('mouseup', mouseup)
        window.removeEventListener('mousemove', mousemove)
      }
      setMovingNote(false)
    }
    if(touch){
      window.addEventListener('touchmove', mousemove)
      window.addEventListener('touchend', mouseup)
    }else{
      window.addEventListener('mousemove', mousemove)
      window.addEventListener('mouseup', mouseup)
    }

  }

  if (!taskY.get('props'))
    return


  const handleDPercent = pxToPercent({
    left: handlePosRelativeToNote.left + handleSize.width / 2,
    top: handlePosRelativeToNote.top + handleSize.height / 2
  })



  const NoteDebug = ()=>{
    const [task, setTask] = useState({})
    useEffect(()=>{
      let ob
      taskY.observeDeep(ob=e=>{
        setTask(taskY.toJSON())
      })
    }, [])
    return(<div>
      <div className={'touch-none flex justify-center  rounded-2xl p-2'}>
        <YjsInput
            style={{fontSize: "20px"}}
            backgroundColor={textAreaColor}
            field={taskY.get('name')}
            id={taskY.get('id') + 'name'}
            className={'touch-none w-full font-bold h-6 rounded-2xl pl-2 pr-2 focus:outline-none focus:shadow-outline focus:outline-orange-300 font-["handwritten"]'}/>
      </div>
      <div className={"flex flex-col"}>
        <a>Posx: {task.note && task.note.posX}</a>
        <a>Posy: {task.note && task.note.posY}</a>


      </div>

    </div>)
  }

  return (

      <div
          onTouchStart={e => {
            taskY.get('note').get('zIndex').set('value', getBiggestZIndex(tasksY) + 1)
            CalculateMove(true)(e)
          }}
          className={'touch-none bg-yellow-200 w-[250px] absolute rounded-bl-lg ' +
              'rounded-tr-lg select-none pt-2 rounded-br-lg'}
          style={{
            top: notePosPercent.top - handleDPercent.top + '%',
            left: notePosPercent.left - handleDPercent.left + '%', backgroundColor: noteColor,
            zIndex: zIndex
          }}
          onClick={() => {
            taskY.get('note').get('zIndex').set('value', getBiggestZIndex(tasksY) + 1)
          }}
      >
        <div style={{
          top: handlePosRelativeToNote.top + 'px',
          left: handlePosRelativeToNote.left + 'px',
          width: handleSize.width,
          height: handleSize.height
        }}
             onMouseDown={e => {
               taskY.get('note').get('zIndex').set('value', getBiggestZIndex(tasksY) + 1)
               CalculateMove(false)(e)
             }}
             className={'cursor-all-scroll touch-none handle bg-white absolute opacity-70 rotate-[6deg] border-b-gray-500 border-2'}></div>
        <div>
          <div className={'touch-none flex justify-center  rounded-2xl p-2'}>
            <YjsInput
                style={{fontSize: "20px"}}
                backgroundColor={textAreaColor}
                field={taskY.get('name')}
                id={taskY.get('id') + 'name'}
                className={'touch-none w-full font-bold h-6 rounded-2xl pl-2 pr-2 focus:outline-none focus:shadow-outline focus:outline-orange-300 font-["handwritten"]'}/>
          </div>
          <div className={'p-1'}>
            <NoteFields taskY={taskY} textAreaBckColor={textAreaColor}/>
          </div>
          <div className={'flex justify-center items-center gap-1'}>
            {!hasChildsV &&
                <NotePropButton isActive={taskProps.assignerActive} icon={userIcon}
                                onClick={() => {
                                  if (taskProps.assignerActive) {
                                    taskY.get('props').set('isAssigned', false)
                                    taskY.get('props').set('assignedTo', '')
                                  }
                                  setTaskProps({...taskProps, assignerActive: !taskProps.assignerActive})
                                }}
                                isClickable={!hasChildsV}
                />}
            {!hasChildsV &&
                <NotePropButton isActive={taskProps.completenessActive} icon={completenessBIcon}
                                onClick={() => {
                                  changeCompletenessActiveAndUpdateChilds(taskY, tasksY)
                                }}
                                isClickable={true}
                />}
            <NotePropButton isActive={taskProps.parentActive} icon={parentBIcon}
                            onClick={() => {
                              if (taskProps.parentActive) {
                                taskY.get('props').set('isChildTask', false)
                                taskY.get('props').set('parentTask', '')
                              }
                              setTaskProps({...taskProps, parentActive: !taskProps.parentActive})
                            }}
                            isClickable={true}/>
          </div>
          {(taskProps.assignerActive || taskProps.completenessActive || taskProps.parentActive) &&
              <div className={'p-1 flex flex-col gap-1'}>
                {!hasChildsV && taskProps.assignerActive &&
                    <div className={'flex gap-2 h-7 items-center'}>
                      <div className={"flex items-center justify-center w-6 h-8"}>
                        {userIconBlack}
                      </div>

                      <AssignedToSelector task={taskY}
                                          collaboratorsY={collaboratorsY}/>
                    </div>}
                {taskProps.parentActive &&
                    <div className={'flex gap-2 h-7 items-center'}>
                      <div className={"flex items-center justify-center w-6 h-8"}>
                        {parentBIconBlack}
                      </div>
                      <ParentSelector tasksY={tasksY} task={taskY}
                                      setAssignedUserInfo={() => {
                                      }}/></div>}
                {hasChildsV && taskProps.completenessActive &&
                    <div className={'flex gap-2 items-center'}>
                      <CompletenessVisualizator completenessPercent={taskProps.completenessPercent}
                                                completenessWeitght={taskProps.completenessWeight}
                                                percentIcon={completenessBIconBlack}
                                                weightIcon={weightIconBlack}/>
                    </div>}
                {taskProps.completenessActive && !hasChildsV &&
                    <div className={'flex flex-row gap-2 items-center'}>
                      <div className={"flex items-center justify-center w-6 h-8"}>
                        {completenessBIconBlack}
                      </div>
                      <CompletenessSelectorValueNote tasksY={tasksY} task={taskY}/>
                      <div className={"flex items-center justify-center w-6 h-8"}>
                        {weightIconBlack}
                      </div>
                      <CompletenessWeightSelectorNote task={taskY} tasksY={tasksY}/>
                    </div>
                }
              </div>}

          <div className={'flex flex-row gap-2 justify-end pr-2'}>
            <div className={'cursor-pointer h-6 flex justify-center items-center'} onClick={(e) => {
              e.stopPropagation()
              createNewTask({
                fullCords: getFullCords(panelsDataY, taskY.get('note').get('actCoords').toJSON()),
                tasksY: tasksY,
                parent: taskY,
                taskY: taskY
              })
            }}>
              <a className={'text-2xl'}>{createChildIcon}</a>
            </div>
            <div className={' cursor-pointer h-6 flex justify-center items-center'} onClick={(e) => {
              e.stopPropagation()
              deleteNote(tasksY, taskY, index)
            }}>
              <a className={'text-2xl'}>{deleteIcon}</a>
            </div>
          </div>
        </div>
      </div>

  )
}

export default Note