import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faSpinner} from "@fortawesome/free-solid-svg-icons";
import InputField from "../common/FormInput.jsx";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {sendRecoverLink, userOrMailExists} from "../../../../backend/userService.js";

const FORM_PANEL = 0
const SENT_PANEL = 1

const Loading = () => {
    return (
        <div className="inline text-white shadow bg-color2 rounded-2xl p-2">
            <FontAwesomeIcon icon={faSpinner} color="white" spin/> Procesando...
        </div>
    )
}

const Form = ({setActualPanel, setUsername, setMail}) => {
    const dispatch = useDispatch()
    const [userOrMailExistsF, setUserOrMailExistsF] = useState(false)
    const [loading, setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm()
    const onSubmit = (data) => {
        dispatch(userOrMailExists(data.username,
            (userOrMailExistsResponse) => {
                setLoading(true)
                dispatch(sendRecoverLink(data.username,
                    () => {
                        setUsername(userOrMailExistsResponse.user)
                        setMail(userOrMailExistsResponse.mail)
                        setLoading(false)
                        setActualPanel(SENT_PANEL)
                    },
                    () => {
                    }))
            },
            () => {
                setUserOrMailExistsF(true)
            }))


    }
    useEffect(() => {
        setUserOrMailExistsF(false)
    }, [watch("username")])

    return (
        <div className="flex flex-col gap-2">
            <a className="text-lg text-white font-bold ml-2">Enviar correo de recuperación</a>
            {loading ? <Loading/> :
                <form className="shadow bg-color2 rounded-2xl p-2" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <InputField autoFocus={true} label={"Introduce tu nombre de usuario o correo"}
                                borderCond={errors.username || userOrMailExistsF} registerCallBack={"username"}
                                errorsAndConds={[
                                    {
                                        cond: errors.username,
                                        text: "Introduce tu nombre de usuario/mail."
                                    },
                                    {
                                        cond: userOrMailExistsF,
                                        text: "No existe ningún usuario registrado con ese nombre/mail."
                                    },
                                ]}
                                register={register}></InputField>
                    <div className="flex flex-row justify-end">
                        <button type="submit" className="bg-color1 rounded px-2 pt-1 mb-4"><p className="block
                            text-white text-sm font-bold mb-2">Enviar</p></button>
                    </div>
                </form>}
        </div>
    )
}


const MailSent = ({username, mail}) => {
    return (
        <div className="flex flex-row gap-2 items-center flex-wrap justify-center">
            <div>
                <FontAwesomeIcon icon={faEnvelope} color="white" size="6x"/>
            </div>
            <div className="max-w-[340px]">
                <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                    Hemos enviado un link para resetear tu cuenta a la dirección de correo <b>{mail}</b>
                </label>
            </div>

        </div>
    )

}


const RecoverAccount = () => {
    const [actualPanel, setActualPanel] = useState(FORM_PANEL)
    const [userName, setUsername] = useState(false)
    const [mail, setMail] = useState(false)
    useEffect(() => {
    }, [actualPanel])
    return (
        <div className="w-full">
            {actualPanel === FORM_PANEL &&
                <Form setActualPanel={setActualPanel} setUsername={setUsername} setMail={setMail}></Form>}
            {actualPanel === SENT_PANEL && <MailSent username={userName} mail={mail}></MailSent>}
        </div>
    )
}

export default RecoverAccount