const NotePropButton =  ({isActive, onClick, icon, isClickable}) =>{
  const buttonClassBase = ' rounded flex justify-center items-center w-6 h-6 '
  const clickableClasses = ' hover:bg-orange-500 cursor-pointer '
  const buttonClassActiveNoClickable= buttonClassBase + ' bg-color-buttonSelected'
  const buttonClassInactiveNoClickable= buttonClassBase + ' bg-color-buttonNotSelected'
  const buttonClassActiveClickable= buttonClassActiveNoClickable + clickableClasses
  const buttonClassInactiveClickable= buttonClassInactiveNoClickable + clickableClasses
  const buttonClass=isActive?
    (isClickable?buttonClassActiveClickable:buttonClassActiveNoClickable) :
    (isClickable?buttonClassInactiveClickable:buttonClassInactiveNoClickable)

  return(
    <div
      className={buttonClass}
      onClick={isClickable?onClick:()=>{}}
    >
      {icon}
    </div>
  )
}

export default NotePropButton