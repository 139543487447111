import Image from "../../media/images/UI/notFound.png"
import Image404 from "../../media/images/UI/404.png"
const NotFoundError = ({notFound=false}) => {
    return (
        <div className={"w-[100vw] h-[100vh]"}>
            <div className={"flex justify-center items-center absolute m-auto left-0 right-0 top-0 bottom-0 max-w-[80vw] max-h-[50vh]"}>
                <img src={notFound?Image404:Image} alt={""} className={"max-w-full max-h-full "}/>
            </div>
        </div>

    )
}

export default NotFoundError