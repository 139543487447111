import {useEffect, useState} from "react";
import {checkRecoverToken, resetPassword} from "../../../../backend/userService.js";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleExclamation, faSpinner, faXmark} from "@fortawesome/free-solid-svg-icons";
import {TextInput, ValidatePanel} from "../common/auxComponents.jsx";
import {correctPassLength, passwordIsCorrect, usesAllRequiredChars, validatePass} from "../../../validators/passValidator.js";
import useGoToUrl from "../../../common/useGoToUrl.jsx";

const whiteExclamationIcon = <FontAwesomeIcon icon={faCircleExclamation} color="white"/>
const redExclamationIcon = <FontAwesomeIcon icon={faCircleExclamation} color="red"/>
const checkIcon = <FontAwesomeIcon icon={faCircleCheck} color="green"/>
const loadingIcon = <FontAwesomeIcon icon={faSpinner} color="green"/>
const queryParameters = new URLSearchParams(window.location.search)
const token = queryParameters.get("token")

const TokenExpired = ({onTryAgainClick}) => {
    return (<a className="text-white ml-2 text-lg w-[380px]">
        Parece que el enlace ha caducado. Los enlaces son válidos durante 1h. <a className="font-bold cursor-pointer hover:underline" onClick={onTryAgainClick} >Vuelve a intentarlo</a>.
    </a>)
}

const ResetPassForm = ({setPassChanged}) => {

    const [passwordField, setPasswordrField] = useState("")
    const [repeatPasswordField, setRepeatPasswordField] = useState("")
    const [passValidationResult, setPassValidationResult] = useState(["min", "uppercase", "lowercase", "digits", "symbols"])
    const rowClasses = "flex flex-col md:flex-row md:flex-wrap gap-4"
    const dispatch = useDispatch()
    const handlePasswordChange = (event) => {
        let pass = event.target.value.trim()
        setPasswordrField(pass)
        setPassValidationResult(validatePass(pass, {list: true}))
    }
    const handleRepeatPasswordChange = (event) => {
        let repeatPass = event.target.value.trim()
        setRepeatPasswordField(repeatPass)
    }
    const onSubmit = () => {
        event.preventDefault()
        dispatch(resetPassword(token, passwordField, () => {
            setPassChanged(true)
        }, () => {
        }))
    }
    return (<form onSubmit={onSubmit} noValidate>
        <div className="flex flex-col gap-8">
            <div>
                <a className="text-white text-3xl font-bold">Introduce una nueva contraseña</a>
            </div>
            <div className="flex flex-col gap-2">
                <div id="formRow4" className={rowClasses}>
                    <TextInput name={"Contraseña"}
                               value={passwordField}
                               handleChange={handlePasswordChange}
                               goodConds={passwordIsCorrect(passValidationResult)}
                               badConds={!passwordIsCorrect(passValidationResult) && passwordField.length > 0}
                               maxLength={52}
                               password={true}
                    />
                    <ValidatePanel conditions={[{
                        condition: true,
                        text: "Debe tener entre 6 y 52 caractéres.",
                        icon: (correctPassLength(passValidationResult) ? checkIcon : (passwordField.length > 0 ? redExclamationIcon : whiteExclamationIcon))
                    }, {
                        condition: true,
                        text: "Debe contener por lo menos una mayúscula, una minúscula y un número.",
                        icon: usesAllRequiredChars(passValidationResult) ? checkIcon : (passwordField.length > 0 ? redExclamationIcon : whiteExclamationIcon)
                    },]}/>
                </div>
                <div id="formRow5" className={rowClasses}>
                    <TextInput name={"Vuelve a introducir la contraseña"}
                               value={repeatPasswordField}
                               handleChange={handleRepeatPasswordChange}
                               goodConds={passwordIsCorrect(passValidationResult) && repeatPasswordField === passwordField}
                               badConds={repeatPasswordField.length > 0 && repeatPasswordField !== passwordField}
                               maxLength={52}
                               password={true}
                    />
                    <ValidatePanel conditions={[{
                        condition: repeatPasswordField.length < 1, text: "Obligatorio.", icon: whiteExclamationIcon
                    }, {
                        condition: repeatPasswordField.length > 0 && repeatPasswordField !== passwordField,
                        text: "Las contraseñas deben coincidir.",
                        icon: redExclamationIcon
                    }, {
                        condition: passwordIsCorrect(passValidationResult) && repeatPasswordField === passwordField,
                        text: "",
                        icon: checkIcon
                    },]}/>
                </div>
            </div>
            <div id="buttonDiv" className={rowClasses + " md:flex-row md:justify-end"}>
                <button type={"submit"}
                        disabled={!(passwordIsCorrect(passValidationResult) && repeatPasswordField === passwordField)}
                        className={(passwordIsCorrect(passValidationResult) && repeatPasswordField === passwordField) ? "bg-color1 text-white font-bold py-4 px-8 rounded text-l" : "bg-color1 text-white font-bold py-4 px-8 rounded text-l opacity-50 cursor-not-allowed"}>
                    Cambiar contraseña
                </button>
            </div>
        </div>
    </form>)
}

const PassChanged = ({onLoginClick}) => {
    return (<div className="flex flex-row flex-wrap">
        <p className="text-white ml-2 text-lg w-[380px] ">
            La contraseña se ha actualizado correctamente. Ya puedes <a className="font-bold cursor-pointer hover:underline" onClick={onLoginClick} >iniciar sesión</a>.
        </p>
    </div>)
}

const ResetPassword = () => {
    const dispatch = useDispatch()
    const [isValid, setIsValid] = useState(false)
    const [init, setInit] = useState(false)
    const [passChanged, setPassChanged] = useState(false)
    const navigate = useGoToUrl()
    useEffect(() => {
        if (!init) {
            dispatch(checkRecoverToken(token, (data) => {
                setIsValid(data.valid);
                setInit(true)
            }, () => {
                setInit(true)
            }))
        }
    }, [init])


    if (!init) return
    return (<div className="bg-color3 rounded-2xl flex flex-col gap-2">
        <div className={"flex flex-row justify-end"}>
            <div onClick={()=>{navigate('/login')}} className={"cursor-pointer"}>
                <FontAwesomeIcon icon={faXmark} color="white" size="2x"/>
            </div>
        </div>
        {!passChanged && !isValid && <TokenExpired onTryAgainClick={()=>{navigate('/recoverAccount')}}/>}
        {!passChanged && isValid && <ResetPassForm onLoginClick={()=>{navigate('/')}} setPassChanged={setPassChanged}/>}
        {passChanged && <PassChanged onLoginClick={()=>{navigate('/login')}}/>}

    </div>)
}
export default ResetPassword