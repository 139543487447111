import ErrorPage from "./ErrorPage.jsx";

const TooManyRequests = () => {
    return (
        <ErrorPage
            title={"Demasiado rápido"}
            happeningCaptions={
                ["Se están haciendo demasiades peticiones a un recurso desde tu dirección IP"]}
            solutionCaptions={["Espera unos minutos y vuelve a intentarlo",
                "Si el error persiste contacta con el administrador"]}
        />
    )
}

export default TooManyRequests