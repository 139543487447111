import {createSlice} from "@reduxjs/toolkit";



export const userSlice = createSlice({
    initialState: {
        userInfo: {}
    },
    name: 'userService',
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        clear: (state, action) => {
            state.userInfo = {}
        },
        setColor: (state, action) => {
            state.color = action.payload
        },
    }
})

export const {setUserInfo, clear, setColor}=userSlice.actions
export default userSlice.reducer