import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getStatsForProject, getStatsFromTaskList } from '../../../../../yjs/functions/YjsTasksFunctions.js'

const ProjectCompletenessBar = () =>{
const [tasksStats, setTasksStats] = useState({})
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const tasksY=yjsData.tasksY

  useEffect(()=>{
    tasksY.observeDeep(e=>{
      setTasksStats(getStatsForProject(tasksY))
    })
    setTasksStats(getStatsForProject(tasksY))
  }, [])

  return(
    <div style={{height: "4%", minHeight: "25px", fontSize: "100%"}}
      className={"pl-20 h-8 w-full fixed bottom-0 bg-color4 text-white flex justify-start pr-6 gap-5 items-center"}>
      <a className={"overflow-hidden whitespace-nowrap overflow-ellipsis text-right"}>
        Tareas completadas (solo activas): {tasksStats.completedTasks}/{tasksStats.tasksWithCompletenessActive} ({tasksStats.completedTaskPercentActive}%)</a>
      <a className={"overflow-hidden whitespace-nowrap overflow-ellipsis  text-right"}>Trabajo completado: {tasksStats.completedWeight}/{tasksStats.totalWeight} ({tasksStats.completedWeightPercent}%)</a>
    </div>
  )

}

export default ProjectCompletenessBar