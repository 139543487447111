import AppBar from "./components/AppBar.jsx";
import AppBackground from "./components/AppBackground.jsx";
import {faBars, faChessBoard, faHome, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from "react-redux";
import {setHideMenu} from "../../redux/slices/kanbanSlice.js";
import {checkIsMobileBrowser} from "../common/Common.jsx";

const AppInterface = (props) => {
    const {children, selectedButton, notOverflow, hideMenuInMobile, isGraph} = props
    const isMobileBrowser = checkIsMobileBrowser()
    return (

        <div className={`flex flex-col w-[100vw] h-fit relative min-h-[100dvh] bg-blue-300` }>
            <AppBackground></AppBackground>

            {(!hideMenuInMobile || !isMobileBrowser )&& <div id={"AppBar"} className="h-[50px] bg-color3 absolute block w-full z-[10000]">
                <AppBar selectedButton={selectedButton}
                        buttons={[
                            {
                                name: <div className={"flex h-full gap-1 items-center"}><FontAwesomeIcon icon={faHome}
                                                                                                         color="white"
                                                                                                         size="sm"/><a
                                    className={"hidden sm:inline"}>Inicio</a></div>, dir: "/"
                            },
                            {
                                name: <div className={"flex h-full gap-1 items-center"}><FontAwesomeIcon
                                    icon={faChessBoard} color="white" size="sm"/><a className={"hidden sm:inline"}>Mis
                                    Kanbans</a></div>, dir: "/kanbans"
                            }
                        ]}/>
            </div>}
            <div style={{height: "calc(100% - 50px)", overflow: notOverflow ? "hidden" : "auto"}}
                 className={`flex-grow w-full relative  ${(isMobileBrowser && isGraph) ?"pt-0":"pt-12"}`}>
                {children}
            </div>

        </div>

    )
}
export default AppInterface