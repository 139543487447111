import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'

const CircularProgress = ({percent, done, total, text, size=50})=>{
  return(
    <div style={{width: size+5}} className={"flex flex-col items-center justify-center text-xl"}>
      <div style={{width: size, height: size}}>
        <CircularProgressbarWithChildren
          value={percent}
          text={`${percent}%`}
          styles={buildStyles({
            textColor: "white",
            pathColor: "white",
            trailColor: "grey",
            textSize: "30px"
          })}
        >
          <div style={{ fontSize: 9, marginTop: 20 }}>
            <a className={""}>{done}/{total}</a>
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <a style={{fontSize: "11px", lineHeight: "11px"}}>{text}</a>
    </div>
  )
}

export default CircularProgress