import { appFetch, config } from './appFetch.js'
import { clear, setColor, setUserInfo } from '../redux/slices/usersSlice.js'
import Cookie from 'universal-cookie'

const SERVICE_TOKEN_NAME = 'TOKEN'
const REMEMBER_NAME = 'REMEMBER'

export const login = (username, pass, remember, onSuccess, onErrors) => (dispatch) => {
  appFetch('/users/login', config('POST', { 'username': username, 'pass': pass }), (data) => {
    setServiceToken(data.token, remember)
    onSuccess()
    delete data.token
    dispatch(setUserInfo(data))
    dispatch(setColor('#' + Math.floor(Math.random() * 16777215).toString(16)))
  }, () => {
    removeServiceToken()
    dispatch(setUserInfo({ badLogin: true }))
    onErrors()
  })
}

export const registerUser = (username, pass, name, mail, onSuccess) => (dispatch) => {
  const colors = [
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#03a9f4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#ffeb3b",
    "#ff9800",
    "#ff5722",
    "#795548"
  ]

  appFetch('/users/register', config('POST', {
    'username': username, 'pass': pass, 'name': name, 'mail': mail, color: colors[Math.floor(Math.random() * 10)]
  }), (data) => {
    setServiceToken(data.token, false)
    delete data.token
    dispatch(setUserInfo(data))
    onSuccess()
  }, () => {
    removeServiceToken()
    dispatch(setUserInfo({ badLogin: true }))
  })
}

export const loginFromToken = (token, onSuccess, onErrors) => (dispatch) => {
  appFetch('/users/auth', config('POST', {}), (data) => {
    delete data.token
    dispatch(setUserInfo(data))
    dispatch(setColor('#' + Math.floor(Math.random() * 16777215).toString(16)))
    onSuccess()
  }, onErrors)
}

export const userExists = (user, onExists, onNoExist) => () => {
  appFetch(`/users/exists/${user}`, config('GET'), (data) => {
    data.exist === true ?
      onExists():
      onNoExist()
  })
}

export const mailExists = (mail, onExists, onNoExist) => {
  appFetch(`/users/mailExists/${mail}`, config('GET'), (data) => {
    if (data.exist === true) {
      onExists()
    } else {
      onNoExist()
    }
  })
}

export const userOrMailExists = (userOrMail, onExists, onNoExist) => () => {
  appFetch(`/users/userOrMailExists/${userOrMail}`, config('GET'), (data) => {
    if (data.exist === true) {
      onExists(data)
    } else {
      onNoExist(data)
    }
  })
}

export const sendRecoverLink = (userNameOrMail, onSuccess, onErrors) => () => {
  appFetch('/users/recoverAccount', config('POST', { 'mail': userNameOrMail }), (data) => {
    onSuccess(data)
  }, () => {
    onErrors()
  })
}

export const checkRecoverToken = (token, onSuccess, onErrors) => () => {
  appFetch('/users/checkRecoverToken', config('POST', { 'token': token }), (data) => {
    onSuccess(data)
  }, () => {
    onErrors()
  })
}

export const resetPassword = (token, password, onSuccess, onErrors) => () => {
  appFetch('/users/resetAccount', config('POST', { 'changePassToken': token, 'newPass': password }), (data) => {
    onSuccess(data)
  }, () => {
    onErrors()
  })
}

export const setServiceToken = (serviceToken, remember) => {
  const cookies = new Cookie()
    remember ?
    localStorage.setItem(SERVICE_TOKEN_NAME, serviceToken) :
    cookies.set(SERVICE_TOKEN_NAME, serviceToken, { path: '/' })
}

export const getServiceToken = () => {
  const lsKey = localStorage.getItem(SERVICE_TOKEN_NAME)
  if(lsKey!=null)
    return localStorage.getItem(SERVICE_TOKEN_NAME)
  else{
    const cookies = new Cookie()
    return cookies.get(SERVICE_TOKEN_NAME)
  }
}

export const removeServiceToken = ()=> () => {
  const cookies = new Cookie()
  cookies.remove(SERVICE_TOKEN_NAME)
  sessionStorage.removeItem(SERVICE_TOKEN_NAME)
  localStorage.removeItem(SERVICE_TOKEN_NAME)
}

export const closeSession=  (dispatch) => {
  removeServiceToken()
  dispatch(clear())
}

export const updateUser = (username, mail, name, oldPass, newPass, onSuccess, onErrors ) => () => {
  appFetch('/users/updateInfo', config('POST',
      {
        'username': username,
        'mail': mail,
        'name': name,
        'oldPass': oldPass,
        'newPass': newPass
      }),
    (data) => {
      onSuccess(data)
    }, (data) => {
      onErrors(data)
    })
}

export const updateUserImg = (image, onSuccess) => (dispatch) => {
  appFetch('/users/updateImage', config('POST',
      { image: image }),
    () => {
      onSuccess()
    },
    () => {

    })
}

export const updateUserColor = (color, onSuccess) => (dispatch) => {
  appFetch('/users/updateColor', config('POST',
      { color: color }),
    (data) => {
      onSuccess(data)
    },
    () => {

    })
}

export const deleteUserImg = (image, onSuccess) => (dispatch) => {
  appFetch('/users/deleteImage', config('POST',
      { image: image }),
    () => {
      onSuccess()
    },
    () => {

    })
}

export const getUserPublicInfo = (userId, onSuccess) => () => {
  appFetch(`/user/${userId}`, config('GET'), (data) => {
    onSuccess(data)
  })
}

export const getUserPublicInfoMultiple = (userIdArray, onSuccess) => () => {
  if (userIdArray.length === 0) {
    onSuccess([])
    return
  }

  let ids = ''
  for (let i = 0; i < userIdArray.length; i++) {
    if (i > 0) ids += ','
    ids += userIdArray[i]
  }
  appFetch(`/userM/${ids}`, config('GET'), (data) => {
    onSuccess(data)
  })
}

export const searchCollaborators = (searchString, kanbanId, page, orderField, orderDir, pageSize, onSuccess) => () => {
  appFetch('/searchCollaborators', config('POST',
      {
        searchString: searchString,
        page: page,
        orderField: orderField,
        orderDir: orderDir,
        pageSize: pageSize,
        kanbanId: kanbanId
      }),
    (data) => {
      onSuccess(data)
    },
    () => {

    })
}
