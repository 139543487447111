import CenterCard from "../appInterface/components/CenterCard.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import BasicForm, {BasicFormInput} from "../common/BasicForm.jsx";
import { useEffect, useState } from 'react'
import {useDispatch} from "react-redux";
import { CreateKanban, GetCreateKanbanOptions } from '../../backend/kanbanService.js'
import {useNavigate} from "react-router-dom";
import useGoToUrl from '../common/useGoToUrl.jsx'
import CreateKanbanOptionSelector from './CreateKanbanOptionSelector.jsx'

const CreateKanbanPage = () =>{
    const redExclamationIcon = <FontAwesomeIcon icon={faCircleExclamation} color="red" size={"sm"}/>
    const [kanbanName, setKanbanname] = useState("")
    const [requiredNameError, setRequiredNameError] = useState(false)
    const dispatch = useDispatch()
    const navigate = useGoToUrl()
    const [selectInfo, setSelectInfo] = useState({
        selecting: false,
        selected:0
    })
    const [createKanbanOptions, setCreateKanbanOptions] =
      useState([])

    useEffect(()=>{
        GetCreateKanbanOptions((data)=>{
            setCreateKanbanOptions(data)
        })
    }, [])

    const handleChangeKanbanName = (newValue) =>{
        const newKanbanName = newValue.trimStart()
        setRequiredNameError(newKanbanName.length===0)
        setKanbanname(newKanbanName)
    }

    const onSubmit = () =>{
        setRequiredNameError(kanbanName.length===0)
        if(kanbanName.length>0)
            dispatch(CreateKanban(kanbanName, selectInfo.selected, (data)=>{navigate(`/kanbanB?id=${data.id}`)}))
    }

    return(
      <div className={"w-full h-full pt-2 pl-1 pr-1 sm:pl-0 sm:pr-0"}>
          <CenterCard extraClasses={"sm:w-[600px]"}>
              <div className={"w-full flex flex-row justify-center"}>
                  <div className={"flex flex-col gap-2 w-full"}>
                      <div className={"flex flex-row justify-between w-full"}>
                          <a className={"text-white font-bold text-2xl"}>Crear un Tablero:</a>
                          <div className={"pr-2 cursor-pointer"} onClick={()=> {
                              navigate("/kanbans")
                          }}>
                              <FontAwesomeIcon icon={faArrowLeft} color="white" size={"lg"}/>
                          </div>

                      </div>
                      <div className={"w-full flex w-full flex-row justify-center mt-2"}>
                          <div className={"flex flex-row justify-center w-full md:w-[350px] ellipsis-3"}>
                              <BasicForm onSubmit={onSubmit} buttonText={"Crear"}
                                         buttonDisabledCond={kanbanName.length<1||selectInfo.selecting}>
                                  <div className={"flex flex-col gap-2 w-full pl-1 pr-1"}>
                                      <BasicFormInput inputTag={"Nombre del tablero:"} value={kanbanName}
                                                      handleChange={handleChangeKanbanName} validationConds={[
                                          {condition: requiredNameError,
                                              text: "Este campo es obligatorio",
                                              icon: redExclamationIcon},
                                      ]}/>
                                      <div className={"w-full flex flex-col gap-2"}>
                                          <a className={"text-white font-bold w-full"}>Plantilla inicial:</a>
                                          <div className={"flex flex-col w-full p-1 gap-1 bg-gray-500 rounded"}>

                                              <div className={"flex flex-col gap-4"}>
                                                  <CreateKanbanOptionSelector options={createKanbanOptions} setSelectInfo={setSelectInfo} />
                                                  {createKanbanOptions[selectInfo.selected] &&
                                                    <p className={'text-white w-full p-1 ellipsis-3'}>{createKanbanOptions[selectInfo.selected].description}</p>}
                                              </div>

                                          </div>
                                      </div>

                                  </div>

                              </BasicForm>
                          </div>
                      </div>


                  </div>
              </div>


          </CenterCard>
      </div>

    )
}

export default CreateKanbanPage