import { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  changeCompletenessActiveAndUpdateChilds,
  updateAverageOfParents
} from '../../../../../yjs/functions/YjsTasksFunctions.js'

export const CompletenessSelector = ({ task, tasksY }) => {
  const plusIcon = <FontAwesomeIcon icon={faPlus} color="white"/>
  const minusIcon = <FontAwesomeIcon icon={faMinus} color="white"/>
  const taskProps = task.get('props')
  const [completenessActive, setCompletenessActive] = useState(false)
  const [completenessPercent, setCompletenessPercent] = useState(0)

  useEffect(() => {
    let ob
    taskProps.observe(ob = x => {
      setCompletenessActive(taskProps.get('completenessActive'))
      setCompletenessPercent(taskProps.get('completenessPercent'))
    })
    setCompletenessActive(taskProps.get('completenessActive'))
    setCompletenessPercent(taskProps.get('completenessPercent'))
    return () => {
      taskProps.unobserve(ob)
    }
  }, [])

  return (
    <div className={'flex flex-col gap-1'}>
      <div className={'flex flex-row gap-2 items-center'}>
        <a className={'text-lg'}>¿Activado?: </a>
        <Switch height={20} width={40}
                onChange={() => {
                  changeCompletenessActiveAndUpdateChilds(task, tasksY)
                }}
                checked={taskProps.get('completenessActive')}/>
      </div>
      {completenessActive &&
        <div className={'flex flex-row gap-2'}>
          <a className={'text-lg'}>Porcentaje:</a>
          <div className={'flex gap-1 items-center'}>
            <div
              onClick={() => {
                if (completenessPercent > 9) {
                  taskProps.set('completenessPercent', Number(completenessPercent) - 10)
                  updateAverageOfParents(tasksY, task)
                }
              }}>{minusIcon}</div>
            <a className={'text-white'}>{taskProps.get('completenessPercent')}</a>
            <div
              onClick={() => {
                if (completenessPercent < 91) {
                  taskProps.set('completenessPercent', Number(completenessPercent) + 10)
                  updateAverageOfParents(tasksY, task)
                }
              }}
            >{plusIcon}</div>
          </div>
        </div>

      }

    </div>
  )
}