import { useNavigate } from 'react-router-dom'
import { getServiceToken, loginFromToken, removeServiceToken } from '../../backend/userService.js'
import { clear } from '../../redux/slices/usersSlice.js'
import { useDispatch } from 'react-redux'

const useGoToUrl = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return navigate
}

export default useGoToUrl
