import {ValidatePanel} from "../users/AuthPanel/common/auxComponents.jsx";

export const BasicFormInput = ({inputTag, validationConds, value, handleChange, errorCond= false}) =>{
    return (
        <div className={"flex flex-col gap-2 w-full"}>
            <a className={"text-white font-bold"}>{inputTag}</a>
            <div className={"pl-1 gap-1 flex flex-col"}>
                <input autoFocus={true} value={value} onChange={(event)=>{handleChange(event.target.value)}}
                    className={errorCond?"border-2 mt-1 text-lg border-red-500 bg-color3 rounded text-white pl-1 w-full":"outline-none focus:outline-orange-500 text-lg bg-color3 rounded text-white pl-1 w-full"}/>
                <div className={"pl-1 w-full"}>
                    <ValidatePanel conditions={validationConds}/>
                </div>
            </div>
        </div>
    )
}

const BasicForm = (props) =>{
    const {children, onSubmit, buttonText, buttonDisabledCond=false} = props
    return(
        <form className={"flex flex-col justify-start w-full items-center"} onSubmit={()=>{event.preventDefault();onSubmit()}}>
            {children}
            <div className={"flex flex-row justify-end pt-4 w-full"}>

                <button type={"submit"} disabled={buttonDisabledCond}
                        className={!buttonDisabledCond ?
                            "bg-color1 text-white pl-3 pr-3 pt-1 pb-1 rounded-2xl text-l" :
                            "bg-color1 text-white pl-3 pr-3 pt-1 pb-1 rounded-2xl text-l opacity-50 cursor-not-allowed"}>
                    {buttonText}
                </button>
            </div>
        </form>

    )
}

export default BasicForm