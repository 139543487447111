import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import YjsTextArea from '../../awareness/yjsText/YjsTextArea.jsx'
import { createNewField, deleteFieldById } from '../../../../../yjs/functions/YjsTasksFunctions.js'
import YjsInput from '../../awareness/yjsText/YjsInput.jsx'

const Field = ({ fieldY, taskY }) => {
  const deleteIcon = <FontAwesomeIcon icon={faTrash} color="white"/>
  const editIcon = <FontAwesomeIcon icon={faPenToSquare} color="white"/>
  const applyIcon = <FontAwesomeIcon icon={faCheck}
                                     color="white"/>
  const [editName, setEditName] = useState(false)
  return (
    <div id={'description'} className={'flex flex-col gap-1 relative'}>
      <div className={'flex items-center gap-2'}>
        {editName &&
          <div className={'relative h-7 w-full pl-1'}>
              <form className={"absolute h-full w-full pl-2 pr-2 flex gap-2"} onSubmit={() => {setEditName(!editName)}}>
                <YjsInput
                  id={'fieldName-' + fieldY.get('id')}
                  field={fieldY.get('name')}
                  className={'text-white font-bold text-lg pl-2 bg-color3 rounded'}
                />
                <button type={'submit'}>{applyIcon}</button>
              </form>

          </div>}
        {!editName &&
          <div className={"flex gap-1"}>
            <a className={'text-white font-bold text-lg pl-2'}>{fieldY.get('name').toString()}:</a>
            <button onClick={() => {setEditName(!editName)}}>{editIcon}</button>
            <button onClick={() => {deleteFieldById(taskY, fieldY.get('id'))}}>{deleteIcon}</button>
          </div>}
      </div>

      <div className={'relative h-24 w-full'}>
        <div className={'absolute h-full w-full pl-2 pr-2'}>
          <YjsTextArea
            id={'fieldValue-' + fieldY.get('id')}
            field={fieldY.get('value')}
            className={'resize-none w-full bg-color3 text-white rounded-2xl p-2 h-full z-100'}
          />
        </div>
      </div>
    </div>
  )
}

const Fields = ({ taskY }) => {
  const addIcon = <FontAwesomeIcon icon={faPlus} color="white" size={'xs'}/>
  const [fields, setFields] = useState([])
  useEffect(() => {
    let ob
    taskY.get('fields').observeDeep(ob = e => {
      let newT = []
      taskY.get('fields').map(x => {
        newT.push(<Field key={x.get('id')} fieldY={x} taskY={taskY}/>)
      })
      setFields(newT)
    })
    let newT = []
    taskY.get('fields').map(x => {
      newT.push(<Field key={x.get('id')} fieldY={x} taskY={taskY}/>)
    })
    setFields(newT)
    return () => taskY.get('fields').unobserveDeep(ob)
  }, [])

  return (
    <div className={'flex flex-col text-white gap-2'}>
      {fields.map(x => x)}
      <div className={'pl-3'}>
        <button
          className={'font-bold'} onClick={() => createNewField(taskY, 'Nuevo campo', '')}>
          {addIcon} Añadir
        </button>
      </div>
    </div>
  )
}

export default Fields