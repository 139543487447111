import {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheck, faCircleExclamation, faTrash, faUpload, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../../redux/slices/usersSlice.js"
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {deleteUserImg} from "../../../backend/userService.js";

const ChangePic = ({canvasRef, setImageIsLoaded, setChangingImage}) => {
    const uploadIcon = <FontAwesomeIcon icon={faUpload} color="white"/>
    const backIcon = <FontAwesomeIcon icon={faArrowLeft} color="white" size="lg"/>
    const addIcon = <FontAwesomeIcon icon={faPlus} color="white" size="lg"/>
    const trashIcon = <FontAwesomeIcon icon={faTrash} color="white" size="lg"/>
    const blobUrlRef = useRef('')
    const [selectedFile, setSelectedFile] = useState(null);
    const imgRef = useRef(null)
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const [crop, setCrop] = useState({
        unit: 'px',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    })


    const handleChange = (e) => {
        const img = e.target.files[0]
        setSelectedFile(URL.createObjectURL(img));
    }

    const onImageLoad = (e) => {
        const {naturalWidth: width, naturalHeight: height} = e.currentTarget
        const imageShowWidth = 340
        const imageShowHeight = 340*height/width
        const selSize = Math.min(imageShowWidth, imageShowHeight)-20
        setCrop({
            ...crop,
            height: selSize,
            width: selSize,
            y: (imageShowHeight-selSize)/2,
            x: (imageShowWidth-selSize)/2
        })
        drawImgInitial(canvasRef.current, imgRef.current)
    }

    const drawImg = (canvas, image) => {
        setImageIsLoaded(true)
        const selectImgWidth = 340
        const selectImgHeight = (selectImgWidth / image.naturalWidth) * image.naturalHeight
        //preview size
        canvas.width = 340
        canvas.height = 340
        const pixelRatio = window.devicePixelRatio
        const ctx = canvas.getContext('2d')
        ctx.imageSmoothingQuality = 'high'
        ctx.save()
        ctx.drawImage(
            image,
            crop.x * (image.naturalWidth / selectImgWidth),
            crop.y * (image.naturalHeight / selectImgHeight),
            crop.width * (image.naturalWidth / selectImgWidth),
            crop.height * (image.naturalHeight / selectImgHeight),
            0,
            0,
            canvas.width,
            canvas.height,
        )

        ctx.restore()
    }

    const drawImgInitial = (canvas, image) => {
        setImageIsLoaded(true)
        canvas.width = 340
        canvas.height = 340
        const ctx = canvas.getContext('2d')
        ctx.imageSmoothingQuality = 'high'
        ctx.save()
        const selSize = Math.min(0.9 * image.naturalWidth, 0.9 * image.naturalHeight)
        ctx.drawImage(
            image,
            (image.naturalWidth-selSize)/2,
            (image.naturalHeight-selSize)/2,
            selSize,
            selSize,
            0,
            0,
            canvas.width,
            canvas.height,
        )

        ctx.restore()
    }

    const onCropChange = () => {
        drawImg(canvasRef.current, imgRef.current)
    }

    return (
        <div className="w-[340px] flex flex-row justify-center min-h-[285px]">
            {selectedFile==null && <div>

                <div className="flex flex-col gap-2 w-[280px]">
                    <div className={"flex flex-row justify-end items-center "}>
                        <div className={"cursor-pointer"} onClick={()=>{setImageIsLoaded(false); setChangingImage(false)}}>{backIcon} </div>
                    </div>

                    <div className={"flex flex-col justify-center text-white font-bold gap-1 items-center w-[250px]"}>
                        <input id="imgUpload" type="file" accept="image/*" onChange={handleChange} className={"hidden"}/>
                        <label htmlFor="imgUpload" className={"flex flex-row items-center gap-1 bg-color3 rounded-xl p-2 pr-4 w-full cursor-pointer hover:bg-stone-500"}>
                            {addIcon}
                            <a>Subir nueva imagen</a>
                        </label>
                        {!user.userInfo.defaultimg && <div
                            className={"flex flex-row items-center gap-1 bg-color3 rounded-xl p-2 pr-4 w-full cursor-pointer hover:bg-stone-500"}
                            onClick={()=>{dispatch(deleteUserImg("asd", ()=>{dispatch(setUserInfo({
                                ...user.userInfo,
                                defaultimg: true,
                                imgdata: ""
                            })); setChangingImage(false)}))}}>
                            {trashIcon}
                            <a>Eliminar imagen actual</a>
                        </div>}
                    </div>


                </div>

            </div>}
            <div>
                <ReactCrop crop={crop} onChange={c => {
                    setCrop(c);
                }} aspect={1} keepSelection={true} onComplete={onCropChange}>
                    <img onLoad={onImageLoad} alt={""} src={selectedFile} className="w-[340px]" ref={imgRef}/>
                </ReactCrop>
            </div>

        </div>
    )
}

export default ChangePic