import {appFetch, config} from "./appFetch.js";
import { set, setYDoc } from '../redux/slices/kanbanSlice.js'
import { getYProvider } from '../yjs/yjs.js'

export const CreateKanban = (name, templateCode, onSuccess) => (dispatch) => {
    appFetch("/kanbans/createKanban", config("POST",
            {name: name, templateCode: templateCode}),
        (data) => {
          onSuccess&&onSuccess(data)
        },
        () => {

        })
}

export const DeleteKanban = (id, onSuccess) => (dispatch) => {
    appFetch("/kanbans/delete", config("POST",
            {id: id}),
        () => {
            onSuccess()
        },
        () => {

        })
}

export const GetKanban = (id, onSuccess, onErrors) => (dispatch) => {
    appFetch(`/kanban/${id}`, config("GET"), (data) => {
        const provider = getYProvider(data.id)
        dispatch(set({ kanbanData: data}))
        dispatch(setYDoc({provider: provider}))
        if (onSuccess != null)
            onSuccess(data)
    }, onErrors)

}

export const GetUserKanbans = ({orderField, orderDir, page, pageSize, onSuccess}) => (dispatch) => {
  appFetch("/kanbans", config("POST",
      {orderField: orderField, orderDir: orderDir, page: page, pageSize: pageSize, target: 0}),
    (x) => {
      onSuccess&&onSuccess(x)
    },
    () => {

    })
}

export const GetCollaboratorKanbans = ({orderField, orderDir, page, pageSize, onSuccess}) => (dispatch) => {
  appFetch("/kanbans", config("POST",
      {orderField: orderField, orderDir: orderDir, page: page, pageSize: pageSize, target: 1}),
    (x) => {
      onSuccess&&onSuccess(x)
    },
    () => {

    })
}

export const GetUserAndCollaboratorKanbans = ({orderField, orderDir, page, pageSize, onSuccess}) => (dispatch) => {
  appFetch("/kanbans", config("POST",
      {orderField: orderField, orderDir: orderDir, page: page, pageSize: pageSize, target: 2}),
    (x) => {
      onSuccess&&onSuccess(x)
    },
    () => {

    })
}

export const GetCreateKanbanOptions = (onSuccess, onErrors)  => {
  appFetch(`/kanban/createOptions`, config("GET"), (data) => {
    onSuccess&&onSuccess(data)
  }, onErrors)

}