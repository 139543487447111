import * as Y from 'yjs'
import { deleteNote } from './YjsTasksFunctions.js'
import { Panel } from '../types/Panel.ts'
import { Note, Task, TaskProps } from '../types/Task.ts'
import { Kanban } from '../types/Kanban.ts'

export const splitKanbanPanel = (horizontal, kanbanY, kanbanYParent, tasksY, containerRef) => {
  const pxToPercentContainer = (value) => {
    return (value / (kanbanY.get('splitHorizontal') ? containerRef.current.offsetWidth : containerRef.current.offsetHeight)) * 100
  }
  const percentToPxContainer = (value) => {
    return (value * (kanbanY.get('splitHorizontal') ? containerRef.current.offsetWidth : containerRef.current.offsetHeight)) / 100
  }
  const child1 = new Y.Map()
  let actCoords = (JSON.parse(JSON.stringify(kanbanY.get('coords'))))
  actCoords.push(1)
  const coordsChild1 = new Y.Array()
  coordsChild1.insert(0, actCoords)
  child1.set('coords', coordsChild1)
  child1.set('split', false)
  child1.set('content', kanbanY.get('content'))
  kanbanY.delete('content')
  child1.set('color', kanbanY.get('color'))
  kanbanY.delete('color')
  const child2 = new Y.Map()
  actCoords = (JSON.parse(JSON.stringify(kanbanY.get('coords'))))
  actCoords.push(2)
  const coordsChild2 = new Y.Array()
  coordsChild2.insert(0, actCoords)
  child2.set('coords', coordsChild2)
  child2.set('split', false)
  child2.set('color', '#36393e')
  child2.set('content', 'Nuevo Panel')
  kanbanY.set('child1', child1)
  kanbanY.set('child2', child2)
  kanbanY.set('splitPos', 50)
  kanbanY.set('splitHorizontal', horizontal)
  kanbanY.set('split', true)

  const panelCoords = panelDataToPanelCoords(kanbanYParent.toJSON())
  tasksY.map(taskY => {
      const note = taskY.get('note')
      const actCoords = note.get('actCoords')
      if (JSON.stringify(kanbanY.get('coords')) === JSON.stringify(actCoords.toArray())) {
        actCoords.push([1])
        let fullCoords
        panelCoords.map(x => {
          if (JSON.stringify(x.coords) === JSON.stringify(actCoords.toArray())) {
            fullCoords = x
          }
        })
        const posRelToCellX = taskY.get('note').get('posRelToCellX')
        const posRelToCellY = taskY.get('note').get('posRelToCellY')
        taskY.get('note').set('posX', pxToPercentContainer(percentToPxContainer(fullCoords.xIni) + posRelToCellX * percentToPxContainer(fullCoords.xEnd - fullCoords.xIni) / 100))
        taskY.get('note').set('posY', pxToPercentContainer(percentToPxContainer(fullCoords.yIni) + posRelToCellY * percentToPxContainer(fullCoords.yEnd - fullCoords.yIni) / 100))

      }
    }
  )
}

export const deleteKanbanPanel = (kanbanY, kanbanYParent, immediateParent, tasksY) => {
  const setCoordsAtDelete = (kanbanY, delPos) => {
    let actCoords = kanbanY.get('coords')
    actCoords.delete(delPos, 1)
    if (kanbanY.get('split')) {
      setCoordsAtDelete(kanbanY.get('child1'), delPos)
      setCoordsAtDelete(kanbanY.get('child2'), delPos)
    }
  }
  const thisChild = kanbanY.get('coords').get(kanbanY.get('coords').length - 1)
  let childT
  if (thisChild === 1) {
    childT = 'child2'
  } else {
    childT = 'child1'
  }

  const deletedCoords = kanbanY.get('coords').toJSON()
  let parentCoords = JSON.parse(JSON.stringify(deletedCoords))
  parentCoords.pop()
  let brotherCoords = JSON.parse(JSON.stringify(parentCoords))
  brotherCoords.push(thisChild === 1 ? 2 : 1)
  const panelCoordsBeforeDelete = panelDataToPanelCoords(kanbanYParent.toJSON())
  let fullDeletedCoords
  panelCoordsBeforeDelete.map((x, i) => {
    if (JSON.stringify(x.coords) === JSON.stringify(deletedCoords)) {
      fullDeletedCoords = x
    }
  })
  const otherChild = immediateParent.get(childT)
  const otherChildValues = {
    content: otherChild.get('content'),
    split: otherChild.get('split'),
    splitPos: otherChild.get('splitPos'),
    splitHorizontal: otherChild.get('splitHorizontal'),
    child1: otherChild.get('child1'),
    child2: otherChild.get('child2'),
    coords: otherChild.get('coords'),
    color: otherChild.get('color'),
  }

  const childIsSplit = immediateParent.get(childT).get('split')
  immediateParent.set('split', childIsSplit)
  if (otherChildValues.split === true) {
    immediateParent.delete('content')
    const child1 = otherChildValues.child1.clone()
    const child2 = otherChildValues.child2.clone()
    immediateParent.set('child1', child1)
    immediateParent.set('child2', child2)
    immediateParent.set('splitPos', otherChildValues.splitPos)
    immediateParent.set('splitHorizontal', otherChildValues.splitHorizontal)
    setCoordsAtDelete(immediateParent.get('child1'), immediateParent.get('coords').length)
    setCoordsAtDelete(immediateParent.get('child2'), immediateParent.get('coords').length)
  } else {
    immediateParent.set('color', otherChildValues.color)
    immediateParent.set('content', otherChildValues.content)
    immediateParent.delete('child1')
    immediateParent.delete('child2')
    immediateParent.delete('splitPos')
    immediateParent.delete('splitHorizontal')
  }

  let offset = 0
  tasksY.map((x, i) => {
    if (JSON.stringify(x.get('note').get('actCoords').toJSON()) === JSON.stringify(deletedCoords)) {
      deleteNote(tasksY, x, i - offset)
      offset = offset + 1
    }
  })
  tasksY.map((x, i) => {
    const thisCoords = x.get('note').get('actCoords').toJSON()
    if (JSON.stringify(thisCoords.slice(0, brotherCoords.length)) === JSON.stringify(brotherCoords)) {
      const newCds = JSON.parse(JSON.stringify(thisCoords))
      newCds.splice(brotherCoords.length - 1, 1)
      let fullNewCoords
      const panelCoordsAfterDelete = panelDataToPanelCoords(kanbanYParent.toJSON())
      panelCoordsAfterDelete.map((x, i) => {
        if (JSON.stringify(x.coords) === JSON.stringify(newCds)) {
          fullNewCoords = x
        }
      })
      tasksY.get(i).get('note').get('actCoords').delete(brotherCoords.length - 1, 1)
      const newX = fullNewCoords.xIni + (fullNewCoords.xEnd - fullNewCoords.xIni) * (tasksY.get(i).get('note').get('posRelToCellX') / 100)
      const newY = fullNewCoords.yIni + (fullNewCoords.yEnd - fullNewCoords.yIni) * (tasksY.get(i).get('note').get('posRelToCellY') / 100)
      tasksY.get(i).get('note').set('posX', newX)
      tasksY.get(i).get('note').set('posY', newY)
    }
  })
}

export const panelDataToPanelCoordsRec = ({ panelData, xIni, xEnd, yIni, yEnd }) => {
  if (panelData.split === false) {
    return [{ xIni: xIni, xEnd: xEnd, yIni: yIni, yEnd: yEnd, coords: panelData.coords, name: panelData.content }]
  } else {
    const { splitHorizontal, child1, child2, splitPos } = panelData
    const child1Areas = panelDataToPanelCoordsRec({
      panelData: child1,
      xIni: splitHorizontal ? xIni : xIni,
      xEnd: splitHorizontal ? (xEnd - xIni) * (splitPos / 100) + xIni : xEnd,
      yIni: splitHorizontal ? yIni : yIni,
      yEnd: splitHorizontal ? yEnd : ((yEnd - yIni) * (splitPos / 100) + yIni)
    })

    const child2Areas = panelDataToPanelCoordsRec({
      panelData: child2,
      xIni: splitHorizontal ? (xEnd - xIni) * (splitPos / 100) + xIni : xIni,
      xEnd: splitHorizontal ? xEnd : xEnd,
      yIni: splitHorizontal ? yIni : ((yEnd - yIni) * (splitPos / 100) + yIni),
      yEnd: splitHorizontal ? yEnd : yEnd
    })

    return child1Areas.concat(child2Areas)
  }
}

export const panelDataToPanelCoords = (panelData) => {
  return panelDataToPanelCoordsRec({ panelData: panelData, xIni: 0, xEnd: 100, yIni: 0, yEnd: 100 })
}

export const getFullCords = (panelsDataY, coords) => {
  const panelCoords = panelDataToPanelCoords(panelsDataY.toJSON())
  let thisCoord
  panelCoords.map((x, i) => {
    if (JSON.stringify(x.coords) === JSON.stringify(coords)) {
      thisCoord = x
    }
  })
  return thisCoord
}

const kanbanPanelToTs = (kanbanY) => {
  let panel = new Panel(kanbanY.get('coords').toJSON(), kanbanY.get('split'), kanbanY.get('splitHorizontal'),
    kanbanY.get('splitPos'), kanbanY.get('split') ? kanbanPanelToTs(kanbanY.get('child1')) : null, kanbanY.get('split') ? kanbanPanelToTs(kanbanY.get('child2')) : null, kanbanY.get('content'), kanbanY.get('color'))
  return panel
}

const kanbanTasksToTs = (tasksY) => {
  let tasks = []
  tasksY.forEach(task => {
    tasks.push(
      new Task(task.get('id'), task.get('name').toString(), task.get('description').toString(),
        new Note(
          task.get('note').get('posRelToCellX'),
          task.get('note').get('posRelToCellY'),
          task.get('note').get('posX'),
          task.get('note').get('posY'),
          task.get('note').get('zIndex').get('value'),
          task.get('note').get('actCoords').toJSON(),
        ),
        new TaskProps(
          task.get('props').get('completenessActive'),
          task.get('props').get('completenessPercent'),
          task.get('props').get('completenessWeight'),
          task.get('props').get('isChildTask'),
          task.get('props').get('parentTask'),
          task.get('note').get('actCoords').toJSON(),
          task.get('props').get('isAssigned'),
          task.get('props').get('assignedTo'),
          task.get('fields') ? task.get('fields').toJSON() : [],
        )
      ))
  })
  return tasks
}

export const dumpKanbanToGoTemplate = (kanbanY, tasksY, kanbanPropsY) => {
  if (!kanbanY.get('coords'))
    return
  let kanban = new Kanban(kanbanPropsY.get('id'), kanbanPropsY.get('name'), kanbanPropsY.get('width'),
    kanbanPropsY.get('height'), kanbanPanelToTs(kanbanY), kanbanTasksToTs(tasksY), '', kanbanPropsY.creationDate)
  return kanban
}

const tsKanbanFieldToGo = (field) => {
  let spaceChild = '  '
  let out =
    `${spaceChild.repeat(4)}{
${spaceChild.repeat(5)}Id: "${field.id}",
${spaceChild.repeat(5)}Name:  "${field.name}",
${spaceChild.repeat(5)}Value: "${field.value}",
${spaceChild.repeat(4)}},\n`
  return out
}

const tsTaskToGo = (tsTask) => {
  let spaceChild = '  '
  let out2 =
    `
${spaceChild.repeat(2)}{
${spaceChild.repeat(3)}Id: "${tsTask.id}",
${spaceChild.repeat(3)}Name:        "${tsTask.name}",
${spaceChild.repeat(3)}Description: "${tsTask.description}",
${spaceChild.repeat(3)}Fields: []kanbans.Field{\n`

  tsTask.props.fields.forEach((field, index) => {
    out2 += tsKanbanFieldToGo(field)
    if (index < tsTask.props.fields.length - 1)
      out2 += ','
  })
  out2+=`${spaceChild.repeat(3)}},\n`
  out2 +=
`${spaceChild.repeat(3)}Props: kanbans.TaskPropsDao{
${spaceChild.repeat(4)}CompletenessActive:  ${tsTask.props.completenessActive},
${spaceChild.repeat(4)}CompletenessPercent: ${tsTask.props.completenessPercent},
${spaceChild.repeat(4)}CompletenessWeight:  ${tsTask.props.completenessWeight},
${spaceChild.repeat(4)}IsChildTask:         ${tsTask.props.isChildTask},
${spaceChild.repeat(4)}ParentTask:          "${tsTask.props.parentTask}",
${spaceChild.repeat(4)}IsAssigned:          ${tsTask.props.isAssigned},
${spaceChild.repeat(4)}AssignedTo:          "${tsTask.props.assignedTo}",
${spaceChild.repeat(3)}},
${spaceChild.repeat(3)}Note: kanbans.NoteDao{
${spaceChild.repeat(4)}PosX:          ${tsTask.note.posX},
${spaceChild.repeat(4)}PosY:          ${tsTask.note.posY},
${spaceChild.repeat(4)}PosRelToCellX: ${tsTask.note.posRelToCellX},
${spaceChild.repeat(4)}PosRelToCellY: ${tsTask.note.posRelToCellY},
${spaceChild.repeat(4)}ZIndex:        ${tsTask.note.zIndex},
${spaceChild.repeat(4)}ActCoords:     []int{${(tsTask.note.actCoords)}},
${spaceChild.repeat(3)}},
${spaceChild.repeat(2)}},
`

  return out2
}

const tsPanelDataToGo = (tsPanelData, deep) => {
  let spaceChild = '  '
  let out =
    `kanbans.PanelsDataDao{
${spaceChild.repeat(deep)}Coords:          []int{${tsPanelData.coords}},
${spaceChild.repeat(deep)}Split:           ${tsPanelData.split},\n`

  if (tsPanelData.split) {
    out +=
      `${spaceChild.repeat(deep)}SplitHorizontal: &[]bool{${tsPanelData.splitHorizontal}}[0],
${spaceChild.repeat(deep)}SplitPos:        &[]float32{${tsPanelData.splitPos}}[0],
${spaceChild.repeat(deep)}Child1:        &${tsPanelDataToGo(tsPanelData.child1, deep + 1)},
${spaceChild.repeat(deep)}Child2:        &${tsPanelDataToGo(tsPanelData.child2, deep + 1)},\n`

  } else {
    out +=
      `${spaceChild.repeat(deep)}Content:        &[]string{"${tsPanelData.content}"}[0],
${spaceChild.repeat(deep)}Color:        &[]string{"${tsPanelData.color}"}[0],\n`
  }
  out += `${spaceChild.repeat(deep - 1)}}`
  return out
}

const tsKanbanToGo = (tsKanban) => {
  let spaceChild = '  '
  let out =
    `return kanbans.Kanban{
${spaceChild.repeat(1)}Name:          name,
${spaceChild.repeat(1)}Owner:         user.Id,
${spaceChild.repeat(1)}CreationDate:  time.Now(),
${spaceChild.repeat(1)}Width:         ${tsKanban.width},
${spaceChild.repeat(1)}Height:        ${tsKanban.height},
${spaceChild.repeat(1)}Collaborators: make([]kanbans.CollaboratorDao, 0),
${spaceChild.repeat(1)}Tasks: []kanbans.TaskDao{`
  tsKanban.tasks.forEach(t => {
    out += tsTaskToGo(t)
  })
  out += `${spaceChild.repeat(1)}},
${spaceChild.repeat(1)}PanelsData: `
  out += tsPanelDataToGo(tsKanban.panelData, 2)
  out += `,\n}\n`

  return out
}

export const dumpKanbanToGoTemplateT = (kanbanY, tasksY, kanbanPropsY) => {
  if (!kanbanY.get('coords'))
    return
  const kanban = dumpKanbanToGoTemplate(kanbanY, tasksY, kanbanPropsY)
  console.log(tsKanbanToGo(kanban))
}