import {useSelector} from "react-redux";
import {KanbanPropsEditor} from "./KanbanEditorLeftMenu.jsx";
import KanbanSizeController from "../kanbanPanels/KanbanSizeController.jsx";

const OptionEditorMobile = () =>{
    const yjsData = useSelector((state) => state.kanban.yjsData)
    if(!yjsData.kanbanPropsY.get)
        return
    return(
        <div className={" bg-red-500 absolute top-48 z-[10002]"}>

        </div>
    )
}

export default OptionEditorMobile