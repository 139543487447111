import CenterCard from '../appInterface/components/CenterCard.jsx'
import React from 'react'
import ScrumImg from "../../../media/images/UI/Scrum board-pana.png";

const Home = () =>{
  return(
    <div className={"w-full h-full p-2"}>
      <CenterCard extraClasses={"md:w-[600px]"}>
        <div className={"flex flex-col gap-2 text-white"}>
          <a className={"text-2xl text-white font-bold"}>Inicio</a>
          <img src={ScrumImg}/>
          <div className={"w-full p-1 flex flex-col gap-8 text-xl"}>
            <div className={"flex flex-col gap-2"}>
              <p className={""}>
                Accede a la pestaña de <a className={"font-bold"}>Mis kanbans</a> para acceder a los tableros creados
                por
                ti o tus colaboradores o crear uno nuevo.
              </p>
            </div>
            <div className={"flex flex-col gap-2"}>
              <p>
                Esta aplicación ha sido creada por Jaime José Corral García.
              </p>
              <p>
                Puedes consultar la <a className={"underline font-bold"} href={"https://kanban.jaimejcorral.com/about"}>página
                de información sobre este
                proyecto</a>
              </p>
              <p>
                o acceder a <a className={"underline font-bold"} href={"https://jaimejcorral.com/"}>mi portfolio
                personal</a>.
              </p>
            </div>

          </div>
        </div>
      </CenterCard>
    </div>
  )
}

export default Home