import { useEffect, useState } from 'react'

const CompletenessVisualizator = ({yTask}) =>{
  const [completenessActive, setCompletenessActive] = useState(false)
  const [completenessWeight, setCompletenessWeight] = useState(0)

  useEffect(()=>{
    let props = yTask.get('props')
    let ob
    props.observe(ob=e=>{
      setCompletenessActive(props.get('completenessActive'))
      setCompletenessWeight(props.get('completenessWeight'))
    })
    setCompletenessActive(props.get('completenessActive'))
    setCompletenessWeight(props.get('completenessWeight'))

    return()=>{
      props.unobserve(ob)
    }
  }, [])
return(
  <div>
    {completenessActive?
      completenessWeight.toString().slice(0,4):"N/A"}
  </div>
)

}

export default CompletenessVisualizator