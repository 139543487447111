
const AppBackground = (props) => {
    const {visible, cancel, children} = props;
    return (
        <div className="w-[100vw] h-full bg-gradient flex-grow z-0 fixed">
                <div className="w-full h-full z-0">
                    {children}
                </div>
        </div>
    )
}

export default AppBackground