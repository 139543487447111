import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFile, faPenToSquare, faSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { createNewTask } from '../../../../yjs/functions/YjsTasksFunctions.js'
import { getFullCords } from '../../../../yjs/functions/YjsPanelFuncions.js'
import ColorPickerPanel from './ColorPickerPanel.jsx'

const PanelButton = ({ onClick, icon }) => {
  return (
    <div onClick={onClick}
         className={'w-[30px] h-[30px] bg-color4 rounded-xl flex flex-col justify-center items-center gap-[3px] cursor-pointer hover:bg-orange-500'}>
      {icon}
    </div>
  )
}

const PanelCell = (props) => {
  const { children, panelsDataY, delC1, splitH, movingNote, actualNoteCoords, tasksY, parentPanelsDataY } = props
  const square = <FontAwesomeIcon icon={faSquare} color="white" size={'2xs'}/>
  const deleteIcon = <FontAwesomeIcon icon={faTrash} color="white"/>
  const [editName, setEditName] = useState(false)
  const editIcon = <FontAwesomeIcon icon={faPenToSquare}
                                    color="white"/>
  const applyIcon = <FontAwesomeIcon icon={faCheck}
                                     color="white"/>
  const newNoteIcon = <FontAwesomeIcon icon={faFile}
                                       color="white"/>
  let color
  if (actualNoteCoords && JSON.stringify(actualNoteCoords.coords) === JSON.stringify(panelsDataY.get('coords').toJSON()) && movingNote) {
    color = 'red'
  } else {
    color = panelsDataY.get('color')
  }

  return (
    <div style={{ backgroundColor: color }}
         className={'p-2 select-none w-full h-full bg-color3 rounded-xl overflow-hidden relative'}>
      <div className={'absolute right-2 top-2 flex gap-1'}>
        <PanelButton onClick={() => {
          createNewTask({ fullCords: getFullCords(parentPanelsDataY, panelsDataY.get('coords')), tasksY: tasksY })
        }}
                     icon={newNoteIcon}/>

        <ColorPickerPanel onChangeColor={(col)=>{
          panelsDataY.set('color', col.hex)
        }} selectedColor={color}/>

        {JSON.stringify(panelsDataY.get('coords')) !== JSON.stringify([0]) &&
          <PanelButton onClick={() => {delC1()}}
                       icon={deleteIcon}/>}
        <PanelButton onClick={() => {splitH(true)}}
                     icon={<div className={'flex gap-1'}>{square}{square}</div>}/>
        <PanelButton onClick={() => {splitH(false)}}
                     icon={<div className={'flex flex-col gap-1'}>{square}{square}</div>}/>
      </div>
      <div id={'content'} className={'flex flex-col'}>
        {editName ?
          <form onSubmit={() => {setEditName(false)}}
                className={'flex flex-row gap-2 items-center text-white'}>
            <input
              className={'w-48 bg-color2 text-white rounded'}
              value={panelsDataY.get('content')}
              onChange={(e) => {
                panelsDataY.set('content', e.target.value)
              }}/>
            <button type={'submit'}>
              {applyIcon}
            </button>
          </form>
          :
          <div className={'flex flex-row gap-2 items-center text-white'}>
            <a>{panelsDataY.get('content')}</a>
            <div onClick={() => {setEditName(true)}}>
              {editIcon}
            </div>
          </div>}
      </div>

    </div>)
}

export default PanelCell