import {ValidatePanel} from "../AuthPanel/common/auxComponents.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheck, faCircleExclamation, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";

const Modal = (props) => {
    const {children} = props
    return (
        <div className={"flex flex-row justify-center items-start z-50"}>
            <div id={"modal"} className={"modalBackground bottom-0 fixed w-[100vw] h-[100vh] z-50"}
                 onClick={() => {
                     setOpenPassModal(false)
                 }}>
            </div>
            <div id={"modal"}
                 className={"fixed max-w-[350px] bg-color3 m-auto mt-48 opacity-100 " +
                     "flex flex-col rounded-xl p-4 z-50 gap-2"}>
                {children}
            </div>
        </div>
    )
}

const RequirePassModal = ({
                              setOpenPassModal,
                              onClickButton,
                              oldPassField,
                              setOldPassField,
                              applyChanges,
                              badPass,
                              setBadPass,
                              closeModal
                          }) => {
    const backIcon = <FontAwesomeIcon icon={faArrowLeft} color="white" size="lg"/>
    const redExclamationIcon = <FontAwesomeIcon icon={faCircleExclamation} color="red"/>

    const onSubmit = () => {
        event.preventDefault()
        applyChanges(() => {
            closeModal()
        }, () => {
            setBadPass(true)
        })
    }
    return (
        <Modal>
            <div className={"flex flex-row justify-end"}>
                <div className={"cursor-pointer"} onClick={() => {
                    setOpenPassModal(false)
                }}>
                    {backIcon}
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className={"flex flex-col gap-2"}>
                    <p className={"text-white font-bold"}>Introduce tu contraseña actual para verificar tu
                        identidad:</p>
                    <input autoFocus={true} value={oldPassField} onChange={(event) => {
                        setOldPassField(event.target.value);
                        setBadPass(false)
                    }}
                           className={"pr-8 bg-color4 text-lg text-white w-full pl-3 rounded-xl outline-none focus:outline-orange-500"}
                           type={"password"}></input>
                    {badPass && <ValidatePanel conditions={[
                        {
                            condition: badPass && oldPassField.length > 0,
                            text: "Contraseña incorrecta.",
                            icon: redExclamationIcon
                        },
                    ]}/>}
                    <div className={"flex flex-row justify-end"}>
                        <button type="submit"
                                className={"text-white text-sm text-center bg-color1 rounded pl-2 pr-2 pt-1 pb-1"}>Aplicar
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default RequirePassModal