import { useDispatch, useSelector } from 'react-redux'
import {
  closeSession,
  mailExists,
  removeServiceToken,
  updateUser,
  updateUserImg,
  userExists
} from '../../../backend/userService.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CenterCard from '../../appInterface/components/CenterCard.jsx'
import { faArrowLeft, faCheck, faCircleExclamation, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import mailValidator from 'email-validator'
import { isValidUser } from '../../validators/userValidator.js'
import { isValidMail } from '../../validators/mailValidator.js'
import { passwordIsCorrect, usesAllRequiredChars, validatePass } from '../../validators/passValidator.js'
import { clear, setUserInfo } from '../../../redux/slices/usersSlice.js'
import ChangePic from './ChangePic.jsx'
import UserPageImg from './UserPageImg.jsx'
import ModifyUserField from './ModifyUserField.jsx'
import RequirePassModal from './RequirePassModal.jsx'
import ColorPicker from './ColorPicker.jsx'
import { useNavigate } from 'react-router-dom'
import useGoToUrl from '../../common/useGoToUrl.jsx'

export const UserPage = () => {
  const INITIAL_PASS_VALUE = '........'
  const icons = {
    redExclamationIcon: <FontAwesomeIcon icon={faCircleExclamation} color="red"/>,
    editIcon: <FontAwesomeIcon  icon={faPenToSquare} color="white" size="lg"/>,
    trashIcon: <FontAwesomeIcon icon={faTrash} color="white" size="lg"/>,
    applyIcon: <FontAwesomeIcon icon={faCheck} color="white" size="lg"/>,
    backIcon: <FontAwesomeIcon icon={faArrowLeft} color="white" size="lg"/>
  }
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [changes, setChanges] = useState({})
  const canvasRef = useRef(null)
  const blobUrlRef = useRef('')
  const [actualValues, setActualValues] = useState({
    fullName: user.userInfo.name, mail: user.userInfo.mail, username: user.userInfo.username
  })

  useEffect(() => {
    setActualValues({
      ...actualValues,
      fullName: user.userInfo.name,
      mail: user.userInfo.mail,
      username: user.userInfo.username
    })
  }, [user])

  const [layoutControlVars, setLayoutControlVars] = useState({
    changeActive: false,
    showRepeatPass: false,
    openPassModal: false,
    changingImage: false,
    imageIsLoaded: false
  })

  const [fieldValues, setFieldValues] = useState({
    fullNameField: "",
    mailField: "",
    userField: "",
    oldPassModalField: '',
    passField: INITIAL_PASS_VALUE,
    repeatPassField: ''
  })

  useEffect(()=>{
    setFieldValues(
      {
        fullNameField: user.userInfo.name,
        mailField: user.userInfo.mail,
        userField: user.userInfo.username,
        oldPassModalField: '',
        passField: INITIAL_PASS_VALUE,
        repeatPassField: ''
      }
    )
  }, [user])

  const [validationFields, setValidationFields] = useState({
    userFieldExists: false,
    mailFieldExists: false,
    badPass: false,
  })

  const closeModal = () => {
    setValidationFields({ ...validationFields, badPass: false })
    setLayoutControlVars({ ...layoutControlVars, openPassModal: false, changeActive: false })
    setFieldValues({ ...fieldValues, oldPassModalField: '', repeatPassField: '' })
  }

  const openModal = () => {
    setValidationFields({ ...validationFields, badPass: false })
    setFieldValues({ ...fieldValues, oldPassField: '' })
    setLayoutControlVars({ ...layoutControlVars, openPassModal: true })
  }

  const handleNameChange = (newValue) => {
    const newName = newValue.trimStart()
    let newChanges = { ...changes }
    if (newName !== actualValues.fullName)
      newChanges.name = newName
    else
      delete newChanges.name
    setChanges(newChanges)
    setFieldValues({ ...fieldValues, fullNameField: newName })
  }

  const handlePassChange = (newValue) => {
    const newPass = newValue.trim()
    let newChanges = { ...changes }
    if (fieldValues.passField === INITIAL_PASS_VALUE) {
      setFieldValues({ ...fieldValues, passField: '', repeatPassField: '' })
    } else {
      setFieldValues({ ...fieldValues, passField: newPass, repeatPassField: '' })
    }
    if (newPass !== INITIAL_PASS_VALUE)
      newChanges['pass'] = newPass
    else
      delete newChanges.pass

    setChanges(newChanges)
    if (passwordIsCorrect(validatePass(newPass)) && !layoutControlVars.showRepeatPass) {
      //setFieldValues({ ...fieldValues, repeatPassField: '' })
      setLayoutControlVars({ ...layoutControlVars, showRepeatPass: true })
    }
    if (!passwordIsCorrect(validatePass(newPass)) && layoutControlVars.showRepeatPass) {
      setLayoutControlVars({ ...layoutControlVars, showRepeatPass: false })
      //setFieldValues({ ...fieldValues, repeatPassField: '' })
    }
  }

  const handleRepeatPassChange = (newValue) => {
    const newPass = newValue.trim()
    setFieldValues({ ...fieldValues, repeatPassField: newPass })
    if (passwordIsCorrect(validatePass(fieldValues.passField)) && newPass === fieldValues.passField)
      setLayoutControlVars({ ...layoutControlVars, showRepeatPass: false })
  }

  const handleChangeUserName = (newName) => {
    const newUserName = newName.trim().toLowerCase()
    setFieldValues({ ...fieldValues, userField: newUserName })
    if (isValidUser(newUserName)) {
      dispatch(userExists(
        newUserName,
        () => {
          setValidationFields({ ...validationFields, userFieldExists: true })
        },
        () => {
          setValidationFields({ ...validationFields, userFieldExists: false })
        },
      ))
    } else
      setValidationFields({ ...validationFields, userFieldExists: false })
    let newChanges = { ...changes }
    if (newUserName !== user.userInfo.username)
      newChanges.user = newUserName
    else
      delete newChanges.user
    setChanges(newChanges)
  }

  const handleMailChange = (newValue) => {
    const newMail = newValue.trim().toLowerCase()
    setFieldValues({ ...fieldValues, mailField: newMail })
    if (isValidMail(newMail)) {
      dispatch(mailExists(
        newMail,
        () => {
          setValidationFields({ ...validationFields, mailFieldExists: true })
        },
        () => {
          setValidationFields({ ...validationFields, mailFieldExists: false })
        },
      ))
    } else
      setValidationFields({ ...validationFields, userFieldExists: false })
    let newChanges = { ...changes }
    if (newMail !== user.userInfo.mail)
      newChanges.mail = newMail
    else
      delete newChanges.mail
    setChanges(newChanges)
  }

  const userIsCorrect = () => {
    return fieldValues.userField && isValidUser(fieldValues.userField) && fieldValues.userField !== user.userInfo.username && !validationFields.userFieldExists
  }
  const mailIsCorrect = () => {
    return mailValidator.validate(fieldValues.mailField) && fieldValues.mailField !== user.userInfo.mail && !validationFields.mailFieldExists
  }
  const fullNameIsCorrect = () => {
    return fieldValues.fullNameField!=null && fieldValues.fullNameField.length > 0 && fieldValues.fullNameField !== actualValues.fullName
  }
  const passIsCorrect = () => {
    return passwordIsCorrect(validatePass(fieldValues.passField)) && fieldValues.passField === fieldValues.repeatPassField
  }
  const allValuesCorrect = () => {
    return userIsCorrect() && mailIsCorrect() && fullNameIsCorrect() && passIsCorrect()
  }
  const userIsDefault = () => {
    return user.userInfo.username === fieldValues.userField
  }
  const mailIsDefault = () => {
    return user.userInfo.mail === fieldValues.mailField
  }
  const fullNameIsDefault = () => {
    return actualValues.fullName === fieldValues.fullNameField
  }
  const passIsDefault = () => {
    return INITIAL_PASS_VALUE === fieldValues.passField
  }
  const allValuesCorrectOrDefault = () => {

    return (userIsCorrect() || userIsDefault()) &&
      (mailIsCorrect() || mailIsDefault()) &&
      (fullNameIsCorrect() || fullNameIsDefault()) &&
      (passIsCorrect() || passIsDefault())
  }

  const setAllDefault = () => {
    setFieldValues({
      ...fieldValues,
      userField: user.userInfo.username,
      fullNameField: actualValues.fullName,
      repeatPassField: '',
      mailField: user.userInfo.mail,
      passField: INITIAL_PASS_VALUE,
    })
  }

  const applyChanges = (onSuccess, onErrors) => {
    let username
    if (!userIsDefault())
      username = fieldValues.userField
    let fullName
    if (!fullNameIsDefault())
      fullName = fieldValues.fullNameField
    let newPass
    if (!passIsDefault())
      newPass = fieldValues.passField
    let mail
    if (!mailIsDefault())
      mail = fieldValues.mailField
    let oldPass
    if (!userIsDefault() || !mailIsDefault() || !passIsDefault())
      oldPass = fieldValues.oldPassModalField

    const onSuccessParam = (data) => {
      dispatch(setUserInfo({ ...user.userInfo, ...data }))
      setFieldValues({ ...fieldValues, oldPassField: '' })
      setLayoutControlVars({ ...layoutControlVars, changeActive: false, openPassModal: false, imageIsLoaded: false })
      onSuccess && onSuccess()
    }
    dispatch(updateUser(username, mail, fullName, oldPass, newPass, onSuccessParam, onErrors))
  }
  const navigate = useGoToUrl()

  const onCloseSession = () => {
    dispatch(removeServiceToken())
    dispatch(clear())
    navigate("/login")
  }

  const onSubmit = () => {
    event.preventDefault()
    if (changes.mail || changes.user || changes.pass)
      openModal()
    else
      applyChanges()
  }

  const saveImage = ({onSuccess}) => {
    canvasRef.current.toBlob((blob) => {
      blobUrlRef.current = URL.createObjectURL(blob)
      var reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = function () {
        const base64data = reader.result.split(',')[1]
        dispatch(updateUserImg(base64data, (data) => {
          dispatch(setUserInfo({
            ...user.userInfo,
            defaultimg: false,
            imgdata: base64data
          }))
          onSuccess&&onSuccess(data)

        }))
      }
    })
  }

  if(!user)
    return
  return (
    <div className={'w-full h-full p-2'}>
      {layoutControlVars.openPassModal &&
        <RequirePassModal setOpenPassModal={(x) => setLayoutControlVars({ ...layoutControlVars, openPassModal: x })}
                          oldPassField={fieldValues.oldPassModalField}
                          setOldPassField={(x) => {setFieldValues({ ...fieldValues, oldPassModalField: x })}}
                          applyChanges={applyChanges} closeModal={closeModal} badPass={validationFields.badPass}
                          setBadPass={(x) => {setValidationFields({ ...validationFields, badPass: x })}}
        />}
      <CenterCard extraClasses={'md:w-[600px]'}>
        <form onSubmit={onSubmit} className={'md:min-h-[350px]'}>
          <div className={'flex flex-col gap-2 pr-2'}>
            <div id={'upperRow'} className={'flex flex-row justify-between'}>
              <a className={'text-white text-3xl font-bold'}>Tu Usuario</a>
            </div>
            <div
              className={'flex flex-col-reverse md:flex-row md:justify-between items-center md:items-start flex-wrap justify-center gap-8 mt-2 '}>
              <div className="w-[250px] justify-center">
                {layoutControlVars.changingImage &&
                  <div className={'justify-center'}>
                    <ChangePic
                      setChangingImage={(x) => {setLayoutControlVars({ ...layoutControlVars, changingImage: x })}}
                      canvasRef={canvasRef}
                      setImageIsLoaded={(x) => setLayoutControlVars({ ...layoutControlVars, imageIsLoaded: x })}/>
                  </div>
                }
                {!layoutControlVars.changingImage &&

                  <div>
                    <div className={'flex flex-row justify-end'}>
                      {!layoutControlVars.changeActive && <div className={'cursor-pointer'} onClick={() => {
                        setAllDefault()
                        setChanges(false)
                        setLayoutControlVars({ ...layoutControlVars, changeActive: true })
                      }}>
                        {icons.editIcon}
                      </div>}
                      {layoutControlVars.changeActive && <div className={'flex flex-row gap-4'}>
                        {Object.keys(changes).length > 0 &&
                          <div className={'cursor-pointer'} onClick={() => {
                            setLayoutControlVars({ ...layoutControlVars, changeActive: false, showRepeatPass: false })
                            setFieldValues({ ...fieldValues, oldPassModalField: '' })
                            setChanges(false)
                          }}>
                            {icons.trashIcon}
                          </div>}
                        {Object.keys(changes).length > 0 && allValuesCorrectOrDefault() &&
                          <div className={'cursor-pointer'} onClick={() => {
                            if (changes.mail || changes.user || changes.pass)
                              openModal()
                            else
                              applyChanges()
                          }}>
                            <button type="submit">{icons.applyIcon}</button>
                          </div>}
                        {Object.keys(changes).length === 0 &&
                          <div className={'cursor-pointer'} onClick={() => {
                            setLayoutControlVars({ ...layoutControlVars, changeActive: false })
                          }}>
                            {icons.backIcon}
                          </div>}
                      </div>}

                    </div>

                    <div className={'flex flex-col gap-1 w-full'}>

                      <ModifyUserField name={'Nombre'}
                                       actualValue={actualValues.fullName}
                                       field={fieldValues.fullNameField}
                                       onChange={handleNameChange}
                                       changeActive={layoutControlVars.changeActive}
                                       okCond={fullNameIsCorrect()}
                                       changeName={'name'}
                                       autoFocus={true}
                                       errorConds={[
                                         {
                                           condition: fieldValues.fullNameField && fieldValues.fullNameField.length < 1,
                                           text: 'Obligatorio.',
                                           icon: icons.redExclamationIcon
                                         },
                                       ]}/>
                      <ModifyUserField name={'Correo'} actualValue={actualValues.mail}
                                       field={fieldValues.mailField}
                                       onChange={handleMailChange}
                                       changeActive={layoutControlVars.changeActive}
                                       changeName={'mail'}
                                       okCond={mailIsCorrect()}
                                       errorConds={[
                                         {
                                           condition: fieldValues.mailField && fieldValues.mailField.length < 1,
                                           text: 'Obligatorio.',
                                           icon: icons.redExclamationIcon
                                         },
                                         {
                                           condition: fieldValues.mailField && !mailValidator.validate(fieldValues.mailField) && fieldValues.mailField.length > 0,
                                           text: 'Email inválido.',
                                           icon: icons.redExclamationIcon
                                         },
                                         {
                                           condition: user.userInfo.mail !== fieldValues.mailField && validationFields.mailFieldExists === true,
                                           text: 'Ya existe un usuario con ese mail.',
                                           icon: icons.redExclamationIcon
                                         },
                                       ]}/>
                      <ModifyUserField name={'Usuario'} actualValue={actualValues.username}
                                       field={fieldValues.userField}
                                       onChange={handleChangeUserName}
                                       changeActive={layoutControlVars.changeActive}
                                       okCond={userIsCorrect()}
                                       changeName={'name'}
                                       errorConds={[
                                         {
                                           condition: !(user.userInfo.username === fieldValues.userField) && validationFields.userFieldExists === true,
                                           text: 'Ya existe un usuario con ese nombre.',
                                           icon: icons.redExclamationIcon
                                         },
                                         {
                                           condition: !isValidUser(fieldValues.userField),
                                           text: 'Debe contener entre 4 y 12 caractéres.',
                                           icon: icons.redExclamationIcon,
                                         },
                                       ]}/>
                      <ModifyUserField name={'Contraseña'} actualValue={INITIAL_PASS_VALUE}
                                       field={fieldValues.passField}
                                       onChange={handlePassChange}
                                       changeActive={layoutControlVars.changeActive}
                                       changeName={'pass'}
                                       isPass={true}
                                       okCond={passIsCorrect()}
                                       errorConds={[
                                         {
                                           condition: INITIAL_PASS_VALUE !== fieldValues.passField && (fieldValues.passField.length < 6 || fieldValues.passField.length > 52),
                                           text: 'Debe tener entre 6 y 52 caractéres.',
                                           icon: icons.redExclamationIcon
                                         },
                                         {
                                           condition: INITIAL_PASS_VALUE !== fieldValues.passField && !usesAllRequiredChars(validatePass(fieldValues.passField)),
                                           text: 'Debe contener por lo menos una mayúscula, una minúscula y un número.',
                                           icon: icons.redExclamationIcon
                                         },
                                       ]}
                      />
                      {layoutControlVars.showRepeatPass && <ModifyUserField name={'Repite tu contraseña'}
                                                                            actualValue={''}
                                                                            field={fieldValues.repeatPassField}
                                                                            onChange={handleRepeatPassChange}
                                                                            changeActive={layoutControlVars.changeActive}
                                                                            isPass={true}
                                                                            isRepeatPass={true}
                                                                            okCond={fieldValues.passField === fieldValues.repeatPassField}
                                                                            errorConds={[
                                                                              {
                                                                                condition: fieldValues.repeatPassField.length > 0 && fieldValues.repeatPassField !== fieldValues.passField,
                                                                                text: 'Las contraseñas no coinciden.',
                                                                                icon: icons.redExclamationIcon
                                                                              },
                                                                              {
                                                                                condition: fieldValues.repeatPassField.length === 0,
                                                                                text: 'Introduce de nuevo tu contraseña para confirmar.',
                                                                                icon: icons.redExclamationIcon
                                                                              },
                                                                            ]}
                      />}


                    </div>
                  </div>
                }
              </div>
              <div className="flex flex-col gap-2">
                <div className={'flex flex-col md:mt-8'}>
                  <div style={{ borderColor: user.userInfo.color, borderWidth: '5px' }}
                       className={'bg-color3 h-[180px] w-[180px] rounded-xl relative flex justify-center items-center overflow-y-hidden'}>
                    {!layoutControlVars.imageIsLoaded && <UserPageImg changingImage={layoutControlVars.changingImage}/>}

                    <canvas ref={canvasRef}
                            className={layoutControlVars.imageIsLoaded ? 'rounded-xl min-w-full min-h-full' : 'rounded-xl min-w-full min-h-full hidden'}/>

                    {!layoutControlVars.changeActive && !layoutControlVars.changingImage &&
                      <div className={'absolute right-2 top-2 cursor-pointer p-2  rounded-xl bg-color3 hover:bg-gray-900 opacity-90 flex justify-center items-center'}
                           onClick={() => {
                             setLayoutControlVars({
                               ...layoutControlVars,
                               changingImage: !layoutControlVars.changingImage
                             })
                           }}>
                        {icons.editIcon}
                      </div>}
                  </div>
                  <ColorPicker changingImage={layoutControlVars.changingImage}/>
                </div>
                {layoutControlVars.imageIsLoaded &&
                  <div className={'flex flex-row justify-center w-[80%] ml-auto mr-auto gap-1'}>
                    <div
                      className={'hover:bg-red-400 w-[49%] bg-color3 flex flex-row justify-center p-2 rounded cursor-pointer'}
                      onClick={() => {
                        setLayoutControlVars({ ...layoutControlVars, changingImage: false, imageIsLoaded: false })
                      }}>
                      {icons.trashIcon}
                    </div>
                    <div
                      className={'hover:bg-green-400 w-[49%] bg-color3 flex flex-row justify-center p-2 rounded cursor-pointer'}
                      onClick={() => {
                        saveImage({onSuccess: ()=>{
                            setLayoutControlVars({ ...layoutControlVars, changingImage: false, imageIsLoaded: false })
                          }})
                      }}>
                      {icons.applyIcon}
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </form>
        <div className={'flex flex-row justify-end h-[30px]'}>
          {!layoutControlVars.changeActive && !layoutControlVars.changingImage &&
            <button className={'text-white text-sm text-center bg-color1 rounded pl-2 pr-2 pt-1 pb-1'}
                    onClick={onCloseSession}>Cerrar sesión
            </button>}
        </div>
      </CenterCard>
    </div>
  )
}

export default UserPage