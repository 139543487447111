import { ValidatePanel } from '../AuthPanel/common/auxComponents.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faRotateLeft } from '@fortawesome/free-solid-svg-icons'

const ModifyUserField = ({
  name,
  actualValue,
  field,
  onChange,
  isPass,
  autoFocus,
  okCond,
  errorConds,
  changeActive = false
}) => {
  const checkIcon = <FontAwesomeIcon icon={faCircleCheck} color="green"/>
  const resetIcon = <FontAwesomeIcon icon={faRotateLeft} color="white"/>
  const pClasses = 'flex flex-col gap-1 '
  const valueClasses = isPass ? 'text-white text-lg pl-3 blur select-none' : 'text-white text-lg pl-3'
  const INITIAL_PASS_VALUE = '........'
  return (
    <div className={pClasses}>
      <a className={'text-white text-xl font-bold pl-1'}>{name}: </a>
      <div className={'flex flex-col break-words'}>
        {changeActive ? <div className={'flex flex-row gap-2'}>
            <div className={'max-w-[250px] relative'}>
              <input autoFocus={autoFocus}
                     onFocus={()=>{
                       isPass&&field===INITIAL_PASS_VALUE&&onChange('')
                     }}
                onClick={()=>{
                  isPass&&field===INITIAL_PASS_VALUE&&onChange('')
                }}
                className={'pr-8 relative bg-color3 text-lg text-white w-full pl-3 rounded-xl outline-none focus:outline-orange-500'}
                value={field} onChange={(event) => {
                onChange(event.target.value)
              }} type={isPass ? 'password' : 'text'}></input>
              {field !== actualValue && <div onClick={() => {
                onChange(actualValue)
              }}
                                             className={'absolute z-30 right-2 top-0 cursor-pointer'}>
                {resetIcon}
              </div>}
              <div className={'absolute right-[-23px] top-0'}>
                {okCond && checkIcon}
              </div>
            </div>
          </div> :
          <a className={valueClasses}>{isPass ? 'notapass' : actualValue}</a>}
        <div className={'ml-2 mt-1'}>
          {changeActive && <div>
            <ValidatePanel conditions={errorConds}/>
          </div>}
        </div>


      </div>
    </div>
  )
}

export default ModifyUserField