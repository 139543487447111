import {useForm} from "react-hook-form";

const InputField = ({autoFocus, label, registerCallBack, borderCond, errorsAndConds, isPass, register}) =>{
    const inputClasses = "bg-color4 shadow appearance-none rounded w-full py-2 px-3 " +
        "text-white mb-1 leading-tight focus:outline-none focus:shadow-outline "
    return (
        <div className="pb-2">
            <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                {label}
            </label>
            <input autoFocus={autoFocus} type={isPass?"password":"text"} autoComplete={"off"}
                   className={inputClasses +
                       (borderCond ? "border-2 border-red-600" : "focus:outline-orange-500")}
                   {...register(registerCallBack, {required: true})} />
            <div className="flex flex-row flex-wrap">
                {errorsAndConds.map((x, index) =>
                  <p className={"text-red-500 text-xs italic " + (x.cond ? "" : "hidden")} key={index}>
                    {x.text}
                  </p>
                )}
            </div>
        </div>
    )
}

export default InputField