import {useSelector} from "react-redux";
import {useEffect} from "react";
import DefaultImage from '../../common/DefaultImage.js'

const UserImg = ({changingImage, className}) =>{
    const user = useSelector((state) => state.user)
    const defaultImg= DefaultImage
    const imgRoute = user.userInfo.defaultimg? defaultImg: `data:image/png;base64, ${user.userInfo.imgdata}`

    useEffect(()=>{},[changingImage])
    return(
        <img src={imgRoute} className={"shrink-0 min-w-full min-h-full " + className}/>
    )
}

export default UserImg