import {useDispatch, useSelector} from "react-redux";
import {setHideMenu, setUndoAndRedoCount} from "../../../../redux/slices/kanbanSlice.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faListCheck, faProjectDiagram, faTable, faUsers} from "@fortawesome/free-solid-svg-icons";
import useGoToUrl from "../../../common/useGoToUrl.jsx";
const iconSize = "xl"

const RespMenuButton = ({icon, url, onClick, index, selectedIndex}) => {
    const menuIcon = <FontAwesomeIcon size={iconSize} icon={faBars} color={'white'}/>
    const navigate = useGoToUrl()
    const dispatch = useDispatch()
    const yjsData = useSelector((state) => state.kanban.yjsData)
    return (
        <div className={`p-2 bg-red-500 z-[10020] ${index === selectedIndex?"bg-color1": "bg-color2"} rounded border-2 border-white flex justify-center items-center w-fit`}
             onClick={() => {
                 dispatch(setUndoAndRedoCount({
                     undoCount:0,
                     redoCount:0,
                 }))
                 yjsData.undoManager.clear()
                 url&&navigate(url)
                 onClick&&onClick()
             }}>
            {icon?icon:menuIcon}
        </div>
    )
}


const KanbanEditorLeftMenuMobile = ({ kanbanId, selectedIndex}) => {
    const kanbanIcon = <FontAwesomeIcon icon={faTable} color="white" size={iconSize}/>
    const usersIcon = <FontAwesomeIcon icon={faUsers} color="white" size={"lg"}/>
    const taskIcon = <FontAwesomeIcon icon={faListCheck} color="white" size={iconSize}/>
    const graphIcon = <FontAwesomeIcon icon={faProjectDiagram} color="white" size={iconSize}/>
    const dispatch = useDispatch()
    return (
        <div className={"w-fit h-fit  absolute top-[10px] left-4 "}>
            <div
                className={"h-fit w-fit flex flex-row  gap-2"}>

                <RespMenuButton index={0} selectedIndex={selectedIndex} icon={kanbanIcon} url={'/kanbanB?id=' + kanbanId} onClick={()=>{dispatch(setHideMenu(true))}}/>
                <RespMenuButton index={1} selectedIndex={selectedIndex} icon={usersIcon} url={'/kanbanUsers?id=' + kanbanId}/>
                <RespMenuButton index={2} selectedIndex={selectedIndex} icon={taskIcon} url={'/kanbanTasks?id=' + kanbanId}/>
                <RespMenuButton index={3} selectedIndex={selectedIndex} icon={graphIcon} url={'/kanbanGraph?id=' + kanbanId}/>
            </div>
        </div>

    )
}

export default KanbanEditorLeftMenuMobile