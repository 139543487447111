import PanelCell from './PanelCell.jsx'
import ResizablePanels from './ResizablePanels.jsx'
import { useEffect, useState } from 'react'
import { deleteKanbanPanel, splitKanbanPanel } from '../../../../yjs/functions/YjsPanelFuncions.js'

const RecursiveKanban = ({
  panelsDataY, parentPanelsDataY, parentY, tasksY, movingNote,
  actualNoteCoords, containerRef
}) => {
  const [state, setState] = useState({
    split:false
  })
  useEffect(()=>{
    panelsDataY && panelsDataY.observe(e=>{
      setState({split: panelsDataY.get('split')})
    })
  }, [panelsDataY])

  if (!panelsDataY)
    return


  const setCoordsAtDelete = (panelsDataY, delPos) => {
    let actCoords = panelsDataY.get('coords')
    actCoords.delete(delPos, 1)
    if (panelsDataY.get('split')) {
      setCoordsAtDelete(panelsDataY.get('child1'), delPos)
      setCoordsAtDelete(panelsDataY.get('child2'), delPos)
    }
  }


  if (panelsDataY.get('split') === false) {
    return <PanelCell parentPanelsDataY={parentPanelsDataY} tasksY={tasksY} actualNoteCoords={actualNoteCoords}
                      movingNote={movingNote} splitH={(horizontal) => {splitKanbanPanel(horizontal, panelsDataY, parentPanelsDataY, tasksY, containerRef)}}
                      delC1={() => {deleteKanbanPanel(panelsDataY, parentPanelsDataY, parentY, tasksY)}} panelsDataY={panelsDataY}>{panelsDataY.get('coords')}</PanelCell>
  } else {
    return (
      <ResizablePanels parentPanelsDataY={parentPanelsDataY} tasksY={tasksY} containerRef={containerRef} panelsDataY={panelsDataY}
                       child1={<RecursiveKanban parentPanelsDataY={parentPanelsDataY} tasksY={tasksY}
                                                containerRef={containerRef} actualNoteCoords={actualNoteCoords}
                                                movingNote={movingNote} parentY={panelsDataY}
                                                panelsDataY={panelsDataY.get('child1')}/>}
                       child2={<RecursiveKanban parentPanelsDataY={parentPanelsDataY} tasksY={tasksY}
                                                containerRef={containerRef} actualNoteCoords={actualNoteCoords}
                                                movingNote={movingNote} parentY={panelsDataY}
                                                panelsDataY={panelsDataY.get('child2')}/>}
      />
    )
  }
}

export default RecursiveKanban