
export const hasValidLengthUser = (user) =>{
    const minLength = 4
    const maxLegth = 12
    return (user.length >= minLength && user.length<=maxLegth)
}

export function isOnlyLowercaseAndNumbers(str) {
    // Regular expression to match only lowercase letters and numbers
    const regex = /^[a-z0-9]+$/;
    return regex.test(str);
}

export const isValidUser = (user) =>{
    return user && hasValidLengthUser(user)
}