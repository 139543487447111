import { useEffect, useState } from 'react'
import { getUserPublicInfo } from '../../../../backend/userService.js'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import DefaultImage from '../../../common/DefaultImage.js'
import {
  deleteCollaboratorFromKanban,
  deleteCollaboratorFromTasks,
  findCollaboratorIndexById
} from '../../../../yjs/functions/YjsCollaboratorFunctions.js'

const UserCard = ({userId, isOwner, collaboratorsY, tasksY}) =>{
  const defaultImg= DefaultImage
  const [user, setUser] = useState(null)
  const delIcon = <FontAwesomeIcon icon={faMinus} color="white" size={"xl"}/>
  const userInfo = useSelector((state) => state.user.userInfo)

  const keepLength = (value, length) =>{
    return value.length<length?value:(value.substring(0, length-1)+"...")
  }
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getUserPublicInfo(userId, (data)=>{setUser(data)}))
  }, [])

  if(!user || !userId)
    return

  const imgRoute = user.defaultimg? defaultImg: `data:image/png;base64, ${user.imgdata}`
  return(
  <div className={"flex  w-full"}>
    <div className={"w-full flex break-keep flex-row bg-color3 justify-between rounded-2xl p-2 text-white"}>
      <div className={"flex flex-row gap-2 ellipsis"}>
        <div style={{borderColor: user.color, borderWidth: "5px"}}
          className={"rounded-2xl"}>
          <img style={{minWidth:"80px", width:"80px", height:"80px"}} src={imgRoute} className={"rounded-2xl shrink-0 w-full h-full "}/>
        </div>
        <div className={"flex flex-col w-full ellipsis"}>
          <a className={"font-bold ellipsis"}>{"@"+user.username}</a>
          <a className={"ellipsis"}>{user.name}</a>
          <a>{keepLength(user.mail, 30)}</a>
        </div>
      </div>



      <div className={"flex justify-end items-center w-6 pr-2"}>
        {!isOwner &&
          <div className={"cursor-pointer"} onClick={()=>{
            deleteCollaboratorFromKanban(userId, collaboratorsY, tasksY)
          }
          }>
          {delIcon}
        </div>}
      </div>
    </div>

  </div>


  )
}
export default UserCard