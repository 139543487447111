import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPenToSquare, faArrowTurnUp} from "@fortawesome/free-solid-svg-icons";
import {setHideMenu} from "../../../../redux/slices/kanbanSlice.js";
import useGoToUrl from "../../../common/useGoToUrl.jsx";
import KanbanSizeController from "../kanbanPanels/KanbanSizeController.jsx";

const MenuOption = (props) => {
    const {icon, onClick, children} = props
    return (
        <div className={"landscape:w-[20vw] portrait:w-full h-fit ellipsis flex gap-1 w-full p-1"}
             onClick={() => {
                 onClick && onClick()
             }}>
            <div className={"flex justify-center items-center"}>
                {icon}
            </div>
            <a className={"w-full text-white"}>{children}</a>
        </div>
    )
}
const KanbanEditorTopBarMobile = () => {
    const [name, setName] = useState('')
    const dispatch = useDispatch()
    const yjsData = useSelector((state) => state.kanban.yjsData)
    const editIcon = <FontAwesomeIcon icon={faPenToSquare} color="white"/>
    const minimizeIcon = <FontAwesomeIcon icon={faMinus} color="white"/>
    const quitIcon = <FontAwesomeIcon icon={faArrowTurnUp} color="white"/>
    const [editOptions, setEditOptions] = useState(false)
    const navigate = useGoToUrl()
    useEffect(() => {
        yjsData.kanbanPropsY.observe(e => setName(yjsData.kanbanPropsY.get('name')))
        setName(yjsData.kanbanPropsY.get('name'))
    }, [])
    return (
        <div
            className={"w-fit h-fit absolute left-[10px] top-[60px]  z-[10000] flex flex-col  bg-color3 rounded-2xl p-2"}>
            <div className={"w-72 h-fit ellipsis"}>
                <a className={"w-full text-2xl text-white"}>{name}</a>
            </div>
            {editOptions && <div className={"w-72"}>
                <KanbanSizeController kanbanPropsY={yjsData.kanbanPropsY}/>
            </div>}
            {!editOptions && <MenuOption icon={editIcon} onClick={() => {
                setEditOptions(true)
            }}>Editar opciones</MenuOption>}
            {editOptions && <MenuOption icon={editIcon} onClick={() => {
                setEditOptions(false)
            }}>Listo</MenuOption>}
            {!editOptions && <MenuOption icon={minimizeIcon} onClick={() => {
                dispatch(setHideMenu(true))
            }}>Minimizar el menú</MenuOption>}
            {!editOptions && <MenuOption icon={quitIcon} onClick={() => {
                navigate("/kanbans")
            }}>Salir del tablero</MenuOption>}
        </div>
    )
}

export default KanbanEditorTopBarMobile