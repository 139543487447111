import RecursiveKanban from './kanbanPanels/RecursiveKanban.jsx'
import { useEffect, useRef, useState } from 'react'
import Note from './notes/Note.jsx'
import { useSelector } from 'react-redux'
import CursorOverlay from './awareness/CursorOverlay.jsx'
import { dumpKanbanToGoTemplate, dumpKanbanToGoTemplateT } from '../../../yjs/functions/YjsPanelFuncions.js'


const KanbanContainer = ({ kanbanId, scale, setScale }) => {
  const ref = useRef()
  const [actualNoteCoords, setActualNoteCoords] = useState(null)
  const [movingNote, setMovingNote] = useState(false)
  const [kanbanSize, setKanbanSize] = useState({width: 0, height:0})
  const [parentPanelsData, setParentPanelsData] = useState(null)
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const [notes, setNotes] = useState([])
  useEffect(()=>{
    let propsOb
    yjsData.kanbanPropsY.observe(propsOb=e => setKanbanSize({width: yjsData.kanbanPropsY.get('width'), height: yjsData.kanbanPropsY.get('height')}))
    setKanbanSize({width: yjsData.kanbanPropsY.get('width'), height: yjsData.kanbanPropsY.get('height')})
    setParentPanelsData(yjsData.kanbanY)
    let tasksYOb
    yjsData.tasksY.observe(tasksYOb=e => {
      setNotes(yjsData.tasksY.map((x,i)=>{
        return <Note scale={scale} collaboratorsY={yjsData.collaboratorsY} index={i} tasksY={yjsData.tasksY}
                     key={x.get("id")} taskY={x} setActualNoteCoords={setActualNoteCoords} movingNote={movingNote}
                     setMovingNote={setMovingNote} panelsDataY={yjsData.kanbanY} propsY={yjsData.kanbanPropsY} containerRef={ref}/>
      }))
    })
    setNotes(yjsData.tasksY.map((x,i)=>{
      return <Note scale={scale} collaboratorsY={yjsData.collaboratorsY} index={i} tasksY={yjsData.tasksY}
                   key={x.get("id")} taskY={x} setActualNoteCoords={setActualNoteCoords} movingNote={movingNote}
                   setMovingNote={setMovingNote} panelsDataY={yjsData.kanbanY} propsY={yjsData.kanbanPropsY} containerRef={ref}/>
    }))

    return()=>{
      yjsData.kanbanPropsY.unobserve(propsOb)
      yjsData.tasksY.unobserve(tasksYOb)
    }
  }, [scale])

  if (!parentPanelsData || !yjsData.kanbanPropsY)
    return



  return (
      <div ref={ref}
           style={{
             width: kanbanSize.width*scale + 'px', height: kanbanSize.height*scale + 'px',
           }}
           className={' scale-100 relative w-full h-full mb-96 mr-72 mt-6 ml-6 inline-block'}
           >
          <RecursiveKanban parentPanelsDataY={parentPanelsData} containerRef={ref} tasksY={yjsData.tasksY}
                           actualNoteCoords={actualNoteCoords} movingNote={movingNote} panelsDataY={parentPanelsData} kanbanId={kanbanId}/>
        {notes}
        <CursorOverlay scale={scale} parentRef={ref}/>
      </div>)
}
export default KanbanContainer