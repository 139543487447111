import {getServiceToken, removeServiceToken} from "./userService.js";

const networkErrorCallback = () => {window.location.replace('/networkError')};
let reauthenticationCallback = () => {
    removeServiceToken()
    const url = "/reAuth?from="+window.location.pathname+window.location.search
    window.location.replace(url)
};

let tooManyRequestsCallback = () => {
    const url = "/tooManyRequests"
    window.location.replace(url)
};

const isJson = response => {

    const contentType = response.headers.get("content-type");

    return contentType && contentType.indexOf("application/json") !== -1;

}

const handleOkResponse = (response, onSuccess) => {

    if (!response.ok) {
        return false;
    }

    if (!onSuccess) {
        return true;
    }

    if (response.status === 204) {
        onSuccess();
        return true;
    }

    if (isJson(response)) {
        response.json().then(payload => onSuccess(payload));
    }

    return true;

}

const handle4xxResponse = (response, onErrors) => {
    console.log(response.status)
    if (response.status < 400 || response.status >= 500) {
        return false;
    }
    console.log(response)
    if (!isJson(response)) {
        networkErrorCallback();
    } else {
        response.json().then(payload => {
            if (response.status === 401) {
                if (payload.reAuth === true) {
                    reauthenticationCallback();
                }
            }
            if (response.status === 429){
                console.log("tooManyRequest")
                const url = "/tooManyRequests"
                window.location.replace(url)
                return true
            }

            if (onErrors)
                onErrors(payload);
        })
    }
    return true;
}

const handleResponse = (response, onSuccess, onErrors) => {
    if (handleOkResponse(response, onSuccess)) {
        return;
    }

    if (handle4xxResponse(response, onErrors)) {
        return;
    }

    networkErrorCallback();

}

export const config = (method, body) => {

    const config = {};

    config.method = method;

    if (body) {
        if (body instanceof FormData) {
            config.body = body;
        } else {
            config.headers = {'Content-Type': 'application/json'};
            config.body = JSON.stringify(body);
        }
    }

    let serviceToken = getServiceToken();
    if (serviceToken) {

        if (config.headers) {
            config.headers['Authorization'] = serviceToken;
        } else {
            config.headers = {'Authorization': serviceToken};
        }

    }

    return config;
}

export const appFetch = (path, options, onSuccess, onErrors) =>
    fetch(import.meta.env.VITE_BACKEND_URL + path, options)
        .then(response => handleResponse(response, onSuccess, onErrors))

