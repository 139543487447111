import ErrorPage from "./ErrorPage.jsx";

const NetworkError = () => {
    return (
        <ErrorPage
            title={"Ha ocurrido un error de red"}
            happeningCaptions={
                ["Puede que haya un problema en tu conexión",
                    "Puede que algo esté fallando en nuestro sistema"]}
            solutionCaptions={["Puedes volver a intentarlo más tarde",
                "Puedes contactar con el administrador"]}
        />
    )
}

export default NetworkError