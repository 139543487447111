import {useRef, useState} from "react";

const CustomText = () => {
    const ref = useRef()
    const [actualSelPos, setActualSelPos] = useState({start: 0, end: 0})
    const [calcLine, setCalcLine] = useState("calC")
    const [outLines, setOutLines] = useState([])

    const [cursorPos, setCursorPos] = useState({})

    const getStringSize = (string) => {
        var fontSize = 12;
        var test = document.getElementById("calcLine");
        test.innerText = string
        test.style.fontSize = fontSize;
        var height = (test.clientHeight);
        var width = (test.clientWidth);
        //console.log(test.clientWidth)
        return {height: height, width: width}
    }

    const checkCharPrevAndNext = (line, lineWidth, desiredWidth, checking, fromPrev = false, fromPost = false) => {
        //console.log("checking " + checking)
        let tryLine = line.substring(0, checking)
        let tryLinePrev = line.substring(0, checking - 1)
        let tryLinePost = line.substring(0, checking + 1)
        const sizeAct = getStringSize(tryLine)
        const sizePrev = getStringSize(tryLinePrev)
        const sizePost = getStringSize(tryLinePost)
        const actBiggerThatExpected = sizeAct.width > desiredWidth
        const postIsValid = sizePost.width < desiredWidth
        //console.log(tryLine)
        //console.log(sizeAct)
        if (actBiggerThatExpected) {
            return checkCharPrevAndNext(line, lineWidth, ref.current.clientWidth, checking - 1, false, true)
        }
        if (postIsValid) {
            return checkCharPrevAndNext(line, lineWidth, ref.current.clientWidth, checking + 1, true)
        }
        return checking
    }

    const getCutCharacter = (line, lineWidth, desiredWidth) => {
        //console.log("getCUt line: " + line)
        //console.log("getCUt lineWidth: " + lineWidth)
        //console.log("getCUt desiredWidth: " + desiredWidth)
        const proportion = desiredWidth / lineWidth
        const startChar = Math.floor(line.length * proportion)
        //console.log(lineWidth + " des: " + desiredWidth + " st: " + startChar)


        const cutPoint = checkCharPrevAndNext(line, getStringSize(line).width, ref.current.clientWidth, startChar)
        return cutPoint
    }

    const splitLine = (line, desiredWidth) => {
        const lineSize = getStringSize(line);
        //console.log(lineSize.width);
        if (lineSize.width <= ref.current.clientWidth) {
            return [line]
        } else {
            const numOfLines = Math.ceil(ref.current.clientWidth / lineSize.width)
            let outLines = []
            let leftLine = line
            let it = 0
            //console.log("textArea size: " + ref.current.clientWidth)
            while (getStringSize(leftLine).width >= ref.current.clientWidth) {
                //console.log("actTextArea size: " + ref.current.clientWidth)
                //console.log("leftLine size: " + getStringSize(leftLine).width)
                //console.log("it: " + it)
                it++
                //console.log("leftLine: " + leftLine)

                const cutC = getCutCharacter(leftLine, getStringSize(leftLine).width, ref.current.clientWidth)
                const l = leftLine.substring(0, cutC)
                //console.log("gotLine: " + l)
                leftLine = leftLine.substring(cutC)
                //console.log("new leftLine: " + leftLine)
                outLines.push(l)
            }
            outLines.push(leftLine)
            //console.log(outLines)
            return outLines
        }

    }

    const parseAllLines = (e) => {
        let lines = e.target.value.split("\n")
        let totalLines = []
        for (let i = 0; i < lines.length; i++) {
            const linesO = splitLine(lines[i])
            totalLines.push(linesO)

        }
        return totalLines
    }

    const getCursorRowAndCol = (outLines, position) => {
        let posC = position
        let i = 0
        let j = 0
        for (i = 0; i < outLines.length; i++) {
            for (j = 0; j < outLines[i].length; j++) {
                if ((posC - (outLines[i][j].length + 1)) < 0) {
                    if (posC === outLines[i][j].length && (!!outLines[i][j+1] || !!outLines[i+1]))
                        return {i: i+1, j: 0, offset: 0, isEndLine: true}
                    else
                        return {i: i, j: j, offset: posC}
                }
                posC = posC - (outLines[i][j].length + 1)

            }
        }
        return {i: i, j: j, offset: posC, firstL: true}
    }


    const onSelect = (e) => {


        const actPos = {
            start: e.currentTarget.selectionStart,
            end: e.currentTarget.selectionEnd
        }

        //console.log(actPos)
        //ref.current.setSelectionRange(4,4)
        //console.log(e.current.selectionStart + " " + e.current.selectionEnd)
        setActualSelPos(actPos)
        let totalLines = parseAllLines(e)
        setOutLines(totalLines)
        const rowsAndCols = getCursorRowAndCol(totalLines, e.currentTarget.selectionStart)
        //console.log(rowsAndCols.isEndLine)
        if(rowsAndCols.isEndLine){
            ref.current.setSelectionRange(e.currentTarget.selectionStart,e.currentTarget.selectionEnd)
        }
        setCursorPos(rowsAndCols)
    }

    const onKeyPress = (e) => {

        const actPos = {
            start: e.currentTarget.selectionStart,
            end: e.currentTarget.selectionEnd
        }

        //console.log(actPos)
        //ref.current.setSelectionRange(4,4)
        //console.log(e.current.selectionStart + " " + e.current.selectionEnd)
        setActualSelPos(actPos)
        let totalLines = parseAllLines(e)
        setOutLines(totalLines)
        const rowsAndCols = getCursorRowAndCol(totalLines, e.currentTarget.selectionStart)
        console.log(rowsAndCols)
        if(rowsAndCols.firstL){
            ref.current.setSelectionRange(e.currentTarget.selectionStart-1,e.currentTarget.selectionEnd-1)
        }

    }

    return (
        <div className={"flex flex-col w-full pl-2"}>

            <div className={"flex flex-col"}>
                {outLines.map((x, i) =>
                    <div key={i} className={"flex flex-col"}>
                        {x.map((y, j) => <a key={j}>{y}</a>)}
                    </div>
                )}
            </div>

            <a className={"h-12"}>{"i:" + cursorPos.i + " j: " + cursorPos.j + " offset: " + cursorPos.offset}</a>
            <a className={"h-12"}>{actualSelPos.start + " " + actualSelPos.end}</a>
            <a className={"w-fit"}></a>
            <div style={{width: "50px"}} className={"relative bg-red-800 w-fit"}>
                <a id={"calcLine"} style={{fontSize: "20px"}}
                   className={"bg-red-800 w-fit z-50 invisible absolute"}>{calcLine}</a>
                <textarea ref={ref} style={{fontSize: "20px"}} className={"w-full p-0 absolute"}
                            onKeyDown={onKeyPress}
                          onSelect={onSelect}/>
            </div>


        </div>
    )
}

export default CustomText