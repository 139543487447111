import { CirclePicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo } from '../../../redux/slices/usersSlice.js'
import { updateUserColor } from '../../../backend/userService.js'
import { useState } from 'react'

const ColorPicker = ({changingImage}) => {
  const user = useSelector((state) => state.user.userInfo)
  const dispatch = useDispatch()
  const [showPicker, setShowPicker] = useState(false)
  const pointSize=30
  const colors = [
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#03a9f4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#ffeb3b",
    "#ff9800",
    "#ff5722",
    "#795548"
  ]
  if(changingImage) {
    showPicker && setShowPicker(false)
    return
  }

  return (
    <div className={"relative flex justify-center mt-1"}>
      {!showPicker&&<div
        onClick={() => {setShowPicker(!showPicker)}}
        style={{
          width: pointSize + 'px', height: pointSize + 'px',
          borderRadius: '50%', backgroundColor: user.color,
          borderColor:"white", marginTop: "-10%"
        }}
        className={'absolute border-2 hover:border-4'}></div>}
      {showPicker&&<div className={'absolute bg-color4 p-2 rounded-2xl'}>
        <CirclePicker
          colors={colors}
          color={user.color}
          onChange={(col) => {
            dispatch(updateUserColor(col.hex, () => {}))
            dispatch(setUserInfo({ ...user, color: col.hex }))
            setShowPicker(!showPicker)
          }}
        />
      </div>}

    </div>
  )
}
export default ColorPicker