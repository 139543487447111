import { useNavigate } from 'react-router-dom'
import useGoToUrl from '../../../../common/useGoToUrl.jsx'

const TaskPageSelector = (props) =>{
  const {children, selectedTab} = props
  const navigate = useGoToUrl()
  const queryParameters = new URLSearchParams(window.location.search)
  const kanbanId = queryParameters.get('id')

  return(
    <div className={"flex flex-col pt-2 gap-2 w-full"}>
      <div className={"gap-2 flex flex-row text-white pl-2"}>
        <button style={{backgroundColor: selectedTab===1?"#e57f38":""}}
                className={"bg-color3 h-8 p-1 rounded-xl hover:bg-orange-500 cursor-pointer"}
                onClick={()=>{navigate("/kanbanTasks?id="+kanbanId)}}
        ><a className={"sm:inline hidden"}>Por</a> categoría</button>
        <button style={{backgroundColor: selectedTab===2?"#e57f38":""}}
                className={"bg-color3 h-8 p-1 rounded-xl hover:bg-orange-500 cursor-pointer"}
                onClick={()=>{navigate("/collaboratorTasks?id="+kanbanId)}}
        ><a className={"sm:inline hidden"}>Por</a> colaborador</button>
      </div>
      <div className={"w-full h-fit pl-1 pr-2 sm:pl-2 sm:pr-2 pb-2"}>
        {children}
      </div>
    </div>
  )

}

export default TaskPageSelector