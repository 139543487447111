import { useRef } from 'react'
import { panelDataToPanelCoords } from '../../../../yjs/functions/YjsPanelFuncions.js'

const ResizablePanels = ({ parentPanelsDataY, child1, child2, panelsDataY, tasksY, containerRef }) => {
  const ref = useRef()
  const horizontal = panelsDataY.get('splitHorizontal')
  const percentToPx = (value) => {
    return (value * (horizontal ? ref.current.offsetWidth : ref.current.offsetHeight)) / 100
  }
  const pxToPercent = (value) => {
    return (value / (horizontal ? ref.current.offsetWidth : ref.current.offsetHeight)) * 100
  }
  const pxToPercentContainer = (value) => {
    return (value / (horizontal ? containerRef.current.offsetWidth : containerRef.current.offsetHeight)) * 100
  }
  const percentToPxContainer = (value) => {
    return (value * (horizontal ? containerRef.current.offsetWidth : containerRef.current.offsetHeight)) / 100
  }

  const SEPARATOR_SIZE = 10
  const MIN_PANEL_SIZE = 20
  const positionPercent = panelsDataY.get('splitPos')

  const moveSlide = (touch) => (e) => {
    if(!touch && e.button!==0)
      return
    const target = touch ? e.changedTouches[0] : e
    const iniPos = horizontal ? target.clientX : target.clientY
    const onmousemove = (e) => {
      const target = touch ? e.changedTouches[0] : e
      const actPos = horizontal ? target.clientX : target.clientY
      const totalSize = horizontal ? ref.current.offsetWidth : ref.current.offsetHeight
      const movement = actPos - iniPos
      let pos = percentToPx(positionPercent)
      const newPosition = pos + movement
      if (newPosition < totalSize - MIN_PANEL_SIZE) {
        if (newPosition > MIN_PANEL_SIZE) {
          panelsDataY.set('splitPos', pxToPercent(newPosition))
        } else {
          panelsDataY.set('splitPos', pxToPercent(MIN_PANEL_SIZE))
        }
      } else {
        panelsDataY.set('splitPos', pxToPercent(totalSize - MIN_PANEL_SIZE))
      }
      updateNotes()
    }
    const onmouseup = () => {
      if (touch) {
        window.removeEventListener('touchmove', onmousemove)
        window.removeEventListener('touchend', onmouseup)
      } else {
        window.removeEventListener('mousemove', onmousemove)
        window.removeEventListener('mouseup', onmouseup)
      }
    }
    if (touch) {
      window.addEventListener('touchend', onmouseup)
      window.addEventListener('touchmove', onmousemove)
    } else {
      window.addEventListener('mouseup', onmouseup)
      window.addEventListener('mousemove', onmousemove)
    }

  }

  const updateNotes = () => {
    const panelCoords = panelDataToPanelCoords(parentPanelsDataY.toJSON())
    tasksY.map(taskY => {
      const actCoords = taskY.get('note').get('actCoords')
      let fullCoords
      panelCoords.map(x => {
        if (JSON.stringify(x.coords) === JSON.stringify(actCoords.toArray())) {
          fullCoords = x
        }
      })
      const posRelToCellX = taskY.get('note').get('posRelToCellX')
      const posRelToCellY = taskY.get('note').get('posRelToCellY')
      taskY.get('note').set('posX', pxToPercentContainer(percentToPxContainer(fullCoords.xIni) + posRelToCellX * percentToPxContainer(fullCoords.xEnd - fullCoords.xIni) / 100))
      taskY.get('note').set('posY', pxToPercentContainer(percentToPxContainer(fullCoords.yIni) + posRelToCellY * percentToPxContainer(fullCoords.yEnd - fullCoords.yIni) / 100))
    })
  }

  return (<div ref={ref} className={'relative w-full h-full'}>
    <div onMouseDown={moveSlide(false)} onTouchStart={moveSlide(true)}
         style={{
           left: horizontal ? 'calc(' + positionPercent + '% - ' + (SEPARATOR_SIZE / 2) + 'px)' : 0,
           top: horizontal ? 0 : 'calc(' + positionPercent + '% - ' + (SEPARATOR_SIZE / 2) + 'px)',
           width: horizontal ? SEPARATOR_SIZE + 'px' : '100%',
           height: horizontal ? '100%' : SEPARATOR_SIZE + 'px'
         }}
         className={`absolute touch-none ${horizontal ?"cursor-col-resize":"cursor-row-resize"}`}></div>
    <div id={'child1'} style={{
      width: horizontal ? 'calc(' + positionPercent + '% - ' + (SEPARATOR_SIZE / 2) + 'px)' : '100%',
      height: horizontal ? '100%' : 'calc(' + positionPercent + '% - ' + (SEPARATOR_SIZE / 2) + 'px)',
      left: 0,
      top: 0
    }}
         className={'absolute'}>
      {child1}
    </div>
    {<div id={'child2'} style={{
      width: horizontal ? 'calc(100% - ' + positionPercent + '% - ' + (SEPARATOR_SIZE / 2) + 'px)' : '100%',
      height: horizontal ? '100%' : 'calc(100% - ' + positionPercent + '% - ' + SEPARATOR_SIZE / 2 + 'px)',
      left: horizontal ? '' : 0,
      top: horizontal ? 0 : '',
      bottom: horizontal ? '' : 0,
      right: horizontal ? 0 : ''
    }}
          className={'absolute'}>
      {child2}
    </div>}
  </div>)
}

export default ResizablePanels