import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faGripLines} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

import { ExpandedRowContent } from './ExpandedRowContent.jsx'
import ParentName from './ParentName.jsx'
import { findTaskById, hasChilds } from '../../../../../yjs/functions/YjsTasksFunctions.js'
import useUserNameCache from '../../useUserNameCache.jsx'

const cellWidth = [17, 18, 15, 18, 15, 12, 5]

export const TaskTableHeader = () => {
  const HeaderItem = ({ width, value }) => {
    return (
      <div className={'p-1 overflow-hidden'} style={{ width: width + '%' }}>
        <a className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis font-bold'}>{value}</a>
      </div>
    )
  }

  return (<div className={'bg-color5 flex flex-col h-8 pl-1'}>
    <div className={'flex flex-row w-full  items-center justify-center pl-1'}>
      <HeaderItem width={cellWidth[0]} value={'Nombre'}/>
      <HeaderItem width={cellWidth[1]} value={'Descripción'}/>
      <HeaderItem width={cellWidth[2]} value={'Asignado a'}/>
      <HeaderItem width={cellWidth[3]} value={'Completado'}/>
      <HeaderItem width={cellWidth[4]} value={'Tiene hijos'}/>
      <HeaderItem width={cellWidth[5]} value={'Padre'}/>
      <HeaderItem width={cellWidth[6]} value={''}/>
    </div>
  </div>)
}

const TaskRow = ({ index, task, tasksY, collaboratorsY, indexInCategory }) => {
  const expandIcon = <FontAwesomeIcon icon={faChevronDown} color="white"/>
  const contractIcon = <FontAwesomeIcon icon={faGripLines} color="white"/>
  const [assignedUserInfo, setAssignedUserInfo] = useState(null)
  const collaboratorsCache = useUserNameCache()
  const [exp, setExp] = useState(false)
  if (!task.get)
    return

  const RowItem = ({ task }) => {
    const [parentName, setParentName] = useState("")
    const cValues = () =>({
      name: task.get('name').toString(),
      description: task.get('description').toString(),
      assignedTo: task.get('props').get('isAssigned') ?
        (collaboratorsCache[task.get('props').get('assignedTo')]&&collaboratorsCache[task.get('props').get('assignedTo')].username) : 'N/A',
      completed: task.get('props').get('completenessActive') ?
        task.get('props').get('completenessPercent').toString().slice(0,4) + '%' : 'N/A',
      hasChilds: hasChilds(task, tasksY) ?
        'Sí' : 'No',
      weight: task.get('props').get('completenessActive') ? "P:"+task.get('props').get('completenessWeight'):""
    })
    const [contractedRowValues, setContractedRowValues] = useState(cValues())

    useEffect(() => {
      if(task.get('props').get('isChildTask')){
        const parent = findTaskById(tasksY, task.get('props').get('parentTask'))
        if(parent){
          const name = parent.get('name').toString()
          setParentName(name)
        }


      }
    }, [])
    useEffect(()=>{
      task.observeDeep(e=>{
        setContractedRowValues(cValues())
        const parent = findTaskById(tasksY, task.get('props').get('parentTask'))
        if(parent){
          const name = parent.get('name').toString()
          setParentName(name)
        }
      })
    }, [task])

    return (
      <div className={'flex flex-row w-full items-center justify-center pl-1 hover:bg-gray-700 cursor-pointer'} onClick={() => {setExp(!exp)}}>
        <div className={'overflow-hidden p-1'} style={{ width: cellWidth[0] + '%' }}>
          <a
            className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis '}>{contractedRowValues.name}</a>
        </div>
        <div className={'overflow-hidden p-1'} style={{ width: cellWidth[1] + '%' }}>
          <a
            className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis '}>{contractedRowValues.description}</a>
        </div>
        <div className={'overflow-hidden p-1'} style={{ width: cellWidth[2] + '%' }}>
          <a
            className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis '}>{contractedRowValues.assignedTo}</a>
        </div>
        <div className={'overflow-hidden p-1'} style={{ width: cellWidth[3] + '%' }}>
          <a
            className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis '}>{contractedRowValues.completed} {contractedRowValues.weight}</a>
        </div>
        <div className={'overflow-hidden p-1'} style={{ width: cellWidth[4] + '%' }}>
          <a
            className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis '}>{contractedRowValues.hasChilds}</a>
        </div>
        <div className={'overflow-hidden p-1'} style={{ width: cellWidth[5] + '%' }}>
          <a
            className={'text-white block whitespace-nowrap overflow-hidden overflow-ellipsis '}><ParentName task={task} tasksY={tasksY}/></a>
        </div>

        <div className={'cursor-pointer'} id={'expand'} style={{ width: cellWidth[6] + '%' }}>
          {exp ? contractIcon : expandIcon}
        </div>
      </div>
    )
  }
  return (
    <div className={'flex flex-col pl-1'}
         style={{ backgroundColor: (indexInCategory % 2) === 0 ? '#202020' : '#101010' }}>
      <RowItem task={task}/>
      {exp &&
        <ExpandedRowContent task={task} tasksY={tasksY} collaboratorsY={collaboratorsY} setAssignedUserInfo={setAssignedUserInfo}/>
      }
    </div>
  )
}

export default TaskRow