import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import { closeSession, removeServiceToken } from '../../../backend/userService.js'
import UserPageImg from "../../users/userPage/UserPageImg.jsx";
import {useNavigate} from "react-router-dom";
import { clear } from '../../../redux/slices/usersSlice.js'
import useGoToUrl from '../../common/useGoToUrl.jsx'

const NavButton = ({index, selectedIndex, text, dir}) => {
    const navigate = useGoToUrl()
    const buttonColor = index === selectedIndex ? " bg-color1" : " bg-color4"
    const highlightButton = index === selectedIndex ? " cursor-default" : " hover:bg-orange-500 cursor-pointer"
    return (<button type="button"
        className={`h-full sm:w-auto w-10 whitespace-nowrap h-full text-white pt-1 pb-1 pl-2 pr-2 rounded flex justify-center items-center transition-all   ${buttonColor} ${highlightButton}`}
        onClick={() => {navigate(dir)}}>
        {text}
    </button>)
}

export const Logo = () => {
    return (<div className={"text-white text-xl h-[100%]"}>
        KanbanApp
    </div>)
}

const UserButton = ({selectedIndex}) => {
    const user = useSelector((state) => state.user)
    const navigate = useGoToUrl()
    const dispatch = useDispatch()
    const buttonColor = -1 === selectedIndex ? " bg-color1" : " bg-color4"
    const highlightButton = -1 === selectedIndex ? " cursor-default" : " hover:bg-orange-500 cursor-pointer"
    return (<div
        className={"h-full sm:w-auto w-10 relative rounded pr-2 pl-2 pt-1 pb-1 flex flex-row items-center gap-1 group mr-3" + buttonColor + highlightButton}
        onClick={() => {navigate("/user")}}>
        <div
            className={" h-[25px] w-[25px] flex flex-row justify-center items-center"}>
            <UserPageImg className={"rounded-[50%]"}/>
        </div>
        <a className={"text-white text-xs hidden sm:inline"}>@{user.userInfo.username}</a>
        <div className={"flex flex-col absolute right-0 top-7 scale-0 group-hover:scale-100 hover:scale-100 z-50"}>
            <div
                className="w-[100%] h-[20px]"></div>
            <div id={"userMiniPanel"}
                 className={"flex flex-col gap-2 p-4 bg-color2 rounded-3xl shadow-2xl bord items-center scale-0 group-hover:scale-100 hover:scale-100"}>
                <div className={"w-[80px]"}>
                    <UserPageImg className={"rounded-2xl"}/>
                </div>
                <div className={"flex flex-col"}>
                    <a className={"whitespace-nowrap text-sm text-white font-bold"}>{user.userInfo.name}</a>
                    <div className={"flex flex-row justify-end"}>
                        <a className={"text-xs whitespace-nowrap text-white font-bold hover:underline cursor-pointer"}
                           onClick={(e) => {
                               e.stopPropagation()
                               dispatch(removeServiceToken())
                               dispatch(clear())
                               navigate("/login")
                           }}>Cerrar sesión</a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}


const AppBar = ({buttons, selectedButton}) => {
    return (<div className={"flex flex-row justify-between items-center sm:p-2 pl-2 pt-1 pb-1 relative w-full h-full"}>
        <div className={"flex flex-row gap-4 h-[100%]"}>
            <div id={"appLogo"} className={"mt-auto mb-auto"}>
                <Logo/>
            </div>
            <div className={"flex flex-row items-center gap-2"}>
                {buttons.map((value, index) => <NavButton index={index} key={index} selectedIndex={selectedButton}
                                                          text={value.name} dir={value.dir}/>)}
            </div>
        </div>
        <div id={"userButton"} className={"h-full"}>
            {selectedButton!==-1 && <UserButton selectedIndex={selectedButton}/>}
        </div>
    </div>)
}

export default AppBar