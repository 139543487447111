import ReactSlider$1 from "react-slider";


const ZoomSlider = ({scale, setScale}) =>{

    return (
        <div className={"z-50 w-full h-fit"}>
            <ReactSlider$1
                ariaLabelledby="slider-label"
                className="horizontal-slider"
                trackClassName="example-track"
                renderThumb={(props, state) => <div {...props}><div className={"bg-white w-8 h-6 text-center cursor-pointer font-bold"}>{scale}</div></div>}
                renderTrack={(props, state) => <div {...props}><div className={"w-full h-6 flex flex-col justify-center"}><div className={"w-full h-2 bg-blue-100"}></div></div></div>}
                max={1}
                min={0.2}
                step={0.1}
                defaultValue={scale}
                onChange={(e)=>{setScale(e)}}
            />
        </div>
    );
}

export default ZoomSlider