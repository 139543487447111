import PasswordValidator from "password-validator";

let passValidator =
    new PasswordValidator()
        .is().min(6)
        .is().max(52)
        .has().uppercase()
        .has().lowercase()
        .has().digits()
        .has().symbols()
        .has().not().spaces()

export const validatePass = (pass)=>{
    return passValidator.validate(pass, {list: true})
}

export const correctPassLength = (passValidationResult) => {
    for (var j = 0; j < passValidationResult.length; j++) {
        if (passValidationResult[j].match("min") || passValidationResult[j].match("max"))
            return false
    }
    return true
}
export const usesAllRequiredChars = (passValidationResult) => {
    for (var j = 0; j < passValidationResult.length; j++) {
        if (passValidationResult[j].match("uppercase") ||
            passValidationResult[j].match("lowercase") ||
            passValidationResult[j].match("digits"))
            return false
    }
    return true
}

export const usesAllRequiredCharsPass = (pass) =>{
    return usesAllRequiredChars(validatePass(pass))
}

export const passwordIsCorrect = (passValidationResult) => {
    return (correctPassLength(passValidationResult) && usesAllRequiredChars(passValidationResult))
}
