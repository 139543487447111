import RecoverAccount from "./RecoverAccount.jsx";
import ForgotImg from "../../../../../media/images/UI/Forgot password-bro.png";
import {HorizontalCard} from "../../../common/Common.jsx";
import useGoToUrl from "../../../common/useGoToUrl.jsx";

const RecoverAccountCard = () => {
    const navigate = useGoToUrl()
    return (
        <HorizontalCard
            onClose={()=>{navigate('/login')}}
            rightContent={
                <RecoverAccount></RecoverAccount>
            }
            leftImage={ForgotImg}
            leftContent={
                <div className="flex flex-col text-white text-md md:mb-2 md:ml-3 p-2">
                    <a className="text-lg font-bold">¿No puedes acceder a tu cuenta?</a>
                    <li>Te enviaremos un correo con un enlace para restablecerla.</li>
                    <li>Los enlaces son válidos durante 1h.</li>
                    <li>Al generar un nuevo enlace los anteriores dejarán de ser válidos.</li>
                </div>
            }>
        </HorizontalCard>
    )
}

export default RecoverAccountCard