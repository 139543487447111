import YjsTextArea from '../awareness/yjsText/YjsTextArea.jsx'
import { faArrowLeft, faArrowRight, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

const NoteFields = ({taskY, textAreaBckColor}) => {
  const nextIcon = <FontAwesomeIcon icon={faArrowRight} color="black" size="sm"/>
  const previousIcon =  <FontAwesomeIcon icon={faArrowLeft} color="balck" size="sm"/>
  const [dFields, setDFields] = useState([])
  const [actualIndex, setActualIndex] = useState(0)
  const nextField = () =>{
    if(actualIndex<dFields.length-1)
      setActualIndex(actualIndex+1)
    else
      setActualIndex(0)
  }
  const previousField = () =>{
    if(actualIndex>0)
      setActualIndex(actualIndex-1)
    else
      setActualIndex(dFields.length-1)
  }


  const createNoteFields = (taskY) =>{
    let fields = [{name: "Descripción",key: taskY.get("id")+"desc",id: taskY.get("id")+"desc", field: taskY.get("description")}]
    taskY.get("fields").map(x=>
      fields.push(
        {name: x.get("name").toString(), key: x.get("id"), id: "fieldValue-"+x.get("id"), field: x.get("value")}
      )
    )
    setDFields(fields)
  }

  useEffect(()=>{
    let ob
    const fields = taskY.get("fields")
    fields.observeDeep(ob=e=>{
      createNoteFields(taskY)
    })
    createNoteFields(taskY)
    return()=>fields.unobserveDeep(ob)

  }, [])

  if(dFields.length<1)
    return
  if(!dFields[actualIndex]) {
    setActualIndex(0)
    return
  }

  return (
    <div className={"flex flex-col"}>
      <div className={"flex justify-between pr-2 pl-2"}>
        <div className={"flex overflow-hidden"}>
          <a style={{fontSize: "18px"}}
            className={'block whitespace-nowrap overflow-hidden overflow-ellipsis font-["handwritten"] font-bold'}>{dFields[actualIndex].name}</a>
          <a>:</a>
        </div>

        {dFields.length>1&&
          <div className={'flex gap-1'}>
          <button onClick={() => {previousField()}}>{previousIcon}</button>
          <button onClick={() => {nextField()}}>{nextIcon}</button>
        </div>}
      </div>
      <YjsTextArea
        key={dFields[actualIndex].key}
        id={dFields[actualIndex].id}
        style={{ backgroundColor: textAreaBckColor, resize: 'none', fontSize: "17px" }}
        field={dFields[actualIndex].field}
        className={'w-full mt-1 h-24 p-2 rounded-2xl focus:outline-none ' +
          'focus:shadow-outline focus:outline-orange-300 font-["handwritten"] font-bold'}
      />

    </div>
  )
}

export default NoteFields