import AuthPanelBackground from "../users/AuthPanel/common/AuthPanelBackground.jsx";
import {HorizontalCard} from "../common/Common.jsx";
import Image from "../../../media/images/UI/No data-bro.png"
import {useNavigate} from "react-router-dom";

const ErrorPage = ({title="Ha ocurrido un error", happeningCaptions = [], solutionCaptions = []}) => {
    const navigate = useNavigate();
    const LeftText = () => {
        return (
            <div className="flex flex-col text-white text-md mb-2 ml-3 gap-4">
                <a className="text-2xl font-bold">{title}</a>
                <div className={"ml-1"}>
                    <a className="text-lg font-bold">¿Qué está pasando?</a>
                    <div className="ml-2">
                        {happeningCaptions.map((x,i) => <li key={i}>{x}.</li>)}
                    </div>
                    <a className="text-lg font-bold">¿Qué puedo hacer?</a>
                    <div className="ml-2">
                        {solutionCaptions.map((x,i) => <li key={i}>{x}.</li>)}
                    </div>
                </div>
                <ButtonDown></ButtonDown>
            </div>
        )
    }

    const goToApp = () => {
        navigate('/', {replace: true})
    }

    const ButtonDown = () => {
        return (
            <div id="buttonDiv" className={"flex md:flex-row md:justify-end"}>
                <button onClick={goToApp}
                        className={"bg-color1 text-white font-bold py-4 px-8 rounded text-l"}>
                    Volver a la aplicación
                </button>
            </div>
        )
    }


    return (
        <AuthPanelBackground>
            <HorizontalCard
                rightContent={<LeftText/>}
                leftImage={Image}
                onClose={goToApp}
            >

            </HorizontalCard>
        </AuthPanelBackground>
    )
}

export default ErrorPage