import { useEffect, useState } from 'react'
import { findTaskById } from '../../../../../yjs/functions/YjsTasksFunctions.js'

const ParentName = ({task, tasksY}) =>{
  const [parentName, setParentName] = useState("")
  const [isChildTask, setIsChildTask] = useState(false)
  const [parentOb, setParentOb] = useState({t: null, ob:null})
  const updateParentOb = (newParentOb) =>{
    parentOb.t&&parentOb.ob&&parentOb.t.unobserve(parentOb.ob)
    setParentOb(newParentOb)
  }

  useEffect(() => {
    const props = task.get("props")
    let propsOb
    props.observe(propsOb=e=>{
      if(props.get("isChildTask")){
        const parent = findTaskById(tasksY, props.get('parentTask'))
        let ob
        parent.observe(ob=e=>{
          setParentName(parent.get("name").toString())
        })
        setParentName(parent.get("name").toString())
        updateParentOb({t: parent, ob: ob})
      }
      else{
        updateParentOb({t: null, ob: null})
        setParentName("N/A")
      }

    })
    if(props.get("isChildTask")){
      const parent = findTaskById(tasksY, props.get('parentTask'))
      let ob
      parent.observe(ob=e=>{
        setParentName(parent.get("name").toString())
      })
      setParentName(parent.get("name").toString())
      updateParentOb({t: parent, ob: ob})
    }
    else{
      updateParentOb({t: null, ob: null})
      setParentName("N/A")
    }
    return () => {
      props.unobserve(propsOb)
      parentOb.t&&parentOb.ob&&parentOb.t.unobserve(parentOb.ob)
    }
  }, [])


  return parentName
}

export default ParentName