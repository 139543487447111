import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { GetKanban } from '../../../backend/kanbanService.js'
import KanbanContainer from './KanbanContainer.jsx'
import KanbanEditorLeftMenu from './commonUI/KanbanEditorLeftMenu.jsx'
import KanbanEditorTopBar from './commonUI/KanbanEditorTopBar.jsx'
import ProjectCompletenessBar from './tasks/common/ProjectCompletenessBar.jsx'
import {setHideMenu} from "../../../redux/slices/kanbanSlice.js";
import KanbanEditorLeftMenuMobile from "./commonUI/KanbanEditorLeftMenuMobile.jsx";
import KanbanEditorTopBarMobile from "./commonUI/KanbanEditorTopBarMobile.jsx";
import UndoRedoMobile from "./commonUI/UndoRedoMobile.jsx";
import ShowMenuButtonMobile from "./commonUI/ShowMenuButtonMobile.jsx";
import {checkIsMobileBrowser} from "../../common/Common.jsx";
import OptionEditorMobile from "./commonUI/OptionEditorMobile.jsx";
import KanbanEditorTopAllPhone from "./commonUI/KanbanEditorTopAllPhone.jsx";

const KanbanEditor = (props) => {
  const queryParameters = new URLSearchParams(window.location.search)
  const kanbanId = queryParameters.get('id')
  const [yjsDataSynced, setYjsDataSynced] = useState(false)
  const dispatch = useDispatch()
  const topBarSize = 50
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const hideMenu = useSelector((state) => state.kanban.hideMenu)
  const { children, showEditMenu, selectedIndex, kanEdit, showProjectStats, isKanbanEditor } = props
  const [scale, setScale] = useState(1)

  const isMobileBrowser = checkIsMobileBrowser()
  useEffect(() => {
    dispatch(GetKanban(kanbanId, null, ()=>{}))
    dispatch(setHideMenu(true))
  }, [dispatch, kanbanId])

  useEffect(() => {
    yjsData && yjsData.yDoc.once('beforeObserverCalls', ()=>{
      setYjsDataSynced(true)
    })
  }, [yjsData])



  if (!yjsData)
    return


  if(!yjsDataSynced)
    return


  return (
    <div className={'flex flex-col w-full h-full'}>
      <OptionEditorMobile/>
      <KanbanEditorTopAllPhone scale={scale} setScale={setScale} kanEdit={kanEdit} isMobileBrowser={isMobileBrowser} hideMenu={hideMenu} isKanbanEditor={isKanbanEditor} kanbanId={kanbanId} selectedIndex={selectedIndex}/>
      <div
           className={'flex flex-row w-full h-full'}>

        {!isMobileBrowser && <KanbanEditorLeftMenu selectedIndex={selectedIndex} kanbanId={kanbanId}
                               showEditButton={showEditMenu}/>}
          <div style={{}} className={` h-full w-full overflow-auto ${isMobileBrowser && (selectedIndex!==3 ?"pt-2 pb-16":"pb-11")}`}>
            {children && children}
            {!children &&
              <KanbanContainer kanbanId={kanbanId} scale={scale}/>
            }
          </div>

      </div>
      {showProjectStats && <ProjectCompletenessBar/>}
    </div>

  )
}
export default KanbanEditor