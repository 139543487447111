const CompletenessVisualizator = ({ completenessPercent, completenessWeitght, percentIcon, weightIcon }) => {

  return (<div className={'flex flex-row gap-6 items-center font-["handwritten"] font-bold'}
               style={{ fontSize: '17px' }}>
    <div className={"flex items-center"}>
      <div className={'flex items-center justify-center w-6 h-8'}>
        {percentIcon}
      </div>
      <a>{Math.round(completenessPercent)}%</a>
    </div>
    <div className={"flex items-center"}>
      <div className={'flex items-center justify-center w-6 h-8'}>
        {weightIcon}
      </div>
      <a>{completenessWeitght}</a>
    </div>
  </div>)

}

export default CompletenessVisualizator