import { useEffect, useState } from 'react'
import { deleteTaskCollaborator, setTaskCollaborator } from '../../../../../yjs/functions/YjsCollaboratorFunctions.js'
import useUserNameCache from '../../useUserNameCache.jsx'

 export const AssignedToSelector = ({task, collaboratorsY}) => {
   const [update, setUpdate] = useState(false)
   const [isAssigned, setIsAssigned] = useState(false)
   const [assignedTo, setAssignedTo] = useState(false)
   const [collabList, setCollabList] = useState([])
   const collaboratorsCache = useUserNameCache()

   useEffect(() => {}, [update])
   useEffect(()=>{
     const taskProps = task.get('props')
     let obProps
     taskProps.observe(obProps=x => {
       setUpdate(!update)
       setAssignedTo(taskProps.get('assignedTo'))
       setIsAssigned(taskProps.get('isAssigned'))
     })
     let collabOb
     collaboratorsY.observe(collabOb=e=>{
       setCollabList(collaboratorsY)
     })
     setCollabList(collaboratorsY)
     setAssignedTo(task.get('props').get('assignedTo'))
     setIsAssigned(task.get('props').get('isAssigned'))
     return()=>{
       taskProps.unobserve(obProps)
       collaboratorsY.unobserve(collabOb)
     }

   }, [])

  const onChangeSwitch = (e) => {
    if (Number(e.target.value) === -1) {
      deleteTaskCollaborator(task)
    } else {
      setTaskCollaborator(task, e.target.value, collaboratorsY)
    }
  }
  const SelectOption = ({ i, collaboratorY, collaboratorsCache }) => {
    return <option key={i} value={collaboratorY.get('id')}>{collaboratorsCache[collaboratorY.get('id')] && collaboratorsCache[collaboratorY.get('id')].username}</option>
  }


  return <select className={'bg-color3  w-48  p-1 rounded text-white cursor-pointer'}
                 value={isAssigned ? assignedTo : -1}
                 name="select"
                 onChange={onChangeSwitch}>
    <option value={-1}>Ninguno</option>
    {
      collabList.map((x, i) => {
        return <SelectOption collaboratorsCache={collaboratorsCache} key={x.get('id')} i={i} collaboratorY={x}/>
      })
    }
  </select>
}