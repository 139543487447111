import * as Y from 'yjs'
import { parse as uuidParse } from 'uuid'

export const findCollaboratorIndexById = (collaboratorsY, collaboratorId) => {
  const collaborators = collaboratorsY.toJSON()
  for (let i = 0; i < collaborators.length; i++) {
    if (collaborators[i].id === collaboratorId) {
      return i
    }
  }
  return -1
}

export const addCollaborator = (collaboratorId, collaboratorsY) => {

  try {
    uuidParse(collaboratorId)
  } catch (e) {
    return null
  }
  const collaboratorIndex=findCollaboratorIndexById(collaboratorsY, collaboratorId)
  if(collaboratorIndex!==-1)
    return null
  const newCollaborator = new Y.Map()
  newCollaborator.set('id', collaboratorId)
  collaboratorsY.push([newCollaborator])
  return newCollaborator
}

export const setTaskCollaborator = (taskY, collaboratorId, collaboratorsY)=>{
  const collaboratorIndex=findCollaboratorIndexById(collaboratorsY, collaboratorId)
  if(collaboratorIndex!==-1){
    taskY.get('props').set('isAssigned', true)
    taskY.get('props').set('assignedTo', collaboratorId)
    return true
  }else
    return false
}

export const deleteTaskCollaborator = (taskY)=>{
    taskY.get('props').set('isAssigned', false)
    taskY.get('props').set('assignedTo', "")
    return true
}

export const deleteCollaboratorFromTasks = (tasksY, collaboratorId, collaboratorsY) => {
  const collaboratorIndex=findCollaboratorIndexById(collaboratorsY, collaboratorId)
  if(collaboratorIndex!==-1){
  tasksY.map((task, i) => {
    if (task.get('props').get('isAssigned') && task.get('props').get('assignedTo') === collaboratorId) {
      deleteTaskCollaborator(task)
    }
  })
    return true
  }else{
    return false
  }
}

export const deleteCollaboratorFromKanban = (userId, collaboratorsY, tasksY) => {
  const collaboratorIndex = findCollaboratorIndexById(collaboratorsY, userId)
  deleteCollaboratorFromTasks(tasksY, userId, collaboratorsY)
  collaboratorsY.delete(collaboratorIndex)
}