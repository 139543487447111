import { configureStore} from "@reduxjs/toolkit"
import kanbanReducer from "./slices/kanbanSlice.js"
import userReducer from "./slices/usersSlice.js"
import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist";
import {combineReducers} from "@reduxjs/toolkit";
import thunk from 'redux-thunk'
import storageSession from 'redux-persist/lib/storage/session'


// Aquí van todos los reducers que se quieran guardar en el session storage
const sessionStorageReducers = ['user'];
const sessionStorageConfig = { key: 'root', storage:storageSession, whitelist: sessionStorageReducers};

const rootReducer = combineReducers({
  user: userReducer,
  kanban: kanbanReducer
})

const persistedReducer = persistReducer(sessionStorageConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})