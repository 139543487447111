import { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { updateAverageOfParents } from '../../../../yjs/functions/YjsTasksFunctions.js'

export const CompletenessSelectorValueNote = ({ task, tasksY }) => {
  const [completenessActive, setCompletenessActive] = useState(false)
  const [completenessPercent, setCompletenesPercent] = useState(0)

  useEffect(()=>{
    const props = task.get('props')
    let ob
    props.observe(ob=e=>{
      setCompletenessActive(props.get('completenessActive'))
      setCompletenesPercent(props.get('completenessPercent'))
    })
    setCompletenessActive(props.get('completenessActive'))
    setCompletenesPercent(props.get('completenessPercent'))

    return()=>{
      props.unobserve(ob)
    }
  }, [])

  const values = [
    {name: "0%", value: 0},
    {name: "10%", value: 10},
    {name: "20%", value: 20},
    {name: "30%", value: 30},
    {name: "40%", value: 40},
    {name: "50%", value: 50},
    {name: "60%", value: 60},
    {name: "70%", value: 70},
    {name: "80%", value: 80},
    {name: "90%", value: 90},
    {name: "100%", value: 100},
  ]
  return (
    <div className={'flex flex-col gap-1 text-black '}>

      {completenessActive &&
        <div className={'flex flex-row gap-2'}>
          <select className={"bg-color3 text-white rounded p-1 w-full cursor-pointer"}
            value={completenessPercent}
                  onChange={e=>{
                    task.get("props").set("completenessPercent", e.target.value)
                    updateAverageOfParents(tasksY, task)
                  }
          }>
            {values.map((x,i)=>{return <option key={i} value={x.value}>{x.name}</option>})}
          </select>
        </div>
      }
    </div>
  )
}