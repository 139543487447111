import { useEffect, useState } from 'react'
import { faArrowLeft, faPlus, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const CreateKanbanOptionSelector = ({ options, setSelectInfo })=>{
  const [selected, setSelected] = useState(1)
  const [selecting, setSelecting] = useState(false)

  useEffect(() => {
    setSelectInfo(      {
      selected: 1,
      selecting: false
    })

  }, [])


  const onChangeValue=(newCode)=>{
    setSelected(newCode);
    setSelecting(false)
    setSelectInfo&&setSelectInfo(
      {
        selected: newCode,
        selecting: false
      }
    )
  }

  const onOpenSelect=()=>{
    setSelected(null);
    setSelecting(true)
    setSelectInfo&&setSelectInfo(
      {
        selected: null,
        selecting: true
      }
    )
  }

  const CreateKanbanOptionSelectorOpt = ({option, placeholder })=>{

    return(
      <div onClick={()=>{}}
        className={`${selecting&&`hover:bg-gray-400`} flex overflow-hidden flex-row items-center p-1 rounded bg-color3 cursor-pointer h-20 text-white font-bold`}>
        {!placeholder&&<div className={"flex flex-row gap-2 p-2 h-full w-full items-center "}>
          <div className={"w-28 h-full min-h-full min-w-28"}>
            <img className={"w-full h-full"} src={option.img}/>
          </div>
          <div className={"pl-4 w-[70%] ellipsis flex flex-col h-full justify-center items-center "}>
            <a className={"w-full ellipsis"}>{option.name}</a>
          </div>

        </div>}

        {placeholder&&<div className={"flex items-center gap-1 w-full"}><a>Cargando</a> <FontAwesomeIcon spin={true} icon={faRotateRight} color="white"/></div>}
      </div>
    )
  }
  if(options.length<1)
  return <CreateKanbanOptionSelectorOpt placeholder={true} code={0}/>
  return(
    <div className={"flex flex-col gap-1 w-full"} >
      {selecting && options.map(e=>
        <div key={e.code} onClick={()=>onChangeValue(e.code)}>
          <CreateKanbanOptionSelectorOpt option={e}/>
        </div>

      )}
      {!selecting &&
        <div className={"relative"} onClick={()=>onOpenSelect()}>
          <CreateKanbanOptionSelectorOpt option={options[selected]}/>
          <div className={"cursor-pointer hover:bg-gray-400 flex justify-center items-center absolute rounded-2xl border-2 border-white w-8 h-8 bg-color2 bottom-[-12px] left-1/2 right-1/2"}>
            <FontAwesomeIcon icon={faPlus} color="white" size={"lg"}/>
          </div>
        </div>
        }
    </div>

  )
}

export default CreateKanbanOptionSelector