import { CirclePicker } from 'react-color'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet } from '@fortawesome/free-solid-svg-icons'

const ColorPicker = ({onChangeColor, selectedColor}) => {
  const [showPicker, setShowPicker] = useState(false)
  const colorIcon = <FontAwesomeIcon icon={faDroplet}
                                     color="white"/>
  const colors = [
    "#36393e",
    "#4a6000",
    "#007235",
    "#006572",
    "#051756",
    "#3f0157",
    "#720069",
    "#3e4859",
  ]
  return (
    <div className={""}>

        <div onClick={() => {setShowPicker(!showPicker)}}
             className={'w-[30px] h-[30px] bg-color4 rounded-xl flex flex-col justify-center items-center gap-[3px] cursor-pointer hover:bg-orange-500'}>
          {colorIcon}
        </div>

      {showPicker&&<div className={'absolute bg-color4 p-2 rounded-2xl z-50'}
                        style={{ left: -35, top: 35 }}>
        <CirclePicker
          width={"170px"}
          colors={colors}
          color={selectedColor}
          onChange={(col)=>{
            onChangeColor&&onChangeColor(col)
            setShowPicker(false)
          }}
        />
      </div>}

    </div>
  )
}
export default ColorPicker