import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo, faUndo} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setUndoAndRedoCount} from "../../../../redux/slices/kanbanSlice.js";

const UndoRedoMobile = ({scale, setScale}) =>{
    const yjsData = useSelector((state) => state.kanban.yjsData)

    const undoIcon = <FontAwesomeIcon size={"2xl"} icon={faUndo} color={yjsData.undoCount > 0 ? 'white' : 'grey'}/>
    const redoIcon = <FontAwesomeIcon size={"2xl"} icon={faRedo} color={yjsData.redoCount > 0 ? 'white' : 'grey'}/>
    const dispatch = useDispatch()
    useEffect(() => {
        const setUndoAndRedo = () => {
            dispatch(setUndoAndRedoCount({
                undoCount:yjsData.undoManager.undoStack.length,
                redoCount:yjsData.undoManager.redoStack.length,
            }))
        }
        yjsData.undoManager.on('stack-item-added', () => {
            setUndoAndRedo()
        })
        yjsData.undoManager.on('stack-item-popped', () => {
            setUndoAndRedo()
        })
        setUndoAndRedo()

    }, [])

    return(
        <div className={"w-fit h-8  z-[10000] flex gap-2"}>
            <button style={{cursor: yjsData.undoManager.undoStack.length > 0 ? "pointer" : "auto"}}
                    className={'cursor-auto'} onClick={() => {
                yjsData.undoManager.undo()
            }}>{undoIcon}</button>
            <button style={{cursor: yjsData.undoManager.redoStack.length > 0 ? "pointer" : "auto"}}
                    className={'cursor-auto'} onClick={() => {
                yjsData.undoManager.redo()
            }}>{redoIcon}</button>

        </div>
    )
}

export default UndoRedoMobile