
const KanbanSizeController = ({kanbanPropsY}) =>{

  return(
    <div className={"flex flex-col gap-2 text-white rounded-2xl"}>
      <div className={"flex flex-row gap-2 p-2 justify-between"}>
        <a>Ancho</a>
        <input className={"w-48"} type={"range"} min={700} max={4000} value={kanbanPropsY.get("width")}
        onChange={e=>kanbanPropsY.set("width", Number(e.target.value))}/>
      </div>
      <div className={"flex flex-row justify-between gap-2 p-2"}>
        <a>Alto</a>
        <input className={"w-48"} type={"range"} min={400} max={4000}
               value={kanbanPropsY.get("height")}
               onChange={e=>kanbanPropsY.set("height", Number(e.target.value))}
        />
      </div>
    </div>
  )
}

export default KanbanSizeController