import { useEffect, useState } from 'react'
import {
  findTaskById,
  getAveragePercentFromChilds,
  getDirectChilds,
  hasChilds
} from '../../../../../yjs/functions/YjsTasksFunctions.js'
import { AssignedToSelector } from './AssignedToSelector.jsx'
import { CompletenessSelector } from './CompletenessSelector.jsx'
import { ParentSelector } from './ParentSelector.jsx'
import { useDispatch } from 'react-redux'
import { getUserPublicInfo } from '../../../../../backend/userService.js'
import CompletenessWeightSelectorNote from '../../notes/CompletenessWeightSelectorNote.jsx'
import YjsTextArea from '../../awareness/yjsText/YjsTextArea.jsx'
import YjsInput from '../../awareness/yjsText/YjsInput.jsx'
import Fields from './Fields.jsx'
import WeightVisualizator from '../../notes/WeightVisualizator.jsx'

export const ExpandedRowContent = ({ task, tasksY, collaboratorsY, setAssignedUserInfo }) => {

  const PropsEditor = ({ task }) => {

    const [hasCh, sethasCh] = useState(hasChilds(task, tasksY))
    const [activeParentChilds, setActiveParentChilds] = useState(0)
    const [isChildTask, setIsChildTask] = useState(false)
    const [completenessActive, setCompletenessActive] = useState(false)
    const taskProps = task.get('props')
    if (!taskProps)
      return
    const updateActiveParentChilds = () => {
      let parentActivePercentChilds = 0
      if (isChildTask) {
        const parent = findTaskById(tasksY, taskProps.get('parentTask'))
        const parentChilds = getDirectChilds(parent, tasksY)
        let activeChildCounter = 0
        parentChilds.map(x => {
          if (x.get('props').get('completenessActive'))
            activeChildCounter++
        })
        parentActivePercentChilds = activeChildCounter
      }
      setActiveParentChilds(parentActivePercentChilds)
    }

    useEffect(() => {
      sethasCh(hasChilds(task, tasksY))
      task.get('props').observe(e => {
        sethasCh(hasChilds(task, tasksY))
      })

      let tasksYOb = []
      tasksY.map(x => {
        let obv
        x.get('props').observe(obv = e => {
          updateActiveParentChilds()
          sethasCh(hasChilds(task, tasksY))
        })
        tasksYOb.push({ task: x.get('props'), ob: obv })
      })
      updateActiveParentChilds()
      let tOb
      taskProps.observe(tOb = e => {
        setIsChildTask(taskProps.get('isChildTask'))
        setCompletenessActive(taskProps.get('completenessActive'))
      })
      setIsChildTask(taskProps.get('isChildTask'))
      setCompletenessActive(taskProps.get('completenessActive'))
      return () => {
        tasksYOb.map(x => {x['task'].unobserve(x['ob'])})
        taskProps.unobserve(tOb)
      }

    }, [])

    const ParentPercent = ({ task }) => {
      const [completenessPercent, setCompletenessPercent] = useState(0)
      const averageIni = !hasChilds(task, tasksY) ? 0 : getAveragePercentFromChilds(getDirectChilds(task, tasksY))
      const [average, setAverage] = useState(averageIni)
      const taskProps = task.get('props')
      useEffect(() => {
        let ob
        taskProps.observe(ob = e => {
          setCompletenessPercent(taskProps.get('completenessPercent'))
        })
        setCompletenessPercent(taskProps.get('completenessPercent'))
        return () => {
          taskProps.unobserve(ob)
        }
      }, [])

      return (
        <a
          className={'text-lg'}>Porcentaje: {average === -1 ? 'N/A' : (completenessPercent.toString().slice(0, 4)) + '%'}</a>
      )
    }

    return (
      <div id={'props'} className={'flex flex-col sm:flex-row justify-between'}>
        <div className={'flex flex-col gap-2 ellipsis'}>
          {!hasCh && <div id={'assignedTo'} className={'flex flex-col gap-1'}>
            <a className={'text-lg'}>Asignado a:</a>
            <AssignedToSelector task={task} collaboratorsY={collaboratorsY}/>
          </div>}
          <div id={'completeness'} className={'flex flex-col gap-1'}>
            <a className={'text-lg'}>Completado: </a>
            {hasCh ? <ParentPercent task={task}/> :
              <CompletenessSelector task={task} tasksY={tasksY}/>}
            {completenessActive &&
              <div className={'flex flewx-row gap-2'}>
                <a>Peso: </a>
                {hasCh?<WeightVisualizator yTask={task}/>:<CompletenessWeightSelectorNote task={task} tasksY={tasksY}/>}
              </div>
            }
          </div>
        </div>

        <div id={'parent'} className={'flex flex-col gap-1'}>
          <a className={'text-lg'}>Tarea Padre: </a>
          <ParentSelector tasksY={tasksY} task={task} setAssignedUserInfo={setAssignedUserInfo}/>
        </div>
      </div>
    )
  }

  const ChildRow = ({ task, padd }) => {
    const [assignedUserInfo, setAssignedUserInfo] = useState(null)
    const dispatch = useDispatch()
    const [thisTaskChilds, setThisTaskChilds] = useState([])

    useEffect(() => {
      if (task.get('props').get('isAssigned'))
        dispatch(getUserPublicInfo(task.get('props').get('assignedTo'), (data) => {setAssignedUserInfo(data)}))
      let ob
      tasksY.observeDeep(ob = e => {
        setThisTaskChilds(getDirectChilds(task, tasksY))
      })
      setThisTaskChilds(getDirectChilds(task, tasksY))
      return () => {
        tasksY.unobserveDeep(ob)
      }
    }, [])

    return (
      <div className={'flex flex-col w-full max-h-64'} style={{ paddingLeft: padd, paddingRight: 10 }}>
        <div className={'flex'}>
          <div className={'flex gap-1 whitespace-nowrap'}>
            <a>{task.get('props').get('completenessActive') ? Math.round(task.get('props').get('completenessPercent')) + '% P:' + Math.round(task.get('props').get('completenessWeight')) : 'N/A'} </a>
            <a>{'-'}</a>
            <a >{task.get('name').toString()}</a>
            <a>{task.get('props').get('isAssigned') && assignedUserInfo && ('[' + assignedUserInfo.name + ']')}</a>
          </div>
        </div>
        {thisTaskChilds.map((x, i) => {
          return <ChildRow key={i} task={x} padd={padd + 30}/>
        })}
      </div>

    )
  }

  const Childrens = ({ task, tasksY }) => {

    const [thisTaskChilds, setThisTaskChilds] = useState(getDirectChilds(task, tasksY))
    useEffect(() => {
      let ob
      tasksY.observe(ob = e => {
        let o = []
        getDirectChilds(task, tasksY).map((x, i) => o.push())
        setThisTaskChilds(getDirectChilds(task, tasksY))
      })
      setThisTaskChilds(getDirectChilds(task, tasksY))
      return () => {
        tasksY.unobserve(ob)
      }
    }, [])

    return (
      <div>
        {thisTaskChilds.length > 0 && <a className={'text-white font-bold text-lg pl-2'}>{'Hijos: '}</a>}
        <div className={'w-full flex p-2 text-white text-lg'}>
          <div className={'overflow-scroll flex-col rounded-2xl w-full bg-color3'}>

            {thisTaskChilds.map((x, i) => {
              return <ChildRow key={i} task={x} padd={10}/>
            })}
          </div>
        </div>
      </div>

    )
  }

  return (
    <div className={'p-2'}>
      <div className={'w-full h-full bg-color4 rounded-2xl flex flex-col gap-2'}>
        <div className={'flex flex-col justify-between text-white p-4 gap-2 w-full '}>
          <div id={'name'} className={'w-full flex flex-row items-center font-bold text-xl gap-2'}>
            <a>Nombre: </a>

              <YjsInput
                id={task.get('id') + 'name'}
                field={task.get('name')}
                className={'bg-color3  rounded w-full'}
              />


          </div>
          <PropsEditor task={task}/>
        </div>
        <div id={'description'} className={'flex flex-col gap-1'}>
          <a className={'text-white font-bold text-lg pl-2'}>{'Descripción: '}</a>
          <div className={'relative h-24 w-full'}>
            <div className={'absolute h-full w-full pl-2 pr-2'}>
              <YjsTextArea
                id={task.get('id') + 'desc'}
                field={task.get('description')}
                className={'resize-none w-full bg-color3 text-white rounded-2xl p-2 h-full z-100'}
              />
            </div>
          </div>
        </div>
        <Fields taskY={task}/>
        {<div id={'childs'} className={'flex flex-col gap-1'}>
          <Childrens task={task} tasksY={tasksY}/>
        </div>}
      </div>
    </div>
  )
}