import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faRedo} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {setHideMenu} from "../../../../redux/slices/kanbanSlice.js";

const ShowMenuButtonMobile = () =>{
    const menuIcon = <FontAwesomeIcon size={"xl"} icon={faBars} color={'white' }/>
    const dispatch = useDispatch()
    return(
        <div className={"absolute top-2 left-4 p-2 bg-red-500 z-[10000] bg-color2 rounded border-2 border-white "}
        onClick={()=>{dispatch(setHideMenu(false))}}>
            {menuIcon}
        </div>
    )
}

export default ShowMenuButtonMobile