import {useForm} from "react-hook-form"
import "../../../../../tailwind/tailwind.css"
import {login} from "../../../../backend/userService.js"
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InputField from "../common/FormInput.jsx"
import useGoToUrl from '../../../common/useGoToUrl.jsx'
import {useAuthContext} from "../AuthPanelContext.jsx";

const LoginForm = ({urlToRedirectAfterLogin}) => {
    const authContext = useAuthContext()
    const {isReAuth, setIsReauth} = authContext
    const dispatch = useDispatch()
    const [badLogin, setBadLogin] = useState(false)
    const navigate = useGoToUrl()
    const [reAuthUrl, setReAuthUrl] = useState("")
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm()
    useEffect(() => {
        setBadLogin(false)
    }, [watch("username"), watch("pass")])
    useEffect(()=>{
        setReAuthUrl(window.location.search.substring(6))
    }, [])

    const onSubmit = (data) => {
        dispatch(login(data.username.toLowerCase().trim(), data.pass, data.remember, () => {
          navigate(isReAuth?reAuthUrl:"/", {replace: true})
        }, () => {
            setBadLogin(true)
        }));
    }

    return (
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <InputField label={"Usuario o Correo"} borderCond={errors.username} registerCallBack={"username"}
                                errorsAndConds={[{cond: errors.username, text: "Introduce tu nombre de usuario/mail."}]}
                                register={register}></InputField>
                    <InputField label={"Contraseña"} registerCallBack={"pass"} borderCond={errors.pass}
                                errorsAndConds={[{cond: errors.pass, text: "Introduce tu contraseña."}]}
                                register={register} isPass={true}></InputField>
                    <div className="mb-3">
                        <div className="mb-2">
                            <div id="ValidationRow"
                                 className={"animate-pulse ml-2 flex content-center gap-2 " + ((badLogin && !errors.pass && !errors.username) ? "visible" : "invisible")}>
                                <FontAwesomeIcon icon={faCircleExclamation} color="red"/>
                                <a className={"text-red-500 text-xs italic"}>Usuario/Contraseña erroneos</a>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="flex flex-row justify-start items-center gap-2"
                             onClick={() => {
                             }}>
                            <input className="w-5 h-5 ml-2 leading-tight" type="checkbox" id="remember"
                                   name="remember"
                                   {...register("remember")} defaultChecked={true}></input>
                            <label className="block text-white text-sm font-bold" htmlFor="remember">
                                Recuérdame
                            </label>
                        </div>
                    </div>

                    <div className="flex flex-row justify-end">
                        <button type="submit" className="bg-color1 rounded px-2 pt-1 mb-4"><p className="block
                            text-white text-sm font-bold mb-2">Iniciar Sesión</p></button>
                    </div>
                    <div className="mb-0 flex flex-row justify-end">
                        <a onClick={()=>{setIsReauth(false); navigate('/recoverAccount')}}
                           className="mb-4 flex flex-row justify-end cursor-pointer text-white text-sm font-bold hover:underline">¿Olvidaste
                            tu
                            cuenta?</a>
                    </div>
                    <div className="mb-0">
                        <p className="text-white">¿Todavía no tienes una cuenta?</p>
                        <div className="mb-4 flex flex-row justify-end cursor-pointer" onClick={()=>{setIsReauth(false); navigate('/register')}}>
                            <a className="block text-white text-sm font-bold hover:underline cursor-pointer">Registrate.</a>
                        </div>
                    </div>
                </form>
    )
}

export default LoginForm