import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Pointer from './Pointer.jsx'

const CursorOverlay = ({ scale}) => {
  const extraBorder = { x: 200, y:350 }
  const user = useSelector((state) => state.user.userInfo)
  const [awarenessStates, setAwarenessStatess] = useState([])
  const ref=useRef()
  const [clientId, setClientId] = useState(0)
  const userColor = useSelector((state) => state.user.color)
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const providerY = yjsData.provider



  const onMouseMove = (e) => {
    if( !ref.current) {
      window.removeEventListener("mousemove", onMouseMove)
      return
    }

    var rect = ref.current.getBoundingClientRect()
    const coordsStartPoint = { x: rect.left, y: rect.top }


    const position = {
      x: e.clientX-coordsStartPoint.x,
      y: e.clientY-coordsStartPoint.y
    }
    setClientId(providerY.awareness.clientID)
    providerY.setAwarenessField('user', {
      name: user.name,
      username: user.username,
      color: user.color,
      mouseX: position.x/scale,
      mouseY: position.y/scale,
      defaultimg: user.defaultimg,
      imgdata: user.imgdata,
      date: new Date().getTime()
    })

  }

  const filterAwarenessStates = (awarenessStates) =>{
    const map = new Map();
    for (const obj of awarenessStates) {
      if (obj.user && (!map.has(obj.user.username) || map.get(obj.user.username).user.date < obj.user.date)) {
        map.set(obj.user.username, obj);
      }
    }

    return Array.from(map.values());


  }

  useEffect(() => {
    window.removeEventListener("mousemove", onMouseMove)
    window.addEventListener("mousemove", onMouseMove)
    providerY.on('awarenessUpdate', ({ states }) => {
      setAwarenessStatess(filterAwarenessStates(states))
      //setAwarenessStatess(states)
    })
    return(
        ()=>window.removeEventListener("mousemove", onMouseMove)
    )

  }, [scale])


  return (
    <div ref={ref} id={'cursor-overlay'} className={'absolute top-0 left-0 bg-color2 w-0 h-0 z-50'}>
      {awarenessStates.map((x, i) => {
        return x.user && clientId!==x.clientId && <Pointer scale={scale} key={i} awarenessInfo={x}/>
      })}
    </div>
  )
}

export default CursorOverlay