import {createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import * as Y from 'yjs'

export const kanbanSlice = createSlice({
    name: 'kanban',
    initialState: {
        kanban: null,
        hideMenu: false,
    },
    reducers: {
        setHideMenu: (state, action) => {
            state.hideMenu = action.payload
        },
        set: (state, action) => {
            state.kanban = action.payload
        },
        clear: (state, action) => {
            state.kanban = null
        },
        setUndoAndRedoCount: (state, action)=>{
            state.yjsData.undoCount=action.payload.undoCount
            state.yjsData.redoCount=action.payload.redoCount
        },
        setYDoc: (state, action)=>{
            const provider = action.payload.provider
            const yDoc = provider.document
            const panelsDataY = yDoc.getMap('mainMap')
            const kanbanPropsY = yDoc.getMap('props')
            const tasksY = yDoc.getArray('tasks')
            const collaboratorsY = yDoc.getArray('collaborators')
            const undoManager = new Y.UndoManager([kanbanPropsY, panelsDataY, tasksY])
            const yjsData = {
                yDoc: yDoc,
                provider: provider,
                kanbanY: panelsDataY,
                kanbanPropsY: kanbanPropsY,
                tasksY: tasksY,
                collaboratorsY: collaboratorsY,
                undoManager: undoManager,
                undoCount: 0,
                redoCount: 0
            }
            state.yjsData = yjsData
            
        },
    }
})

export const {setHideMenu, set, setUndoAndRedoCount, clear, setYDoc}=kanbanSlice.actions
export default kanbanSlice.reducer