import React, {useState, useContext} from "react";

const authContext = React.createContext(null)
export const useAuthContext = ()=> useContext(authContext)

const AuthPanelContext = (props) =>{
    const {children} = props

    const [isReAuth, setIsReAuth] = useState(false)
    let setIsReauth = (x)=>setIsReAuth(x)
    return(
        <authContext.Provider value={{isReAuth, setIsReauth}}>
            {children}
        </authContext.Provider>
    )
}

export default AuthPanelContext