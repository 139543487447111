import CenterCard from '../appInterface/components/CenterCard.jsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  DeleteKanban,
  GetCollaboratorKanbans,
  GetUserAndCollaboratorKanbans,
  GetUserKanbans
} from '../../backend/kanbanService.js'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight, faMinus,
  faPlus,
  faRotateRight,
  faSortDown,
  faSortUp, faTrash
} from '@fortawesome/free-solid-svg-icons'
import { clear } from '../../redux/slices/kanbanSlice.js'
import useGoToUrl from '../common/useGoToUrl.jsx'

const MyKanbansListRow = ({ rowHeight, kanban, searchProps, setSearchProps, totalKanbans }) => {
  const dispatch = useDispatch()
  const navigate = useGoToUrl()
  const user = useSelector((state) => state.user.userInfo)
  return (
    <div style={{height: rowHeight}} className={'items-center flex w-full relative hover:bg-gray-500 rounded-2xl bg-color3 hover:bg-gray-500'}>
      <div
        className={'pl-3 h-full text-white flex  cursor-pointer w-5/6  rounded-l-2xl'}
        onClick={() => {
          dispatch(clear())
          navigate('/kanbanB?id=' + kanban.id)
        }}>
        <div className={'ellipsis font-bold w-2/3'}>
          {kanban.name}
        </div>
        <div className={"ellipsis w-1/3"}>
          {new Date(Date.parse(kanban.creationdate)).toLocaleDateString('es-Es', {
            weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
          })}
        </div>
      </div>
      {kanban.owner===user.id&& <button onClick={(e) => {
        const backPageCond = (searchProps.page + 1) * searchProps.pageSize >= (totalKanbans - 1 + searchProps.pageSize) && searchProps.page > 0
        const newPage = backPageCond ? searchProps.page - 1 : searchProps.page
        dispatch(DeleteKanban(kanban.id, () => {
          setSearchProps({ ...searchProps, page: newPage })
        }))
      }}
               className={'absolute right-0 h-full bg-color4 hover:bg-orange-500 rounded-r-2xl text-white text-sm font-bold pl-3 pr-3 pt-1 p-1 min-w-1/6 ellipsis'}>
        <a className={'hidden sm:inline'}>Eliminar</a>
        <FontAwesomeIcon className={'sm:hidden inline'} icon={faTrash} color="white" size={'lg'}/>
      </button>}
    </div>)
}

const RowSpaceKeeper=({rowHeight})=>{
  return(
    <div style={{height: rowHeight}} className={'items-center flex w-full'}>
    </div>
  )
}

const MyKanbansList = ({ kanbans, searchProps, setSearchProps, totalKanbans }) => {
  const headerClass = 'pl-2 text-white sm:text-lg font-bold'
  const dispatch = useDispatch()
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [asc, setAsc] = useState(true)
  const HeaderField = ({ name, orderFieldName, index }) => {
    const classes = selectedIndex === index ? 'underline' : 'cursor-pointer'
    return (<div className={'flex flex-row gap-2 items-baseline'}>
        <a className={`${classes} break-keep whitespace-nowrap  ellipsis`}
           onClick={() => {
             setSelectedIndex(index)
             setAsc(false)
             setSearchProps({
               ...searchProps, orderField: orderFieldName, orderDir: -1
             })
           }}>{name}</a>
        {selectedIndex === index && asc &&
          <div className={'cursor-pointer flex flex-col justify-end'} onClick={() => {
            setAsc(false)
            setSearchProps({
              ...searchProps, orderDir: -1
            })
          }}>
            <FontAwesomeIcon icon={faSortUp} color="white" size="sm"/>
          </div>}
        {selectedIndex === index && !asc &&
          <div className={'cursor-pointer flex flex-col justify-start'} onClick={() => {
            setAsc(true)
            setSearchProps({
              ...searchProps, orderDir: 1
            })
          }}>
            <FontAwesomeIcon icon={faSortDown} color="white" size="sm"/>
          </div>}
      </div>

    )
  }
  const rowHeight = 30;
  return (<div className={'text-left flex flex-col w-full gap-1'}>
    <div className={'flex w-full'}>
      <div className={"flex w-full justify-between pr-[6%]"}>
        <div className={'pl-2 text-white sm:text-lg font-bold ellipsis'}>
          <HeaderField name={'Nombre'} orderFieldName={'name'} index={0}/>
        </div>
        <div className={'pl-2 text-white sm:text-lg font-bold ellipsis'}>
          <HeaderField name={'Fecha de Creación'} orderFieldName={'creationdate'} index={1}/>
        </div>
      </div>
      <div className={' pl-8 min-w-1/6 h-8'}>
      </div>
    </div>


    {kanbans.kanbans.map(x => <MyKanbansListRow rowHeight={rowHeight} key={x.id} kanban={x} searchProps={searchProps}
                                                setSearchProps={setSearchProps} totalKanbans={totalKanbans}/>)}
    {[...Array(searchProps.pageSize-kanbans.kanbans.length)].map((e, i) =>
      <RowSpaceKeeper key={i} rowHeight={rowHeight}/>)}
  </div>)
}

const MyKanbans = () => {
  const dispatch = useDispatch()
  const [kanbans, setKanbans] = useState(null)
  const [reloading, setReloading] = useState(false)
  const navigate = useGoToUrl()
  const addIcon = <FontAwesomeIcon icon={faPlus} color="white" size="sm"/>
  const [actPage, setActPage] = useState(1)
  const [searchProps, setSearchProps] = useState({
    orderField: 'creationdate', orderDir: -1, page: actPage - 1, pageSize: 5, searchType: 'all', onSuccess: (x) => {
      setKanbans(x)
    }
  })
  const searchTypeButtonsNSClass = 'pl-3 pr-3 font-bold text-sm bg-color3 hover:bg-orange-500'
  const searchTypeButtonsSClass = 'pl-3 pr-3 font-bold text-sm bg-color1 hover:bg-orange-500'

  const doSearch = (onSuccess) =>{
    if (searchProps.searchType === 'my')
      dispatch(GetUserKanbans({ ...searchProps, onSuccess: onSuccess&&onSuccess }))
    else if (searchProps.searchType === 'collab')
      dispatch(GetCollaboratorKanbans({ ...searchProps, onSuccess: onSuccess&&onSuccess }))
    else if (searchProps.searchType === 'all')
      dispatch(GetUserAndCollaboratorKanbans({ ...searchProps, onSuccess: onSuccess&&onSuccess }))
    else
      dispatch(GetUserKanbans({ ...searchProps, onSuccess: onSuccess&&onSuccess }))
  }

  useEffect(() => {
    doSearch((x) => {
      setKanbans(x)
    })
  }, [searchProps])
  if (!kanbans) return
  const totalPages = Math.ceil(kanbans.totalitems / searchProps.pageSize)
  return (
    <div className={'w-full h-full p-2'}>
      <CenterCard extraClasses={'sm:w-[600px]'}>
        <div className={'flex flex-col justify-center items-center w-full gap-4'}>
          <div className={'flex flex-row justify-between w-full'}>
            <div className={'flex flex-row gap-2'}>
              <a className={'text-2xl text-white font-bold'}>Mis Tableros:</a>

            </div>
            <button className={'pl-3 pr-3 font-bold rounded-2xl text-sm bg-color3 hover:bg-orange-500'}
                    onClick={() => {
                      navigate('/kanbans/create')
                    }}>{addIcon} <a className={'text-white'}>Crear</a>
            </button>
          </div>
          <div className={'flex flex-row w-full h-fit justify-between'}>
            <div className={'flex flex-row h-8'}>
              <button
                className={(searchProps.searchType === 'my' ? searchTypeButtonsSClass : searchTypeButtonsNSClass) + ' rounded-l-2xl '}
                onClick={() => {
                  setSearchProps({ ...searchProps, searchType: 'my', page: 0, orderField: 'name', orderDir: -1 })
                }}><a className={'text-white'}>Mios</a>
              </button>
              <button className={searchProps.searchType === 'all' ? searchTypeButtonsSClass : searchTypeButtonsNSClass}
                      onClick={() => {
                        setSearchProps({ ...searchProps, searchType: 'all', page: 0, orderField: 'name', orderDir: -1 })
                      }}><a className={'text-white'}>Todos</a>
              </button>
              <button
                className={(searchProps.searchType === 'collab' ? searchTypeButtonsSClass : searchTypeButtonsNSClass) + ' rounded-r-2xl '}
                onClick={() => {
                  setSearchProps({ ...searchProps, searchType: 'collab', page: 0, orderField: 'name', orderDir: -1 })
                }}><a className={'text-white'}>Compartidos</a>
              </button>
            </div>
            <div className={'pr-2'}>
              <FontAwesomeIcon spin={reloading} className={'cursor-pointer'} icon={faRotateRight} color="white"
                               onClick={
                                 () => {
                                   setReloading(true)
                                   doSearch((x)=>{
                                     setKanbans(x)
                                     new Promise(resolve => setTimeout(resolve, 500)).then(r => setReloading(false));
                                   })
                                 }}/>
            </div>
          </div>
          {kanbans.totalitems === 0 &&
            <div className={'w-full h-full text-white'}>
              <a>No se ha encontrado ningún tablero.</a>
            </div>
          }
          {kanbans.totalitems > 0 &&
            <MyKanbansList kanbans={kanbans}
                           searchProps={searchProps} setSearchProps={setSearchProps}
                           totalKanbans={kanbans.totalitems}/>}
          <div className={'w-full flex flex-row justify-end'}>
            {kanbans.totalitems > 0 &&
              <div className={'items-center relative w-[80px]'}>
                {actPage > 1 &&
                  <div className={'cursor-pointer h-full flex-col justify-end absolute left-0 top-0'} onClick={() => {
                    setActPage(actPage + -1)
                    setSearchProps({ ...searchProps, page: searchProps.page - 1 })
                  }}>
                    <FontAwesomeIcon icon={faArrowLeft} color="white"/>
                  </div>}
                <p
                  className={'text-white text-center align-middle'}>{searchProps.page + 1}/{Math.floor(totalPages)}</p>
                {actPage < totalPages &&
                  <div className={'cursor-pointer h-full flex-col justify-end  absolute right-0 top-0'} onClick={() => {
                    setActPage(actPage + 1)
                    setSearchProps({ ...searchProps, page: searchProps.page + 1 })
                  }}>
                    <FontAwesomeIcon icon={faArrowRight} color="white"/>
                  </div>}
              </div>}
          </div>
        </div>

      </CenterCard>
    </div>
  )
}

export default MyKanbans