import { useEffect, useState } from 'react'
import Collaborator from './Collaborator.jsx'
import {useDispatch, useSelector} from 'react-redux'
import TaskRow from '../taskRows/TaskRow.jsx'
import CenterCard from '../../../../appInterface/components/CenterCard.jsx'
import {setHideMenu} from "../../../../../redux/slices/kanbanSlice.js";

const TasksByCollaborator = () =>{
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const collaboratorsY=yjsData.collaboratorsY
  const tasksY=yjsData.tasksY
  const [collabArray, setCollabArray] = useState([])
  const [allTasks, setAllTasks] = useState([])
    const dispatch = useDispatch()

  useEffect(()=>{
    let ob
    collaboratorsY.observe(ob=e=>{
      let newArr = []
      collaboratorsY.map(x=>newArr.push(x))
      setCollabArray(newArr)
    })
    let newArr = []
    collaboratorsY.map(x=>newArr.push(x))
    setCollabArray(newArr)

    tasksY.observe(e=> {
        setAllTasks(
          tasksY.map((x, i) => {
            return <TaskRow
              indexInCategory={0} index={i} key={x.get("id")}
              task={x} tasksY={tasksY} collaboratorsY={collaboratorsY}
            />
          })
        )
      }
    )
    setAllTasks(
      tasksY.map((x, i) => {
        return <TaskRow
          indexInCategory={0} index={i} key={x.get("id")}
          task={x} tasksY={tasksY} collaboratorsY={collaboratorsY}
        />
      })
    )
      dispatch(setHideMenu(false))
    return()=>{
      collaboratorsY.unobserve(ob)
    }
  }, [])

  return(
    <CenterCard color={"ad"} extraClasses={"md:w-[700px] w-full mb-8"}>
      <div className={"flex flex-col gap-4"}>
        {collabArray.length>0?collabArray.map(x=>{
          return <Collaborator collaboratorY={x} key={x.get("id")} allTasks={allTasks} tasksY={tasksY}/>
        }):
        <a className={"text-white"}>Todavía no has añadido colaboradores a este tablero.</a>}
      </div>


    </CenterCard>
  )

}

export default TasksByCollaborator