import { useEffect, useReducer, useState } from 'react'
import KanbanSizeController from '../kanbanPanels/KanbanSizeController.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faListCheck,
  faPenToSquare,
  faProjectDiagram,
  faTable,
  faUsers,
  faX
} from '@fortawesome/free-solid-svg-icons'
import { ValidatePanel } from '../../../users/AuthPanel/common/auxComponents.jsx'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setUndoAndRedoCount } from '../../../../redux/slices/kanbanSlice.js'
import useGoToUrl from '../../../common/useGoToUrl.jsx'

const kanbanIcon = <FontAwesomeIcon icon={faTable} color="white" size={'2xl'}/>
const editIcon = <FontAwesomeIcon icon={faPenToSquare} color="white"
                                  size={'sm'}/>
const applyIcon = <FontAwesomeIcon icon={faCheck} color="white" size="sm"/>
const closeIcon = <FontAwesomeIcon icon={faX} color="white" size="sm"/>
const usersIcon = <FontAwesomeIcon icon={faUsers} color="white" size="2xl"/>
const taskIcon = <FontAwesomeIcon icon={faListCheck} color="white" size="2xl"/>
const graphIcon = <FontAwesomeIcon icon={faProjectDiagram} color="white" size="2xl"/>

export const MenuElement = ({ icon, url, index, selectedIndex }) => {
  const navigate = useGoToUrl()
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const dispatch = useDispatch()
  const classSelected = 'rounded-2xl flex justify-center items-center ' +
    'hover:bg-orange-500 cursor-pointer bg-color1'
  const classNotSelected = 'rounded-2xl flex justify-center items-center ' +
    'hover:bg-orange-500 cursor-pointer bg-color3'
  return (
    <div style={{ width: '60px', height: '60px' }}
         onClick={() => {
           dispatch(setUndoAndRedoCount({
             undoCount:0,
             redoCount:0,
           }))
           yjsData.undoManager.clear()
           navigate(url)
         }}
         className={`${index === selectedIndex ? classSelected : classNotSelected}`}>
      {icon}
    </div>
  )
}

export const KanbanPropsEditor = ({ propsY, setEditPanel }) => {
  const [kanbanName, setKanbanName] = useState(propsY.get('name').toString())
  const [editName, setEditName] = useState(false)
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {propsY.observe(e => {forceUpdate()})}, [])

  return (
    <div
      className={'z-50  bg-color3 rounded-r-2xl flex flex-col w-72'}>
      <div className={'flex flex-row justify-end p-2'}>
        <div id={'backB'}
             className={'cursor-pointer flex justify-center items-center'}
             style={{ width: '20px', height: '20px' }}
             onClick={() => {setEditPanel(false)}}>
          {closeIcon}
        </div>
      </div>
      <div className={'flex flex-col gap-4 pl-2'}>
        <div className={'flex flex-row'}>
          {editName && <div>
            <form onSubmit={(e) => {
              event.preventDefault()
              propsY.set('name', kanbanName)
              setEditName(false)
            }}>
              <div className={'flex flex-col'}>
                <div className={'flex  gap-1 text-white pl-2 pr-2'}>
                  <a className={''}>Nombre: </a>
                  <input className={'bg-color4 w-full'} value={kanbanName}
                         onChange={(e) =>
                           setKanbanName(e.target.value)
                         }/>
                  <button type={'submit'}>
                    <div
                      className={'cursor-pointer bg-color3 w-6 h-6 rounded flex justify-center items-center'}> {applyIcon} </div>
                  </button>
                </div>
                <ValidatePanel conditions={[
                  {
                    condition: kanbanName.length < 1,
                    text: 'Debes introducir un nombre válido.',
                    icon: editIcon,
                  },
                ]}/>
              </div>

            </form>
          </div>}
          {!editName &&
            <div className={'gap-2 flex'}>
              <div className={'gap-2 pl-2 flex flex-wrap'}>
                <a className={'text-white'}>Nombre: {
                  propsY.get('name').toString().length < 15 ? propsY.get('name') : propsY.get('name').substring(0, 14) + '...'
                }</a>
              </div>

              <div className={'bg-color3 w-6 h-6 rounded flex justify-center items-center cursor-pointer '}
                   onClick={() => {setEditName(true)}}> {editIcon} </div>
            </div>}

        </div>

        <KanbanSizeController kanbanPropsY={propsY}/>
      </div>
    </div>
  )
}

const KanbanEditorLeftMenu = ({ kanbanId, showEditButton, selectedIndex }) => {
  const [editPanel, setEditPanel] = useState(false)
  const yjsData = useSelector((state) => state.kanban.yjsData)
  return (
    <div className={'flex w-fit'}>
      <div style={{minHeight: `calc(100vh - ${98}px)`, width: "80px"}} className={'bg-color4 h-full flex  flex-col gap-2 p-2'}>

          <MenuElement selectedIndex={selectedIndex} index={0} icon={<div className={"hover:animate-pulse"}>{kanbanIcon}</div>} url={'/kanbanB?id=' + kanbanId}/>

        <MenuElement selectedIndex={selectedIndex} index={1} icon={usersIcon} url={'/kanbanUsers?id=' + kanbanId}/>
        <MenuElement selectedIndex={selectedIndex} index={2} icon={taskIcon} url={'/kanbanTasks?id=' + kanbanId}/>
        <MenuElement selectedIndex={selectedIndex} index={3} icon={graphIcon} url={'/kanbanGraph?id=' + kanbanId}/>
      </div>
      <div className={'flex flex-col pt-2 relative'}>
        {showEditButton && !editPanel && <div
          style={{ width: '20px', height: '60px' }}
          className={'bg-color5 rounded-r-2xl  cursor-pointer flex justify-center ' +
            'items-center hover:bg-orange-500'}
          onClick={() => {setEditPanel(true)}}>
          {editIcon}
        </div>
        }
        {showEditButton && editPanel && <KanbanPropsEditor propsY={yjsData.kanbanPropsY} setEditPanel={setEditPanel}/>}
      </div>
    </div>

  )
}

export default KanbanEditorLeftMenu