import CenterCard from '../../../appInterface/components/CenterCard.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useReducer, useState } from 'react'
import { getUserPublicInfo } from '../../../../backend/userService.js'
import UserCard from './UserCard.jsx'
import UserSearcher from './UserSearcher.jsx'
import InfiniteScroll from 'react-infinite-scroll-component'
import {setHideMenu} from "../../../../redux/slices/kanbanSlice.js";

const KanbanUsers = () => {
  const kanban = useSelector((state) => state.kanban.kanban.kanbanData)
  const dispatch = useDispatch()
  const queryParameters = new URLSearchParams(window.location.search)
  const kanbanId = queryParameters.get('id')
  const [ownerInfo, setOwnerInfo] = useState(null)
  const user = useSelector((state) => state.user)
  const yjsData = useSelector((state) => state.kanban.yjsData)
  const [collaborators, setCollaborators] = useState([])

  useEffect(() => {
    dispatch(getUserPublicInfo(kanban.owner, (data) => {setOwnerInfo(data)}))
    dispatch(setHideMenu(false))
  }, [kanban])

  useEffect(() => {
    setCollaborators(yjsData.collaboratorsY.toJSON())
    let ob
    yjsData.collaboratorsY.observeDeep(ob=e => {
      setCollaborators(yjsData.collaboratorsY.toJSON())
    })
    return()=>{
      yjsData.collaboratorsY.unobserveDeep(ob)
    }
  }, [])

  if (!kanban || !ownerInfo)
    return

  return (
    <div className={'p-2'}>
      <CenterCard extraClasses={"sm:w-[600px] w-full"}>
        <div className={'flex flex-col items-center w-full'}>
          <div id={'propietario'} className={"w-full"}>
            <div className={'p-2 text-white text-xl font-bold'}>
              Propietario:
            </div>
            <UserCard userId={ownerInfo.id} isOwner={true}/>
          </div>
          <div id={'colaboradores'} className={'flex flex-col gap-2 w-full'}>
            <div className={'p-2 text-white text-xl font-bold w-full'}>
              Colaboradores:
            </div>
            {user.userInfo.id === ownerInfo.id && <UserSearcher kanbanId={kanban.id}/>}
            <div className={'flex flex-col gap-2 w-full'}>
              {collaborators.map((x,i) => {
                return <UserCard key={x.id} collaboratorsY={yjsData.collaboratorsY} kanbanId={kanban.id} userId={x.id}
                                 isOwner={!(user.userInfo.id === ownerInfo.id)} tasksY={yjsData.tasksY}/>
              })}
            </div>
          </div>
        </div>
      </CenterCard>
    </div>
  )
}

export default KanbanUsers