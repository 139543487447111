import React from "react"
import {Provider} from "react-redux"
import { store } from "./redux/store.js"
import "./index.css"
import {createBrowserRouter, RouterProvider} from "react-router-dom";;
import { PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import {routes} from "./routes.jsx"
import { createRoot } from 'react-dom/client'

const persistor = persistStore(store);

const router = createBrowserRouter(routes);

const container = document.getElementById("root")

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <Provider store={store}>
          <RouterProvider router={router}/>
      </Provider>
    </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
